@use '../../../common/styleConstants.scss' as *;

.survivalRateChart {
  .axisLight {
    span {
      color: $davys-grey-5;
    }
  }
  
  .axisDark {
    span {
      color: $philippine-gray;
    }
  }
  
  .labelAxisX {
    text-align: center;
    font-size: 14px;
  }

  .labelAxisY {
    text-align: center;
    position: absolute;
    width: 170px;
    height: 140px;
    bottom: calc((100vh - 265px) / 2);
    font-size: 14px;
    transform: rotate(270deg);
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hide {
    display: none;
  }

  .margin {
    margin: 0 30px 0 50px;
    overflow: auto !important;
  }

  .legend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 8px;

      .lightText {
        color: $davys-grey-5;
       }

       .darkText {
         color: $bright-gray;
       }

      .colorBox {
        margin-left: 20px;
        margin-right: 4px;
        display: inline-block;
        width: 28px;
        height: 4px;
      }

      .survivalRate {
        display: flex;
        justify-items: center;

        .smallSquare {
          margin: -2px 10px 0px 10px;
          padding-bottom: 6px;
          width: 8px;
          height: 8px;
          border-radius: 2px;
        }
      }

      .survivalRateLight {
        background-color: $han-blue;

        .smallSquare {
          background-color: $han-blue;
        }
      }

      .survivalRateDark {
        background-color: $white;

        .smallSquare {
          background-color: $white;
        }
      }

      .globalAvg {
        border-style: dashed;
        border-color: red;
        border-width: 1px;
        height: 1px;
      }

      .companyAvg {
        border-style: dashed;
        border-color: $gray;
        border-width: 1px;
        height: 1px;
      }

      > span {
        font-size: 0.9em;
      }
    }
  }
}

.containerResult {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
  width: 100%;
  z-index: 9;

  .result {
    margin: auto;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  i {
    font-size: 40px;
  }
}
