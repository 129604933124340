@use '../../styleConstants.scss' as *;

.container {
  position: relative;
  width: 100%;
}

.ellipsis {
  display: inline-block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  min-width: 110px;
}

@media (max-width: 1380px) {
  .ellipsis {
    min-width: 80px;
  }
}

@media (max-width: 950px) {
  .ellipsis {
    min-width: 100px;
  }
}

@media (max-width: 520px) {
  .ellipsis {
    min-width: 80px;
  }
}
