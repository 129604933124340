@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  position: relative;
  height: 100%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.container {
  position: relative;
  padding: 0 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);

  .body {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    height: calc(100vh - 200px);
  }

  .containerMap {
    position: relative;
    border-radius: 5px;
    width: 100%;
  }

  .widthContainerMap {
    width: calc(100% - 220px);
    margin-right: 20px;
  }

  .cursorPin {
    position: relative;

    .containerMap {
      cursor: url("../../../assets/marker.png") 12 24, auto;
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
