@use '../../common/styleConstants.scss' as *;

.container {
  display: flex;
  justify-content: space-between;

  .button {
    display: flex !important;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
  }

  .options {
    display: flex;
    align-items: flex-end;
  }

  .select {
    width: 150px;
  }

  .sidePanel {
    display: none;
  }
}

@media (max-width: 650px) {
  .container {
    .select {
      width: 120px;
    }
  }
}

@media (max-width: 540px) {
  .container {
    .filters {
      display: none;
    }

    .sidePanel {
      display: flex;

      .title {
        color: $title-color-light;
        font-size: 16px;
        font-weight: bold;
      }

      .bodyPanel {
        width: 100%;
      }
    }
  }
}
