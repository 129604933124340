.geneticsGlobal {
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;

  .form {
    width: 400px;
    margin: auto;

    .title {
      font-size: 17px;
    }

    .alignButtonRight {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.spinnerContainer {
  position: relative;
  width: 100%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}
