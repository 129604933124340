@use '../../../styleConstants.scss' as *;

.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 8px;

    .circle {
      margin-left: 20px;
      margin-right: 4px;
      margin-bottom: 2px;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 100px;
    }

    .below {
      background-color: crimson;
    }

    .over {
      background-color: green;
    }

    .normal {
      background-color: $light-gray;
    }

    .consolidated {
      background-color: $state-blue;
    }

    .colorBox {
      margin-left: 4px;
      margin-right: 4px;
      display: inline-block;
      width: 28px;
      height: 4px;
    }

    .pigmentation {
      border-style: dashed;
      border-color: $gray;
      border-width: 1px;
      height: 1px;
    }

    .growthDelta {
      border-style: solid;
      border-color: $primary-color-dark;
      border-width: 1px;
      height: 1px;
    }

    .movingAverage {
      border-style: dashed;
      border-color: $primary-color-dark;
      border-width: 1px;
      height: 1px;
    }

    .moonPhaseBox {
      margin-left: 4px;
      margin-right: 4px;
      display: inline-block;
      width: 16px;
      height: 16px;
    }

    .newMoonLight {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .newMoonDark {
      background-color: rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.5);
    }

    .firstQuarterMoon {
      background-color: rgb(212, 175, 55, 0.5);
    }

    .lastQuarterMoon {
      background-color: rgb(112, 128, 144, 0.5);
    }

    .fullMoon {
      background-color: rgba(255, 255, 255, 0.4);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}
