@use '../../common/styleConstants.scss' as *;

.postpaid {
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .months {
      margin-left: 15.5%;
      display: flex;
      justify-content: space-around;

      div {
        display: flex;
        align-items: center;

        .month {
          font-size: 1.6em;
        }

        .button {
          padding-top: 0px;
        }
      }
    }
  }

  .grid {
    height: calc(100vh - 360px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px;

    .row {
      align-items: center;
      display: grid;
      grid-template-columns: 16% 83%;
      column-gap: 10px;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }

      .company {
        font-size: 1.35em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .percentages {
        width: 100%;

        .tooltip {
          display: none;
        }

        .months {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 50px;

          &:hover + .tooltip {
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            > div {
              margin-right: auto;
              width: 80%;

              &:nth-child(2) {
                margin-left: 12px;
              }

              &:nth-child(3) {
                margin-left: 24px;
              }

              .gridTooltip {
                padding: 8px 14px;
                border-radius: 6px;
                display: grid;
                grid-column-gap: 12px;

                div {
                  color: black;
                  font-size: 14px;
                  display: grid;
                  grid-template-columns: 1fr 1fr;

                  span {
                    margin-left: 15px;
                    font-size: 14px;
                  }
                }

                @media (max-width: 1200px) {
                  div {
                    font-size: 12px;

                    span {
                      margin-left: 4px;
                      font-size: 12px;
                    }
                  }
                }

                @media (max-width: 760px) {
                  div {
                    font-size: 10px;

                    span {
                      margin-left: 2px;
                      font-size: 10px;
                    }
                  }
                }
              }

              .gridTooltipDark {
                background-color: $white;
              }

              .gridTooltipLight {
                background-color: $row-selected-light;
              }
            }

            @media (max-width: 760px) {
              > div {
                width: 90%;
              }
            }
          }

          .month {
            width: 100%;

            .progressBar {
              height: 18px;
            }

            .progressBarDark {
              background-color: $content-background;
            }

            .border {
              border: 0.2px solid #606060;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1150px) {
    .grid {
      height: calc(100vh - 382px);
    }
  }

  @media (max-width: 600px) {
    .grid {
      height: calc(100vh - 416px);
    }
  }

  .pagination {
    margin: 14px 14px 0 0;

    div {
      display: flex;
      justify-content: flex-end;
    }
  }
}
