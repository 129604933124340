.adminDashboard {
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;

  .lastDays {
    display: none;
  }

  @media (max-width: 1280px) {
    .lastDays {
      display: block;
    }
  }
}
