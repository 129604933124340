@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  position: relative;
  width: 100%;
  height: 90%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.detailPayment {
  width: 100%;
  margin: -28px 10px 0 10px;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;

    .imageContainer {
      height: calc(100vh - 225px);
      display: flex;
      flex-direction: column;
      justify-content: center;

      .image {
        max-height: calc(100vh - 225px);
      }

      .buttonContainer {
        margin: 16px 20px;

        .rowButtons {
          width: 100%;
          display: flex;
          justify-content: center;

          .space {
            .rejectButton {
              background-color: $orange-red;
              border-color: $orange-red;
              display: flex;
            }

            .approveButton {
              background-color: $green-crayola;
              border-color: $green-crayola;
              display: flex;
            }
          }
        }
      }
    }

    .columnText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: calc(100vh - 225px);

      .descriptionContainer {
        display: flex;
        flex-direction: column;
        margin: 0 20px;

        .row {
          margin: 4px 0px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .tag {
            font-size: 1.1em;
            line-height: inherit;
          }

          .value {
            font-size: 1.2em;
          }

          .field {
            font-size: 1.2em;
            font-weight: bold;
          }

          @media (max-width: 540px) {
            .tag {
              font-size: 0.9em;
            }

            .value {
              font-size: 1em;
            }

            .field {
              font-size: 1em;
            }
          }
        }
      }
    }
  }

  @media (max-width: 960px) {
    .row {
      flex-direction: column;

      .imageContainer {
        height: calc(100vh - 425px);

        .image {
          max-height: calc(100vh - 425px);
        }
      }

      .columnText {
        margin-bottom: 10px;

        .descriptionContainer {
          .row {
            justify-content: flex-start;
          }
        }
      }
    }
  }

  .rowHeader {
    height: 70px;
  }
}

.rejectionModal {
  .commentLabel {
    margin-bottom: 5px;
  }

  .rejectionLabel {
    padding: 0 6px;
    font-style: italic;
    font-size: 14px;
    color: $sonic-silver;
  }
}
