@use '../../common/styleConstants.scss' as *;

.passwordForgotWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $body-background-dark;
}

.passwordForgotFormWrapper {
  width: 400px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.passwordForgotLogo {
  margin-bottom: 10px;
}

.passwordForgotForm {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.passwordForgotFormTitle {
  text-align: center;
  font-size: 2em;
  margin-bottom: 15px;
}

.formOptions {
  display: flex;
  justify-content: space-between;
}

.forgotPassword {
  padding-top: 7px;
}
.loginIcon {
  color: $dark-gray-x11;
}
