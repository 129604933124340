@use '../../../../../../src/common/styleConstants.scss' as *;

.percentiles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12px;

  .percentile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .containerCircle {
      display: flex;
      justify-content: center;
      align-items: center;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;

        .icon {
          padding-top: 2px;
        }

        .icon1 {
          font-size: 1.1em;
        }

        .icon2 {
          font-size: 1.3em;
        }

        .icon3 {
          font-size: 1.5em;
        }

        .icon4 {
          font-size: 1.7em;
        }

        .icon5 {
          font-size: 1.9em;
        }
      }
    }

    .rank {
      font-size: 0.75em;
    }
  }
}

@media (max-width: 400px) {
  .percentiles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 12px;

    .percentile {
      .rank {
        font-size: 0.7em;
      }
    }
  }
}
