@use '../../common/styleConstants.scss' as *;

.postpaidModal {
  .canvas {
    display: none;
  }

  .emptyMonth {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border: 1px solid $silver-chalice;
    border-radius: 4px;
    color: $sonic-silver;
  }

  .payphone {
    display: flex;
    justify-content: right;
  }

  .createButton {
    float: right;
  }

  .itemForm {
    div {
      label {
        width: 100%;

        .containerLabel {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          justify-items: center;
          align-items: center;

          .button {
            padding: 0 !important;
          }
        }
      }
    }

    .price {
      padding: 3px 0px 3px 12px;
    }
  }

  .radioGroup {
    text-align: center;
    div {
      font-size: 14px;
    }

    .radioButton {
      border-radius: 0 !important;
      border-left-width: 0px;
      border-right-width: 0px;
    }

    .radioButtonLeft {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .radioButtonRight {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .table {
    thead tr th {
      font-size: 12px !important;
    }

    tbody tr td div div input {
      font-size: 13px;
    }
  }
}
