@use '../../../../src/common/styleConstants.scss' as *;

.archivedAnalysis {
  width: 100%;
  height: 90%;

  .rowHeader {
    width: 100%;
    height: 70px;
    padding: 10px 0px;
    margin-top: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select {
      width: 160px;
    }
  }

  .title {
    font-family: $font-family;
    font-weight: bold;
    font-size: 1.6em;
  }
}

.confirmModal {
  width: 100%;

  .description {
    font-size: 14px;
  }
}

.table {
  .tags {
    text-align: right;
  }

  :global {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 290px);
        overflow-y: scroll;
      }
    }
  }
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i {
      margin-right: 2px !important;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
