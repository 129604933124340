@use '../../common/styleConstants.scss' as *;

.editClientModal {
  :global .ant-modal-content .ant-modal-body {
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
  }

  .contentHeader {
    color: $title-color-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;

    .title {
      font-family: $font-family;
      font-weight: 500;
      color: $sonic-silver;
      font-size: 1.4em;
    }

    .switch {
      color: $title-color-light;
      display: grid;
      grid-column-gap: 10px;
      grid-template-columns: auto auto;
      float: right;
    }
  }

  .inputGroup {
    display: flex;
  }

  @media (max-width: 860px) {
    .contentHeader {
      flex-direction: column;

      div {
        margin-bottom: 2px;
      }
    }
  }

  .maturationsSelect :global {
    .ant-select-selector,
    .ant-select-selector input,
    .ant-select-selector .ant-select-selection-item {
      cursor: default !important;
    }
  }

  .itemTrialPhase {
    div {
      label {
        width: 100%;

        .trialPhase {
          width: 100%;
          display: flex;
          justify-items: center;
          align-items: center;

          div {
            font-size: 14px;
          }

          .icon {
            color: $sonic-silver;
          }
        }
      }
    }
  }

  .countryCodeContainer {
    :global {
      .ant-select .ant-select-selector {
        border-radius: 4px 0px 0px 4px;
        border-color: $silver-chalice;

        &:hover {
          border-color: $silver-chalice;
        }
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(
          .ant-select-customize-input
        )
        .ant-select-selector {
        border-color: $silver-chalice;
        box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.2);
      }
    }
  }

  .countryCodeSelect {
    min-width: 90px;
  }
}
