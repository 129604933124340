@use '../../common/styleConstants.scss' as *;

.settings {
  width: 100%;
  margin-top: -15px;
}

.columnFlex {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .content {
    height: 100%;

    .tabPane {
      min-height: 340px;
    }

    .space {
      padding: 15px 0;
      width: 100%;

      .filters {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .firstSelect {
          display: flex;
          align-items: flex-end;

          .filtersLabel {
            padding: 5px 15px;
          }

          .selectOptionsHistory {
            width: 225px;
            margin-right: 10px;
          }
        }

        @media (max-width: 554px) {
          .firstSelect {
            margin-bottom: 8px;
          }
        }

        .selectUsers {
          width: 175px;
          margin-right: 10px;
        }

        .resetFilters {
          display: grid;
          grid-column-gap: 6px;
        }

        .cleanBtn {
          align-items: center;
          display: flex;
          justify-content: center;
          align-self: flex-end;
        }
      }
    }

    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .name {
        padding-left: 10px;
        text-align: left;

        span {
          font-weight: bold;
        }
      }

      .underline {
        -webkit-text-decoration-line: underline;
        text-decoration-line: underline;
      }
    }
  }
}

.tableCell {
  padding: 16px 30px 16px 10px !important;
}

.spinnerContainer {
  position: relative;
  width: 100%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.operations {
  display: flex;
  justify-content: flex-end;

  .buttonSave {
    margin-right: 10px;

    i {
      margin-top: 1px !important;
    }

    span {
      margin-top: 0px;
      background-color: transparent;
    }
  }

  .buttonCancel {
    i {
      margin-top: 1px !important;
    }

    span {
      margin-top: 0px;
      background-color: transparent;
    }
  }
}
