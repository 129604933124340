@use '../../common/styleConstants.scss' as *;

.alignButtonRight {
  display: flex;
  justify-content: flex-end;
}

.listLight {
  color: $sonic-silver;
}
