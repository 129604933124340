@use '../../../common/styleConstants.scss' as *;

.container {
  background-color: white;
  height: 1187px;
  width: 1680px;
  margin: auto;
  
  .graphTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $davys-grey;
    padding-top: 20px;
    padding-bottom: 6px;

    div {
      font-size: 1.8em;
      text-transform: uppercase;
    }

    span {
      font-size: 1.8em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .graph {
    padding: 0 40px;
  }
}
