@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  width: 100%;
  height: 100%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.container {
  margin: -15px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .rowHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    .filtersContainer {
      .filters {
        .select {
          width: 150px;
        }

        .selectStage {
          width: 100px;
        }
      }
    }

    .iconButtons {
      .iconButton.disabledIconBtn button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .containerSuccessQuadrant {
    width: 100%;
    margin-top: 20px;
    display: flex;

    &.lessMargin {
      margin-top: 4px;
    }

    .successQuadrant {
      position: relative;
      width: 100%;
      height: calc(100vh - 160px);
      overflow-y: scroll;

      .alert {
        margin-top: 4px;
        padding: 4px 8px;
        border-radius: 5px;
      }

      .containerChart {
        display: flex;
        justify-content: space-around;
        position: relative;
        margin-top: 6px;

        .chart {
          .successQuadrantChart {
            position: relative;
          }
        }
      }

      @media (max-width: 1260px) {
        .containerChart {
          flex-direction: column;

          .chart {
            margin-top: 18px;
          }
        }
      }

      .table {
        margin-top: 12px;
      }

      .tableDark {
        .cell {
          &:hover {
            background-color: $row-selected-dark;
          }
        }
      }

      .tableLight {
        .cell {
          &:hover {
            background-color: $row-selected-light;
          }
        }
      }
    }

    .labelAxisX {
      text-align: center;
      font-size: 1em;

      span {
        position: relative;
      }

      .lightText {
        color: $davys-grey-5;
      }

      .darkText {
        color: $bright-gray;
      }
    }

    .labelAxisY {
      position: relative;
      font-size: 1em;
      display: flex;
      justify-content: space-between;

      .lightText {
        color: $davys-grey-5;
        position: relative;
      }

      .darkText {
        color: $bright-gray;
        position: relative;
      }
    }
  }

  .sidePanel {
    display: none;
  }

  .settingsSidePanel {
    display: flex;
  }

  .sidePanel,
  .settingsSidePanel {
    .title {
      color: $title-color-light;
      font-size: 16px;
      font-weight: bold;
    }

    .bodyPanel {
      width: 100%;
      overflow-y: auto;

      height: calc(100vh - 110px);
      padding-bottom: 15px;
    }

    .groupData,
    .excludeOutliers {
      margin-top: 4px;
    }

    .groupData.checkboxDisabled {
      span {
        color: $disabled-color;
      }
    }

    .divider {
      margin-top: 10px;
    }

    .dividerLight {
      border-bottom: 1px solid $philippine-gray;
    }

    .dividerDark {
      border-bottom: 1px solid $chinese-white;
    }
  }
}

@media (max-width: 880px) {
  .container {
    .rowHeader {
      .filtersContainer {
        display: none;
      }
    }

    .sidePanel {
      display: flex;
    }

    .settingsSidePanel {
      display: none;
    }
  }
}