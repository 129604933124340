@use '../../../../../../src/common/styleConstants.scss' as *;

.container {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 380px;
  border-radius: 8px;
  border: 1px solid $title-color-dark;
  background-color: $body-background-dark;
  padding: 15px;
}

@media (max-width: 600px) {
  .container {
    position: sticky;
    bottom: 64px;
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .container {
    width: 320px;
  }
}

.containerLight {
  border: 1px solid $title-color-light;
  background-color: $body-background-light;
}
