.container {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 6px 0;
    display: flex;

    .containerInformation {
      padding-left: 8px;
      cursor: pointer;
    }
  }

  .child {
    position: relative;
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    border: 1px solid #1b62e6;
    border-radius: 8px;
    padding: 16px 8px;

    .actions {
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 2px;
      top: 2px;
    }
  }
}
