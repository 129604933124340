@use '../../../src/common/styleConstants.scss' as *;

.item {
  .description {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 6px;
  }

  .row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;

    input {
      font-size: 15px;
    }

    .icon {
      margin: 0 10px;
    }

    .select {
      width: 100%;
      min-width: 50px;
      max-width: 200px;
    }
  }
}

@media (max-width: 540px) {
  .item {
    margin-bottom: 10px !important;
  }
}

.campusInactive {
  background-color: $persimmon;
  border: none;
  color: white;
  text-transform: none;
  width: fit-content;
  font-size: 12px;
  height: auto;
  padding: 2px 5px 2px 5px;
}
