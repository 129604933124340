@use '../../../common/styleConstants.scss' as *;

.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .select {
    width: 150px;
  }

  .sidePanel {
    display: none;
  }

  .options {
    display: flex;
    align-items: flex-end;
  }
}

@media (max-width: 1020px) {
  .container {
    .select {
      width: 120px;
    }
  }
}

@media (max-width: 640px) {
  .container {
    .filters {
      display: none;
    }

    .sidePanel {
      display: flex;

      .title {
        font-size: 16px;
        font-weight: bold;
      }

      .bodyPanel {
        width: 100%;
      }
    }
  }
}
