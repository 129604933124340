@use '../../../common/styleConstants.scss' as *;

.lightAlert {
  &:global.ant-alert.ant-alert-info {
    background-color: $body-background-light;
    border-color: $silver-chalice;

    .ant-alert-content {
      position: relative;

      .ant-alert-message {
        color: $sonic-silver;
      }
    }

    .ant-alert-icon {
      color: $primary-color-dark;
    }
  }

  &:global.ant-alert.ant-alert-warning {
    background-color: $cosmic-latte;
    border: 1px solid $yellow-green;

    .ant-alert-content {
      position: relative;

      .ant-alert-message {
        color: $dark-charcoal-3;
      }
    }
  }
}

.darkAlert {
  &:global.ant-alert.ant-alert-info {
    background-color: grey;
  }

  &:global.ant-alert.ant-alert-warning {
    background-color: $zinnwaldite-brown;
    border: 1px solid $pineapple;

    .ant-alert-content {
      .ant-alert-message {
        color: $title-color-dark;
      }
    }

    .ant-alert-icon {
      color: $ochre;
    }
  }
}