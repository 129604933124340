@use '../../../../src/common/styleConstants.scss' as *;

.containerQRCode {
  margin: 14px 0;
  padding: 14px 14px 8px 14px;
  border: 4px solid $ghost-white;

  .qrcode {
    background-color: $white;
    width: 128px;
    height: 128px;
  }

  .pin {
    position: relative;
    text-align: center;
    font-size: 0.75em;
    line-height: 1;
    color: black;
  }
}
