@use '../../common/styleConstants.scss' as *;

.topBar {
  top: -100px;
  position: absolute;
  width: calc(100% - 50px);
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 25px 0 25px;
  border-radius: 24px;
  z-index: 100;

  .icon {
    color: $top-bar-icon;
    font-size: 26px;
  }

  .left {
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    .message {
      color: $black;
      margin-left: 25px;
      font-size: 14px;
    }

    @media (max-width: 660px) {
      .message {
        margin-left: 15px;
        font-size: 10px;
        line-height: 13px;
      }
    }
  }

  .right {
    margin-right: 15px;
    display: flex;
    align-items: center;

    .closeButton {
      background-color: transparent;
      display: flex;
      align-items: center;
    }
  }
}

.showWithAnimation {
  top: 0px;
  transform: translateY(0px);
  transition: 0.5s;
}
