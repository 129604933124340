@use '../../styleConstants.scss' as *;

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;

  &:global.ant-btn.ant-btn-text:focus {
    background-color: transparent;
  }

  &:global.ant-btn.ant-btn-loading::before {
    border-radius: 50px;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &:global.ant-btn.ant-btn-text {
    border-radius: 50px;
  }
}

.iconButtonLight:global.ant-btn.ant-btn-text:not(.ant-btn-loading) {
  &:hover {
    background-color: $btn-bg-hover-light;
  }
}

.iconButtonLight:global.ant-btn.ant-btn-text.ant-btn-loading {
  color: $dark-charcoal-3;
}

.iconButtonDark:global.ant-btn.ant-btn-text:not(.ant-btn-loading) {
  &:hover {
    background-color: $btn-bg-hover;
  }
}

.iconButtonDark:global.ant-btn.ant-btn-text.ant-btn-loading {
  color: $bright-gray;
}
