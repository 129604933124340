@use '../../common/styleConstants.scss' as *;

.analysisFromConsolidatedModal {
  .description {
    margin-bottom: 12px;
  }

  .exclude {
    color: $primary-color-light;
    font-weight: 500;
  }
}
