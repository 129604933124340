@use '../../../common/styleConstants.scss' as *;

.radioButton {
  &:global.ant-radio-button-wrapper:first-child:last-child {
    border-radius: 5px;
  }
}

.lightContainer {
  .lightRadioButton {
    color: $sonic-silver;
    background-color: transparent;
    width: 100%;
  }

  :global {
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):first-child {
      border-color: $primary-color-light;
      background-color: $primary-color-light;
      color: $white;
    }

    .ant-radio-button-wrapper:not(
        .ant-radio-button-wrapper-disabled,
        .ant-radio-button-wrapper-checked
      ):hover {
      background-color: $btn-bg-hover-light;
    }

    .ant-radio-button-wrapper {
      border: 1px solid $primary-color-light;
    }
  }
}

.darkContainer {
  .darkRadioButton {
    width: 100%;
    background-color: $body-background-dark;
  }

  :global {
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):first-child {
      border-color: $primary-color-dark;
    }

    .ant-radio-button-wrapper:not(
        .ant-radio-button-wrapper-disabled,
        .ant-radio-button-wrapper-checked
      ):hover {
      background-color: $btn-bg-hover;
    }

    span {
      color: $title-color-dark;
    }
  }
}
