@use '../../common/styleConstants.scss' as *;

.container {
  display: flex;
  justify-content: space-between;

  .select {
    width: 150px;
  }

  .sidePanel {
    display: none;
  }
}

@media (max-width: 700px) {
  .container {
    .select {
      width: 120px;
    }
  }
}

@media (max-width: 580px) {
  .container {
    .filters {
      display: none;
    }

    .sidePanel {
      display: flex;

      .title {
        color: $title-color-light;
        font-size: 16px;
        font-weight: bold;
      }

      .bodyPanel {
        width: 100%;
      }
    }
  }
}
