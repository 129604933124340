@use '../../common/styleConstants.scss' as *;

.newClientModal {
  :global .ant-modal-content .ant-modal-body {
    height: calc(100vh - 280px);
    overflow-y: scroll;

    .ant-input-group.ant-input-group-compact {
      display: flex;
    }

    .inputGroup {
      display: inline;
    }

    .password {
      font-size: 14px;
    }
  }

  .inputGroup {
    display: inline;
  }

  .password {
    font-size: 14px;
  }

  .countryCodeContainer {
    :global {
      .ant-select .ant-select-selector {
        border-radius: 4px 0px 0px 4px;
        border-color: $silver-chalice;

        &:hover {
          border-color: $silver-chalice;
        }
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(
          .ant-select-customize-input
        )
        .ant-select-selector {
        border-color: $silver-chalice;
        box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.2);
      }
    }
  }

  .countryCodeSelect {
    min-width: 90px;
  }
}
