@use '../../../../src/common/styleConstants.scss' as *;

.detailCustomizableModal {
  top: 60px;
  width: 820px !important;
}

.container {
  position: relative;
  height: 100%;

  .settings {
    margin-left: 8px;
    font-size: 0.9em;
  }

  .row {
    user-select: none;
    margin-top: 8px;
    width: 100%;

    .label {
      display: flex;
      align-items: center;
      border: 1px dashed gray;
      padding: 2px 6px 2px 10px;
      border-radius: 4px;

      .description {
        font-size: 0.9em;
      }
    }

    .mainLabel {
      border-color: darkorange;
    }

    .dropzone {
      border: 1px solid $pagination-border-dark;
      border-radius: 1rem;
      height: 361px;
      margin-top: 12px;
    }

    .dropzone.otherKeys {
      border-radius: 0rem;
      border-color: transparent;
    }

    .otherKeysDark {
      background-color: $even-row-background-dark;
    }

    .otherKeysLight {
      background-color: $even-row-background-light;
    }

    .dropzone.keysSelected {
      margin-bottom: 12px;

      .oddDark {
        background-color: $even-row-background-dark;
      }

      .oddLight {
        background-color: $even-row-background-light;
      }

      .borderRadiusTopLeft {
        border-top-left-radius: 1rem;
      }

      .borderRadiusTopRight {
        border-top-right-radius: 1rem;
      }

      .borderRadiusBottomLeft {
        border-bottom-left-radius: 1rem;
      }

      .borderRadiusBottomRight {
        border-bottom-right-radius: 1rem;
      }

      .width {
        width: 33.15% !important;
      }

      .height {
        height: 89px !important;
      }
    }

    .gridItem {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: grab;

      &:hover {
        background-color: $dropdown-hover-color-light;
      }
    }

    .hoverItem0 {
      &:hover {
        border-top-left-radius: 1rem;
      }
    }

    .hoverItem2 {
      &:hover {
        border-top-right-radius: 1rem;
      }
    }

    .hoverItem9 {
      &:hover {
        border-bottom-left-radius: 1rem;
      }
    }

    .hoverItem11 {
      &:hover {
        border-bottom-right-radius: 1rem;
      }
    }

    .gridItemOtherKeys {
      padding: 6px 2px 6px 12px;
    }

    .gridItemContent {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      font-family: Arial, Helvetica, sans-serif;
      align-items: center;
    }

    .gridItemContent.keys {
      width: 100%;
      justify-content: flex-start;
      font-size: 13px;
      color: $title-color-dark;
    }
  }

  .button {
    float: right;
  }
}

@media (max-width: 767px) {
  .container {
    height: calc(100vh - 72px);
  }
}
