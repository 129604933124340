@use '../../styleConstants.scss' as *;

.container {
  .actionButton {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-top: 1px;
    }

    :global {
      .ant-btn.ant-btn-text.ant-btn-loading::before {
        background: transparent;
      }
      i {
        font-size: 1.2em;
      }
    }
  }
}

.darkContainer {
  .darkActionButton {
    transition: 0s;

    i {
      color: $title-color-dark;
    }
  }

  :global {
    .ant-btn.ant-btn-default {
      border: 1px solid $white-bf;
      background-color: transparent;
      color: $white;

      i {
        color: $white;
      }

      &:hover,
      &:focus {
        border: 1px solid $majorelle-blue;
        background-color: $btn-bg-hover;
        color: $white-bf;

        i {
          color: $white-bf;
        }
      }
    }

    .ant-btn.ant-btn-primary {
      border-radius: 4px;
      background-color: $primary-color-dark;
      color: $white;

      i {
        color: $white;
      }

      &:hover,
      &:focus {
        background-color: $primary-color-hover-dark;
        color: $white;
      }

      &:disabled {
        background-color: $disabled-color;
      }
    }

    .ant-btn.ant-btn-ghost {
      border: 1px solid $cultured;
      color: $cultured;

      i {
        color: $cultured;
      }

      &:hover {
        background-color: $primary-color-hover-dark;
        color: $white;

        i {
          color: $white;
        }
      }

      &:disabled {
        color: $inactive-status-color;
        background-color: $disabled-color;

        i {
          color: $inactive-status-color;
        }
      }
    }
  }
}

.lightContainer {
  .lightActionButton {
    transition: 0s;
    color: $cultured;
  }

  :global {
    .ant-btn.ant-btn-default {
      border: 1px solid $title-color-light;
      background-color: transparent;
      color: $title-color-light;

      i {
        color: $title-color-light;
      }

      &:hover,
      &:focus {
        border: 1px solid $majorelle-blue;
        background-color: $btn-bg-hover-light;
        color: $rich-black;

        i {
          color: $rich-black;
        }
      }
    }

    .ant-btn.ant-btn-text {
      color: $title-color-light;
    }

    .ant-btn.ant-btn-primary {
      border-radius: 4px;
      border: 1px solid $primary-color-hover-light;
      background-color: $primary-color-light;
      color: $white;

      i {
        color: $white;
      }

      &:hover,
      &:focus {
        background-color: $primary-color-hover-light;
        color: $white;
      }

      &:disabled {
        border: 1px solid $disabled-color;
        background-color: $disabled-color;
        color: $dark-charcoal-3;
      }
    }

    .ant-btn.ant-btn-ghost {
      border: 1px solid $primary-color-light;
      color: $primary-color-light;

      i {
        color: $primary-color-light;
      }

      &:hover {
        background-color: $primary-color-light;
        color: $white;

        i {
          color: $white;
        }
      }

      &:disabled {
        color: $inactive-status-color;
        background-color: transparent;

        i {
          color: $inactive-status-color;
        }
      }
    }
  }
}
