@use '../../../common/styleConstants.scss' as *;

.lightForm {
  :global {
    .ant-form-item {
      margin-bottom: 16px;
    }
    .ant-form-item-label {
      padding-bottom: 0px;
    }
    .ant-form-item-label label {
      color: $sonic-silver;
    }

    .ant-form-item-explain-warning {
      color: $dark-orange;
      font-size: 0.95em;
    }

    .ant-input-number-status-warning:not(.ant-input-number-disabled):not(
        .ant-input-number-borderless
      ).ant-input-number,
    .ant-input-number-status-warning:not(.ant-input-number-disabled):not(
        .ant-input-number-borderless
      ).ant-input-number:hover {
      border-color: $dark-orange;
      background-color: transparent;
    }
  }
}

.darkForm {
  :global {
    .ant-form-item {
      margin-bottom: 16px;
    }
    .ant-form-item-label {
      padding-bottom: 0px;
    }
    .ant-form-item-label label {
      color: $title-color-dark;
    }
  }
}
