@use '../../../src/common/styleConstants.scss' as *;

.rowHeader {
  width: 100%;
  padding: 10px 0px;
  height: 70px;

  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .rightRow {
    display: flex;
    border-radius: 8px;
    align-items: flex-end;
    flex: 1;
    margin-right: 6px;
  }
}
