@use '../../../common/styleConstants.scss' as *;

.containerActions {
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;

  .typeHistogram {
    .radioButton {
      height: 28px;
      line-height: 26px;
      background-color: transparent;

      span {
        div {
          font-size: 0.9em;
        }
      }
    }

    .radioButtonDark {
      border: 1px solid $primary-color-dark;

      &:not(.radioSelected):hover {
        background-color: $btn-bg-hover;
      }

      span {
        div {
          color: $bright-gray;
        }
      }
    }

    .radioButtonLight {
      border: 1px solid $primary-color-light;

      &:not(.radioSelected):hover {
        background-color: $btn-bg-hover-light;
      }

      span {
        div {
          color: $title-color-light;
        }
      }
    }

    .radioButtonLight.radioSelected {
      background-color: $primary-color-light;

      span div {
        transition: 0.3s;
        color: $white;
      }
    }

    .radioButtonDark.radioSelected {
      background-color: $primary-color-dark;
    }

    .weight {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border-right-width: 0px;
    }

    .length {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left-width: 0px;
    }
  }
}

.containerExtraInformation {
  margin-top: 20px;
  width: 100%;
}
