@use '../../../../src/common/styleConstants.scss' as *;

.monthlyUserActivity {
  .ant-picker-cell-inner:hover {
    cursor: default;
  }

  .ant-picker-calendar-date-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px !important;

    div {
      font-size: 30px;
    }
  }

  @media (min-height: 860px) {
    .ant-picker-calendar-date-content {
      height: 72px !important;

      div {
        font-size: 38px;
      }
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-calendar-date {
      background-color: transparent;
    }
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date {
    background-color: transparent;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: $content-background;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top-width: 1px;
    border-color: $content-background;
  }

  @media (max-width: 480px) {
    .ant-picker-calendar-date-value {
      font-size: 13px;
    }

    .ant-picker-calendar-date-content {
      height: 44px !important;

      div {
        font-size: 21px;
      }
    }
  }

  @media (max-width: 420px) {
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
      padding: 0 2px 0 0;
    }

    .ant-picker-calendar-date-value {
      font-size: 12px;
    }

    .ant-picker-calendar-date-content {
      height: 42px !important;

      div {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 380px) {
    .ant-picker-calendar-date-value {
      font-size: 8px;
    }

    .ant-picker-calendar-date-content {
      height: 32px !important;

      div {
        font-size: 14px;
      }
    }
  }
}
