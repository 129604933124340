@use '../../../../src/common/styleConstants.scss' as *;

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 40px;
  }
}

.userActivityDashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 10px;

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    margin: auto;

    .alert {
      margin-bottom: 6px;
    }

    @media (max-width: 500px) {
      .alert {
        div {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 480px) {
      .alert {
        div {
          font-size: 13px;
        }
      }
    }

    @media (max-width: 380px) {
      .alert {
        div {
          font-size: 12px;
        }
      }
    }
  }

  .monthlyBody {
    .alert {
      margin-bottom: 0px;
    }

    div {
      width: 100%;
    }
  }

  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;

    .previousNextOptions {
      .iconButton {
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:disabled) {
          border-color: $primary-color-light;
        }

        &:focus {
          border-color: $primary-color-light;
          background-color: unset;
        }

        &:hover:not(:disabled) {
          transition: 0.2s;
          background-color: $btn-bg-hover-light;

          i {
            transition: 0.2s;
          }
        }
      }
    }

    .select {
      width: 180px;
    }

    .datePicker {
      width: 140px;
    }

    .rightFilters {
      .radioButtonLeft {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .radioButtonRight {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  @media (max-width: 880px) {
    .filters {
      flex-direction: column;

      .leftFilters {
        margin-bottom: 8px;
      }
    }
  }

  @media (max-width: 490px) {
    .leftFilters {
      display: flex;
      flex-direction: column;
    }

    .rightFilters {
      display: flex;
      flex-direction: column;
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
