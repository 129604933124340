@use './../../styleConstants.scss' as *;

.lrvIcon {
  margin: 0 !important;
  vertical-align: middle;
}

.iconDark {
  color: $bright-gray;
  &:hover {
    color: $bright-gray;
  }
}

.iconLight {
  color: $dark-charcoal-3;
  &:hover {
    color: $dark-charcoal-3;
  }
}
