@use '../../../common/styleConstants.scss' as *;

.spinner {
  width: auto;
  height: calc(50vh - 80px);
  bottom: 0;
  margin-bottom: 20px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 40px;
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  position: relative;

  .actions {
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 8px;
    top: 2px;
  }

  .gridContainerLight {
    border: 1px solid $pagination-border-light;
  }

  .gridContainerDark {
    border: 1px solid $pagination-border-dark;
  }

  .gridContainer {
    width: auto;
    height: calc(50vh - 80px);
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto;
    border-radius: 8px;

    .oddDark {
      background-color: $even-row-background-dark;
    }

    .oddLight {
      background-color: $even-row-background-light;
    }

    .firstRow {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  @media (max-width: 1420px) {
    .gridContainer {
      height: calc(50vh - 90px);
    }
  }
}
