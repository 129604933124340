@use '../../common/styleConstants.scss' as *;

.rowHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  .select {
    width: 110px;
  }

  .rowRight {
    display: flex;
    align-items: flex-end;

    .addButton {
      border-radius: 4px;
    }

    .reportButton {
      margin-left: -8px;
    }

    @media (max-width: 660px) {
      .addIconLittle {
        font-size: 20px;
      }
    }
  }

  .sidePanel {
    display: none;
  }
}

@media (max-width: 1280px) {
  .rowHeader {
    .select {
      width: 105px;
    }
  }
}

@media (max-width: 1140px) {
  .rowHeader {
    .select {
      width: 100px;
    }
  }
}

@media (max-width: 1100px) {
  .rowHeader {
    align-items: center;

    .filters {
      display: none;
    }

    .sidePanel {
      display: flex;

      .title {
        color: $title-color-light;
        font-size: 16px;
        font-weight: bold;
      }

      .bodyPanel {
        width: 100%;
      }
    }
  }
}
