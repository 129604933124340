@use '../../../common/styleConstants.scss' as *;

.container {
  :global {
    .ant-switch {
      box-shadow: none;
    }

    .ant-switch-inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      i {
        font-size: 16px;
        color: $white;
      }
    }

    .ant-switch-handle {
      &:before {
        background-color: $white;
      }
    }

    .ant-switch-checked {
      .ant-switch-handle {
        &:before {
          background-color: $body-background-light;
        }
      }
    }
  }
}

.darkContainer {
  :global {
    .ant-switch {
      background-color: $disabled-color;
    }

    .ant-switch-checked {
      background-color: limegreen;
    }
  }
}

.lightContainer {
  :global {
    .ant-switch {
      background-color: $disabled-color;
    }

    .ant-switch-checked {
      background-color: limegreen;
    }
  }
}
