.prepaid {
  .ant-popover {
    .ant-popover-arrow-content {
      background-color: white;
    }

    .ant-popover-arrow-content:before {
      margin-top: -1px;
      background: white;
    }
  }
}
