@use '../../../common/styleConstants.scss' as *;

.lightContainer {
  :global {
    .ant-pagination {
      display: flex;
      align-items: center;

      .ant-pagination-prev,
      .ant-pagination-next {
        padding: 0;
        margin: 0px;
        transition: 0s;
        background-color: $pagination-background-light;
        border: 1px solid $pagination-border-light;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          align-self: center;
          color: $davys-grey-5;
        }

        &:not(.ant-pagination-disabled):hover {
          background-color: $btn-bg-hover-light;
        }
      }

      .ant-pagination-prev {
        border-radius: 5px 0px 0px 5px;
        border-right: none;
      }

      .ant-pagination-next {
        border-radius: 0px 5px 5px 0px;
        border-left: none;
      }

      .ant-pagination-item {
        margin-right: 0px;
        color: $davys-grey-5;
        background-color: $pagination-background-light;

        border: 1px solid $pagination-border-light;
        border-radius: 0px;
        border-left: none;
        border-right: none;

        a {
          font-weight: 500;
          color: $davys-grey-5;
          font-size: 14px;
        }

        &:hover {
          border-color: $pagination-border-light;
          border-left: none;
          border-right: none;
          background-color: $btn-bg-hover-light;

          a {
            color: $davys-grey-5;
          }
        }
      }

      .ant-pagination-item.ant-pagination-item-active {
        border-radius: 0px;
        background-color: $primary-color-light;
      }

      .ant-pagination-item-active a {
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      transition: 0s;
      border: 1px solid $pagination-border-light;
      background-color: $pagination-background-light;
      border-radius: 0px;
      border-left: none;
      border-right: none;
      margin: 0;

      .ant-pagination-item-ellipsis {
        color: $davys-grey-5;
        font-size: 10px;
      }
    }

    .ant-pagination-jump-prev .ant-pagination-item-container,
    .ant-pagination-jump-next .ant-pagination-item-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon {
      color: $primary-color-dark;
    }

    .ant-pagination-disabled {
      svg {
        color: $davys-grey-5;
      }
    }
  }
}

.darkContainer {
  :global {
    .ant-pagination {
      display: flex;
      align-items: center;

      .ant-pagination-prev,
      .ant-pagination-next {
        padding: 0;
        margin: 0px;
        transition: 0s;
        background-color: $pagination-background-dark;
        border: 1px solid $pagination-border-dark;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          align-self: center;
          color: $chinese-silver;
        }
        
        &:not(.ant-pagination-disabled):hover {
          background-color: $btn-bg-hover;
        }
      }

      .ant-pagination-prev {
        border-radius: 5px 0px 0px 5px;
        border-right: none;
      }

      .ant-pagination-next {
        border-radius: 0px 5px 5px 0px;
        border-left: none;
      }

      .ant-pagination-item {
        margin-right: 0px;
        color: $bright-gray;
        background-color: $pagination-background-dark;

        border: 1px solid $pagination-border-dark;
        border-radius: 0px;
        border-left: none;
        border-right: none;

        a {
          font-weight: 500;
          color: $bright-gray;
          font-size: 14px;
        }

        &:hover {
          border-color: $pagination-border-dark;
          border-left: none;
          border-right: none;
          background-color: $btn-bg-hover;

          a {
            color: $bright-gray;
          }
        }
      }

      .ant-pagination-item.ant-pagination-item-active {
        color: $bright-gray;
        border-radius: 0px;
        background-color: $primary-color-dark;
      }
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      transition: 0s;
      border: 1px solid $pagination-border-dark;
      background-color: $pagination-background-dark;
      border-radius: 0px;
      border-left: none;
      border-right: none;
      margin: 0;

      .ant-pagination-item-ellipsis {
        color: $title-color-dark;
        font-size: 10px;
      }
    }

    .ant-pagination-jump-prev .ant-pagination-item-container,
    .ant-pagination-jump-next .ant-pagination-item-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon {
      color: $primary-color-dark;
    }

    .ant-pagination-disabled {
      svg {
        fill: $title-color-dark;
      }
    }
  }
}
