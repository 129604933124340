@use '../../common/styleConstants.scss' as *;

.transferStockingInfoModal {
  .stockingName {
    font-size: 14px;
  }

  .options {
    display: flex;
    justify-content: flex-end;

    .actionButton {
      i {
        color: $dark-charcoal-3;
      }
    }
  }

  .footerModal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .closeStockingBtn {
      margin-left: 10px;
    }
  }

  tr.footerRow {
    .footerCell {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .label {
        font-weight: 600;
      }
    }

    :global {
      .ant-table-cell {
        color: $sonic-silver;
        background-color: $background-light !important;
      }
    }
  }
}