@use '../../../common/styleConstants.scss' as *;

.container {
  .alignButtonRight {
    display: flex;
    justify-content: flex-end;
  }

  .line {
    height: 1px;
    width: 100%;
    background-color: $davis-grey;
    margin-bottom: 24px;
  }

  .apiToken {
    span {
      span {
        padding: 0 0 0 11px;
      }
    }
  }
}
