@use '../../../../../../src/common/styleConstants.scss' as *;

.subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 0;

  .actions {
    font-size: 0.9em;
  }

  .doneButton {
    font-size: 0.9em;
    color: $primary-color-hover-dark;
    cursor: pointer;
  }
}

@media (max-width: 400px) {
  .subtitle {
    .actions {
      font-size: 0.8em;
    }

    .doneButton {
      font-size: 0.8em;
    }
  }
}
