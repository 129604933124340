@use '../../../common/styleConstants.scss' as *;

.rowHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  .select {
    width: 140px;
  }

  .sidePanel {
    display: none;
  }

  .rowRight {
    display: flex;
    align-items: flex-end;
  }
}

@media (max-width: 1100px) {
  .rowHeader {
    align-items: center;

    .filters {
      display: none;
    }

    .sidePanel {
      display: flex;

      .title {
        color: $title-color-light;
        font-size: 16px;
        font-weight: bold;
      }

      .bodyPanel {
        width: 100%;
      }
    }
  }
}
