@use '../../../../src/common/styleConstants.scss' as *;

.container {
  height: calc(47vh - 80px);
  margin-top: -20px;

  @media (max-width: 1120px) {
    height: calc(47vh - 120px);
  }

  .actions {
    position: absolute;
    top: 12px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
  }
}
.containerFull {
  height: calc(95vh - 60px);

  .filters {
    display: flex;
    justify-content: flex-end;
  }

  .cleanBtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
