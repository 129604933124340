@use '../../../../../src/common/styleConstants.scss' as *;

.legends {
  position: sticky;
  bottom: 64px;
  width: 420px;
  margin: 0 auto;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px 8px 12px;

  .legend {
    display: flex;

    .icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 10px;
      margin-left: 2px;
    }

    .item {
      font-size: 0.9em;
    }
  }
}

@media (max-width: 420px) {
  .legends {
    width: 360px;
    padding: 8px;

    .legend {
      .item {
        font-size: 0.8em;
      }
    }
  }
}

@media (max-width: 380px) {
  .legends {
    width: 260px;
    padding: 8px;
    flex-direction: column;

    .legend {
      &:first-child {
        padding-bottom: 6px;
      }
    }
  }
}

.legendsDark {
  border: 1px solid $title-color-dark;
  background-color: $body-background-dark;

  .legend {
    .item {
      color: white;
    }
  }
}

.legendsLight {
  border: 1px solid $title-color-light;
  background-color: $body-background-light;

  .legend {
    .item {
      color: $title-color-light;
    }
  }
}
