@use '../../common/styleConstants.scss' as *;

.ant-modal.impersonateModal {
  .ant-modal-content .ant-modal-body {
    padding: 0px 24px !important;
  }
}

.impersonateModal {
  width: 80% !important;
  padding: 20px;
  z-index: 99999;

  .ant-modal-content {
    width: 100%;
    left: 0 !important;

    .ant-modal-body {
      min-height: calc(100vh - 280px);
    }

    .ant-modal-footer {
      display: none;
    }
  }

  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 440px);
          overflow-y: scroll;
        }
      }
    }
  }
}
