@use '../../common/styleConstants.scss' as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;

  .switchDarkAnalysisPublic {
    border: 1px solid $white;

    :global {
      .ant-switch-handle::before {
        margin-bottom: 1px;
      }
    }
  }

  .switchDark {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }

    .icon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
    }

    background-color: $black;

    :global {
      .ant-switch-handle::before {
        background-color: $white;
      }
    }
  }

  .switchDark.boxShadow {
    box-shadow: 0 0 0 1px $white;

    &:focus {
      box-shadow: 0 0 0 1px $white;
    }
  }

  .switchLight {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }

    .icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      color: $white;
    }

    background-color: $cornflower-blue;

    :global {
      .ant-switch-handle::before {
        background-color: $white;
      }
    }
  }

  .switchLight {
    box-shadow: 0 0 0 1px $silver-chalice;

    &:focus {
      box-shadow: 0 0 0 1px $silver-chalice;
    }
  }
}
