@use '../../styleConstants.scss' as *;

.containerStyles {
  height: 35px;
  line-height: 35px;
  background-color: $title-color-dark;
}

.fillerStyles {
  height: 100%;
  background-color: $blue-cola;
  border-radius: inherit;
  text-align: left;
}

.labelStyles {
  padding-left: 10px;
  color: $title-color-light;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 236px;
  display: inline-block;
}

.cursorPointer {
  cursor: pointer;
}

.hasError {
  border: solid 1px $orange-red;
}
