@use '../../common/styleConstants.scss' as *;

.destinationStocking {
  .titleNewStocking {
    margin-bottom: 8px;
  }

  .deleteButton {
    i {
      color: $table-title;
    }
  }

  .alertError {
    color: $orange-red;
  }
}
