@use '../../../common/styleConstants.scss' as *;

.lightTabs {
  :global {
    .ant-tabs-nav {
      &:before {
        border-width: 2px;
        border-color: $platinum-e7;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $primary-color-dark;
    }

    .ant-tabs-tab-btn {
      color: $sonic-silver;
      font-size: 14px;

      &:hover {
        color: $title-color-light;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        transition: 0s;
        color: $sonic-silver;
      }
    }
  }
}

.darkTabs {
  :global {
    .ant-tabs-nav {
      &:before {
        border-width: 2px;
        border-color: $dark-charcoal-3;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $primary-color-dark;
    }

    .ant-tabs-tab-btn {
      transition: 0s;
      color: $title-color-dark;

      &:hover {
        color: $white;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        transition: 0s;
        color: $title-color-dark;
      }
    }
  }
}
