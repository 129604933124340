@use '../../../../src/common/styleConstants.scss' as *;

.fullSpinner {
  position: absolute;
  width: calc(100vw - 30px);
  height: calc(100vh - 50px);
  bottom: 0;
  margin-bottom: 20px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 40px;
  }
}

.boldTitle {
  font-weight: bold;
}

.analysisSpinner {
  position: absolute;
  width: calc(100vw - 272px);
  height: calc(100vh - 45px);
  bottom: 0;
  margin-bottom: 20px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 40px;
  }

  @media (max-width: 880px) {
    width: calc(100vw - 20px);
  }

  @media (max-width: 660px) {
    width: calc(100vw - 10px);
  }
}

@media (max-width: 1420px) {
  .analysisSpinner {
    width: calc(100vw - 120px);
  }
}

.containerMain {
  width: 100%;
  height: 90vh;
  margin-top: -28px;

  .rowHeader {
    width: 100%;
    padding: 10px 0px;
    margin: 0px 10px;

    .archivedTag {
      display: flex;
    }

    .col {
      width: 100%;

      .containerAnalysisOptions {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 6px;
        border-radius: 8px;
      }
    }
  }

  .bodyWithHeader {
    height: calc(100vh - 124px);
    align-items: center;
    overflow-y: scroll;
  }

  .bodyWithoutHeader {
    height: calc(100vh - 60px);
    align-items: center;
    overflow-y: scroll;
  }

  .histogram {
    .title {
      color: transparent;
    }
  }

  .containerBarsChart {
    height: calc(100vh - 228px);
  }

  .weightGroups {
    height: calc(100vh - 228px);
  }

  .chartTextBarsChart {
    font-size: 13px;
    font-family: $font-family;
    text-shadow: 0 0 10px #000;
    font-weight: bold;
    fill: lightgray;
  }

  .chartTextFillWhite {
    font-size: 13px;
    font-family: $font-family;
    fill: white;
    text-shadow: 0 0 10px #000;
    font-weight: bold;
  }

  .body {
    height: calc(100vh - 122px);
    overflow-y: scroll;
  }

  .histogramColumn {
    order: 1;

    .histogram {
      margin-bottom: 20px;
    }
  }

  .barsChartColumn {
    order: 3;
    margin-bottom: 10px;

    .postlarvaePerGroup {
      margin-bottom: 20px;

      .weightGroups .headerChart {
        padding: 5px 0px 5px 0px;
      }
    }
  }

  .sampleColumn {
    order: 2;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .sample {
      width: 100%;
      margin-bottom: 20px;
    }

    .map {
      order: 2;
      width: 100%;
      margin-right: 6px;
    }
  }

  @media (max-width: 1200px) {
    .barsChartColumn {
      order: 2;

      .postlarvaePerGroup {
        padding-top: 0px;
      }
    }

    .sampleColumn {
      order: 3;
      flex-direction: row;
      margin-top: 20px;

      .sample {
        width: 50%;
        padding-right: 9px;
      }

      .map {
        width: 50%;
        padding-left: 9px;
      }
    }
  }

  @media (max-width: 991px) {
    .histogramColumn {
      order: 1;
    }

    .postlarvaePerGroup {
      margin-top: 10px;
    }

    .sampleColumn {
      flex-direction: column;
      padding-top: 10px;
      display: block;

      .sample {
        width: 100%;
        margin-right: 0px;
      }

      .map {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
}

@media (max-width: 1420px) {
  .containerMain {
    height: 86vh;

    .bodyWithHeader {
      height: calc(100vh - 158px);
    }
  }
}

@media (max-width: 1380px) {
  .containerMain {
    .bodyWithHeader {
      height: calc(100vh - 158px);
    }
  }
}

.divider {
  background-color: black;
}

.publicLink {
  display: flex;
  justify-content: space-between;
}

.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.showResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.marginForm {
  margin: auto auto;
  width: 32vw;
  border-radius: 8px;
  border: 1px solid $border-color;
  background-color: $body-background-light;

  .contentTitle {
    color: $title-color-light;
  }

  .headerClassName {
    padding: 14px 26px;
  }

  .alignButtonRight {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1600px) {
  .marginForm {
    width: 50vw;
  }
}

@media (max-width: 860px) {
  .marginForm {
    width: 60vw;
  }
}

@media (max-width: 540px) {
  .marginForm {
    width: 80vw;
  }
}

.shareLinkModal {
  .expireDateItem {
    label {
      width: 100%;
    }
    .expireDateLabel {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
