@use '../../../common/styleConstants.scss' as *;

.modalSelectStockings {
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;

    .information {
      font-size: 0.85em;
    }
  }

  .radioGroup {
    margin-bottom: 12px;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0;

    .arrowRightIcon {
      margin: 22px 10px 0 10px;

      i {
        color: $dim-gray;
        font-size: 1.3em;
      }
    }

    .select {
      width: 150px;
    }
  }

  .clean {
    display: flex;
    justify-content: flex-end;
    margin: 8px 0;

    button {
      i {
        color: $sonic-silver;
      }
    }

    .select {
      min-width: 50px;
      max-width: 150px;
    }
  }

  .informationSearch {
    display: flex;
    align-items: center;
    border: 1px dashed gray;
    color: lightgray;
    padding: 2px 10px;
    border-radius: 4px;

    .flag {
      color: $sonic-silver;
    }

    .description {
      margin-left: 5px;
      font-size: 0.8em;
    }
  }

  .separator {
    display: grid;
    grid-template-columns: 14% auto;
    margin: 8px 0;

    .result {
      font-size: 0.9em;
    }

    .divider {
      height: 2px;
      margin: 10px 0;
    }
  }

  .group {
    display: flex;
  }

  .legendsModal {
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0;
  }

  .table {
    .disabledRows {
      background-color: $chinese-silver-80;
      pointer-events: none;
    }

    .rows {
      * {
        font-size: 12px;
      }
    }

    .containerTag {
      .stockingActive {
        background-color: $body-background-dark;
        border: none;
        color: $gray-x11;
      }

      .stockingFinished {
        background-color: $platinum;
        color: $title-color-light;
      }
    }

    .stockingSelected {
      i {
        color: orange;
      }
    }

    .checkIcon {
      color: $dim-gray;
    }
  }
}
