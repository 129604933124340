@use '../../../../src/common/styleConstants.scss' as *;

.table {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .body {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 4px;
    border: 1px solid $davys-grey;
    overflow: hidden;

    th,
    td {
      padding: 2px 12px 2px 12px;

      .bold {
        font-weight: bold;
      }
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid $davys-grey;
    }

    td:nth-child(1) {
      background-color: $bright-gray;
    }

    thead > tr:not(:last-child) > th,
    head > tr:not(:last-child) > td,
    tbody > tr:not(:last-child) > th,
    tbody > tr:not(:last-child) > td,
    tfoot > tr:not(:last-child) > th,
    tfoot > tr:not(:last-child) > td,
    tr:not(:last-child) > td,
    tr:not(:last-child) > th,
    thead:not(:last-child),
    tbody:not(:last-child),
    tfoot:not(:last-child) {
      border-bottom: 1px solid black;
    }
  }

  .maturation {
    display: flex;
  }

  .maturationStatus {
    background-color: pink;
    margin-top: -2px;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
