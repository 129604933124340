@use '../../../common/styleConstants.scss' as *;

.limitsDefaultLight {
  font-size: 0.8em;
  fill: $title-color-light !important;
}

.limitsDefaultLightPDF {
  font-size: 1em;
}

.frequenciesDefaultLight {
  transition: 0s;
  fill: $title-color-light;
  font-size: 0.8em;
  text-align: center;

  tspan {
    font-size: 0.8em;
  }
}

@media (max-width: 440px) {
  .limitsDefaultLight {
    font-size: 0.72em;
  }

  .frequenciesDefaultLight {
    font-size: 0.72em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);

    tspan {
      font-size: 0.72em;
    }
  }
}

.frequenciesDefaultLightPDF {
  font-size: 1em;

  tspan {
    font-size: 1em;
  }
}

.axisY {
  cursor: default;

  line,
  path {
    stroke: $davys-grey-5;
  }
  text {
    font-size: 0.8em;
    fill: $davys-grey-5;
  }
}

.lineAxisX {
  stroke: $davys-grey-5;
  stroke-width: 1;
}

.triangle {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #959595;
}
