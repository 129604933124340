@use '../../../../src/common/styleConstants.scss' as *;

.limitsDefaultDark {
  font-size: 10px;
  fill: $bright-gray !important;
}

.limitsDefaultLight {
  font-size: 10px;
  fill: $title-color-light !important;
}

.limitsPdf {
  font-size: 10px;
  fill: $davys-grey !important;
}

.ticksLimitsDefaultDark {
  font-size: 11px;
  fill: lightgray !important;
}

.ticksLimitsPdf {
  font-size: 10px;
  fill: $davys-grey !important;
}

.ticksLimitsPdf {
  font-size: 10px;
  fill: $davis-grey !important;
}

.frequenciesDefaultDark {
  transition: 0s;
  fill: $bright-gray;
  font-size: 11px !important;

  tspan {
    font-size: 11px !important;
  }
}

.frequenciesDefaultLight {
  transition: 0s;
  fill: $title-color-light;
  font-size: 11px !important;

  tspan {
    font-size: 11px !important;
  }
}

.frequenciesPdf {
  fill: $davys-grey;
  font-size: 11px !important;
}

.container {
  height: calc(50vh - 120px);

  .containerBins {
    position: absolute;
    top: 120px;
    right: 20px;

    .bins {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: transparent;
      margin-left: 10px;

      .radioButton {
        height: 28px;
        line-height: 26px;
        background-color: transparent;

        span {
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 15px;
          }
        }
      }

      .radioButtonDark {
        border-color: $primary-color-dark;
        color: $title-color-dark;

        &:not(.disabled):hover {
          background-color: $btn-bg-hover;
        }

        span {
          i {
            color: $title-color-dark;
          }
        }
      }

      .radioButtonLight {
        border-color: $primary-color-light;
        color: $sonic-silver;

        &:not(.disabled):hover {
          background-color: $btn-bg-hover-light;
        }

        span {
          i {
            color: $title-color-light;
          }
        }
      }

      .remove {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }

      .add {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .disabled {
        color: grey;
        cursor: not-allowed;
      }
    }
  }

  .actions {
    position: absolute;
    top: 5px;
    right: 14px;
    left: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .select {
      width: 200px;
    }

    .typeHistogram {
      .radioButton {
        height: 28px;
        line-height: 26px;
        background-color: transparent;

        span {
          div {
            font-size: 11.5px;
          }
        }
      }

      .radioButtonDark {
        border: 1px solid $primary-color-dark;

        &:not(.radioSelected):hover {
          background-color: $btn-bg-hover;
        }

        span {
          div {
            color: $bright-gray;
          }
        }
      }

      .radioButtonLight {
        border: 1px solid $primary-color-light;

        &:not(.radioSelected):hover {
          background-color: $btn-bg-hover-light;
        }

        span {
          div {
            color: $title-color-light;
          }
        }
      }

      .radioButtonLight.radioSelected {
        background-color: $primary-color-light;

        span div {
          transition: 0.3s;
          color: $white;
        }
      }

      .radioButtonDark.radioSelected {
        background-color: $primary-color-dark;
      }

      .weight {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border-right-width: 0px;
      }

      .length {
        border-left-width: 1px;
        border-right-width: 1px;
      }

      .conditionFactor {
        border-left-width: 0px;
        border-right-width: 1px;
      }

      .pigmentation {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left-width: 0px;
      }
    }
  }

  @media (max-width: 1200px) {
    .actions {
      top: 5px;
    }
  }

  @media (max-width: 352px) {
    .actions {
      .typeHistogram {
        .weight .length .pigmentation .conditionFactor {
          height: 26px;
          line-height: 24px;

          span {
            font-size: 11px;
          }
        }
      }

      .bins {
        .remove {
          height: 26px;
          line-height: 24px;

          span {
            font-size: 11px;
          }
        }

        .add {
          height: 26px;
          line-height: 24px;

          span {
            font-size: 11px;
          }
        }
      }
    }
  }

  .containerAnimals {
    position: absolute;
    padding: 12px 8px 0px 8px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 12px);
    background-color: transparent;

    .animals {
      padding: 4px;
      border-radius: 6px;
      border: 1.5px solid #3e3e3e;
      text-align: center;

      div {
        color: $bright-gray;
        font-size: 11px;
      }

      span {
        font-size: 11px;
      }
    }
  }
}

@media (max-width: 1420px) {
  .container {
    height: calc(50vh - 130px);
  }
}

@media (max-width: 991px) {
  .container {
    margin-top: 20px;
  }
}

.title {
  color: white;
}

.row {
  justify-content: space-between;
}

.averageLine {
  stroke: #06bb2e;
  stroke-width: 2;
  stroke-dasharray: 6;
  fill: transparent;
}

.averageTextDefault {
  fill: #06bb2e;
  font-size: 11px;
}

.averageTextPdf {
  fill: $davys-grey;
  font-size: 11px;
}
