@use '../../../../src/common/styleConstants.scss' as *;

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.containerMain {
  background-color: white;
  margin: auto;
  height: 1187px;
  width: 1680px;

  .rowData {
    display: flex;
    justify-content: space-between;
    margin: 0 24px 0 24px;

    .labelAxisX {
      text-align: center;
      font-size: 1em;
      color: #a7a7a7;
    }
  }

  .chart {
    background-color: white;
    margin: 0 auto;
    width: 100%;
    position: relative;
  }

  .headerPadding {
    padding: 0;
  }

  .titleContent {
    color: #585857;
    padding: 0;
    font-size: 1.2em !important;
  }
}

.value {
  font-size: 1.2em;
  color: #585857;
  text-align: center;
  font-weight: bold;
}

.field {
  font-size: 1em;
  color: #a7a7a7;
  text-align: center;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .title {
    display: flex;
    justify-content: center;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #585857;

      div {
        font-size: 1.8em;
        text-transform: uppercase;
      }

      span {
        font-size: 1.8em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  .legend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: white;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 20px;

      .colorBox {
        margin-left: 20px;
        margin-right: 10px;
        display: inline-block;
        width: 16px;
        height: 16px;
      }

      .stockingName {
        font-size: 1.2em;
        color: #585857;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

.maturationChart {
  height: 100%;
  width: 100%;
}
