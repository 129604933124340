.stockingsInfoModal {
  width: 850px !important;

  .table {
    tr th,
    tr td {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}
