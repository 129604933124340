@use '../../../../common/styleConstants.scss' as *;

.maturations {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .panelOptions {
    display: flex;
    justify-content: flex-end;
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .button {
    padding: 4px 15px 4px 11px;
    float: right;
  }

  .collapse {
    width: 100%;
    border: none;
    height: calc(100vh - 136px);
    overflow-y: scroll;

    .codeRow {
      display: flex;
      justify-content: space-between;
      padding: 2px 0;
    }

    .emptyCode {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header {
      width: 100%;

      > div {
        width: 100%;
      }
    }

    .maturationPanel {
      border: none;
      transition: 0.3s;

      :global .ant-collapse-content {
        border: none;
      }
    }

    .evenMaturationPanel {
      transition: 0s;
      background-color: $even-row-background-dark;

      :global .ant-collapse-content-box {
        background-color: $even-row-background-dark;
      }
    }

    .oddMaturationPanel {
      transition: 0s;
      background-color: $odd-row-background-dark;

      :global .ant-collapse-content-box {
        background-color: $odd-row-background-dark;
      }
    }

    .activeMaturationPanel {
      transition: 0.3s;
      background-color: $row-selected-dark;
    }
  }
}

.maturationsDark {
  .collapse {
    .maturationPanel {
      &:hover {
        background-color: $row-selected-dark;
      }
    }

    .evenMaturationPanel {
      transition: 0s;
      background-color: $even-row-background-dark;

      :global .ant-collapse-content-box {
        background-color: $even-row-background-dark;
      }
    }

    .oddMaturationPanel {
      transition: 0s;
      background-color: $odd-row-background-dark;

      :global .ant-collapse-content-box {
        background-color: $odd-row-background-dark;
      }
    }

    .activeMaturationPanel {
      background-color: $row-selected-dark;
    }
  }
}

.maturationsLight {
  .collapse {
    .maturationPanel {
      &:hover {
        background-color: $row-selected-light;
      }

      :global .ant-collapse-content {
        color: $sonic-silver;
      }

      :global .ant-collapse-header {
        transition: 0s;
        color: $title-color-light;

        .ant-collapse-expand-icon {
          color: $title-color-light;
        }
      }
    }

    .evenMaturationPanel {
      transition: 0s;
      background-color: $even-row-background-light;

      :global .ant-collapse-content-box {
        background-color: $even-row-background-light;
      }
    }

    .oddMaturationPanel {
      transition: 0s;
      background-color: $odd-row-background-light;
      color: $white;

      :global .ant-collapse-content-box {
        background-color: $odd-row-background-light;
      }
    }

    .activeMaturationPanel {
      background-color: $row-selected-light;
    }
  }
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i::before {
      margin-right: 4px;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
