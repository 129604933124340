@use '../../../../src/common/styleConstants.scss' as *;

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.containerMain {
  background-color: white;
  margin: auto;
  width: 1190px;
  height: 1680px;

  .rowData {
    display: flex;
    justify-content: space-between;
    margin: 0 40px 0 40px;
  }

  .rowChart {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 24px;
    margin: 0 60px 20px 40px;

    .colChart {
      display: grid;
      row-gap: 20px;
    }
  }

  .headerPadding {
    padding-bottom: 4px;
    padding-top: 6px;
  }

  .titleContent {
    color: $davys-grey !important;
    padding: 0;
    font-size: 1.2em !important;
  }

  .titleContentMap {
    color: $davys-grey;
    padding: 0;
    font-size: 1em !important;
  }

  .containerPdf {
    height: 372px;
    overflow: hidden;
    position: relative;
  }

  .containerGrowOutSizesPdf1 {
    height: 508px;
    overflow: hidden;
    position: relative;
  }

  .containerGrowOutSizesPdf2 {
    height: 678px;
    overflow: hidden;
    position: relative;
  }

  .notAvailable {
    height: 372px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .horizontalChartText {
    font-size: 12px;
    font-family: $font-family;
    fill: $davys-grey;
    font-weight: bold;
  }

  .textInsideBarClassName {
    font-size: 12px;
    font-family: $font-family;
    fill: white;
    font-weight: bold;
  }

  .textOutsideBarClassName {
    font-size: 12px;
    font-family: $font-family;
    fill: $davys-grey;
    font-weight: bold;
  }

  .horizontalChartAxis {
    text {
      fill: $davys-grey;
      font-size: 12px;
    }

    line,
    path {
      stroke: #d6d6d6;
    }
  }
}