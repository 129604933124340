@use './../../styleConstants.scss' as *;

.content {
  border-radius: 8px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0 8px 0;

    .title {
      font-size: 16px;
      width: 82%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .titleDark {
      color: $title-color-dark;
    }

    .titleLight {
      color: $title-color-light;
    }
  }

  .subtitle {
    font-size: 14px;
    font-weight: normal;
    padding: 0px 26px;
  }

  .body {
    padding: 10px 20px;
  }

  .noPadding {
    padding: 0;
  }
}
