@use '../../common/styleConstants.scss' as *;

.prepaidPaymentModal {
  width: 540px !important;

  #formPrepaidPayments {
    .ant-picker {
      background-color: $gainsboro;
      border-color: $jet;

      .ant-picker-input {
        input {
          color: $davis-grey;
        }

        .ant-picker-suffix {
          span {
            svg {
              fill: $jet;
            }
          }
        }
      }
    }

    .itemPayphone {
      flex-direction: row !important;
    }
  }

  .anticon-inbox {
    svg {
      font-size: 48px;
    }
  }

  .ant-upload-text {
    color: $title-color-dark !important;
  }
}
