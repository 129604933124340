@use '../../common/styleConstants.scss' as *;

.prepaidPaymentModal {
  .payphone {
    display: flex;
    justify-content: right;
  }

  .canvas {
    display: none;
  }

  .containerSubmit {
    width: 100%;
    display: flex;
    justify-content: right;
  }

  .rect {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border: 1px solid $silver-chalice;
    border-radius: 4px;
    color: $sonic-silver;
  }
}
