.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.container {
  background-color: white;
  margin: auto;
  width: 1190px;
  height: 1680px;

  .body {
    margin: 20px 40px 0px 40px;

    .chart {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .labelAxisY {
        position: relative;
        font-size: 1em;
        display: flex;
        justify-content: space-between;
      }

      .successQuadrantChart {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .harvestHistogram {
    display: flex;
    flex-direction: row;
  }
}
