@use '../../styleConstants.scss' as *;

.mouseTooltip {
  z-index: 9999;
  font-size: 1.2em;

  i {
    color: $persimmon;
  }
}
