@use '../../../../src/common/styleConstants.scss' as *;

.shareLinkModal {
  .expireFormItem {
    label {
      width: 100%;
    }
    .expireDateLabel {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.downloading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  margin-top: 4px;
}

.publicLink {
  display: flex;
  justify-content: space-between;

  .icon {
    color: $sonic-silver;
  }
}

.arrow {
  font-size: 1.4em;
}

.button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: $primary-color-light;

  &:not(:disabled):hover {
    background-color: $primary-color-hover-light;
  }
}

.itemPaginate {
  .days {
    line-height: 22px;
    font-size: 14px;
  }

  .consolidated {
    line-height: 0px;
    font-size: 10px;
  }
}
