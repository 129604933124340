@use '../../../common/styleConstants.scss' as *;

.result {
  :global {
    .ant-result-icon {
      span {
        svg {
          width: 6em;
          height: 6em;
        }
      }
    }

    @media (max-width: 700px) {
      .ant-result-title {
        font-size: 15px;
      }
    }
  }
}

.lightResult {
  :global {
    .ant-result-title {
      color: $title-color-light;
    }
  }
}

.darkResult {
  :global {
    .ant-result-title {
      color: $title-color-dark;
    }
  }
}
