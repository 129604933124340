@use '../../../common/styleConstants.scss' as *;

.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 15px;

  .settings {
    margin-left: 8px;
    font-size: 0.9em;
  }

  .row {
    user-select: none;
    margin-top: 8px;
    width: 100%;

    .label {
      display: flex;
      align-items: center;
      border: 1px dashed gray;
      padding: 2px 6px 2px 10px;
      border-radius: 4px;

      .description {
        font-size: 0.9em;
      }
    }

    .dropzone {
      border: 1px solid $pagination-border-dark;
      border-radius: 1rem;
      height: 80px;
      margin-top: 12px;
    }

    .dropzone.otherKeys {
      border-radius: 0rem;
      height: 120px;
      border-color: transparent;
    }

    .otherKeysDark {
      background-color: $even-row-background-dark;
    }

    .otherKeysLight {
      background-color: $even-row-background-light;
    }

    .dropzone.keysSelected {
      margin-bottom: 12px;

      .borderRadiusLeft {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
      }

      .borderRadiusRight {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }

      .width {
        width: 19.7% !important;
      }
    }

    .gridItem {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: grab;

      &:hover {
        //background-color: $dropdown-hover-color-light;
        background-color: transparent;
      }
    }

    .hoverItem0 {
      &:hover {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
      }
    }

    .hoverItem3 {
      &:hover {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }

    .gridItemOtherKeys {
      padding: 6px 2px 6px 12px;
    }

    .gridItemContent {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      font-family: Arial, Helvetica, sans-serif;
      align-items: center;
    }

    .gridItemContent.keys {
      width: 100%;
      justify-content: center;
      font-size: 13px;
      color: $title-color-dark;
    }
  }

  .alignButtonRight {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
  }
}
