@use '../../../common/styleConstants.scss' as *;

.lightPopover {
  background-color: transparent;
}

.darkPopover {
  background-color: transparent;
}

.lightOverlay {
  :global {
    .ant-popover-arrow-content {
      &:before {
        background-color: $body-background-light !important;
      }
    }

    .ant-popover-inner {
      border-radius: 5px;
      background-color: $body-background-light;

      .ant-popover-title {
        color: $title-color-light;
        border-bottom: 1px solid $silver-chalice;
      }

      .ant-popover-inner-content {
        color: $title-color-light;
      }
    }
  }
}

.darkOverlay {
  background-color: transparent;

  :global .ant-popover-inner {
    border-radius: 5px;
  }
}
