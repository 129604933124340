@use '../../common/styleConstants.scss' as *;

@media (max-width: 540px) {
  .ant-row {
    margin: 0 !important;
  }
}

.maturationsModal {
  padding: 20px;
  width: 70% !important;

  .ant-modal-content {
    width: 100%;
    left: 0 !important;

    .ant-modal-body {
      max-height: calc(100vh - 160px);
    }
  }
}
