@use '../../common/styleConstants.scss' as *;

.referenceCurvesModal {
  width: 1022px !important;
  top: 40px;

  .contentModal {
    display: flex;
    flex-direction: row;

    .chart {
      width: 100%;
      padding-left: 12px;
      text-align: center;
    }

    .referenceCurves {
      width: 90%;

      .table {
        thead tr th {
          font-size: 11px !important;
        }

        tbody tr td div div input {
          font-size: 13px;
        }

        .row {
          td {
            padding: 6px 4px;
          }
        }
      }

      .formItem {
        .button {
          border-radius: 4px;
          padding: 4px 15px 4px 11px;
          float: right;
        }
      }

      .input {
        div {
          input {
            height: inherit !important;
            padding: 0 6px;
          }
        }
      }

      .inputError {
        border-color: $orange-red;
      }

      .buttonDelete {
        font-size: 12px;

        .icon {
          color: $top-bar-icon;
        }
      }

      .labelError {
        color: $orange-red;
        font-size: smaller;
      }

      .column {
        text-transform: initial;
      }
      .range {
        font-size: 0.75rem;
      }
    }
  }
}

@media (max-width: 1030px) {
  .referenceCurvesModal {
    width: 480px !important;

    .contentModal {
      flex-direction: column;

      .referenceCurves {
        width: 100%;
      }
    }
  }
}
