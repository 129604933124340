@use '../../../../src/common/styleConstants.scss' as *;

.stockingComparisionPdf {
  .ant-row {
    .ant-col {
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }

  .ant-switch {
    background-color: grey;
  }

  .ant-switch-checked {
    background-color: $primary-color-dark;
  }

  #svg {
    background-color: white;
    width: 1680px - 96px;
  }
}
