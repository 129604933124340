@use '../../../common/styleConstants.scss' as *;

.footer {
  font-size: 1em;
  color: $quick-silver;
  margin: 0 48px;
  height: 50px;
  text-align: justify;
  line-height: 18px;
}
