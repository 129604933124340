@use '../../../common/styleConstants.scss' as *;

.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  cursor: pointer;
}

.larvaeAvatar {
  background-color: blue;
}

.juvenileAvatar {
  background-color: $cadmium-green;
}

.growOutAvatar {
  background-color: $french-wine;
}

.icon {
  font-size: 20px;
}

.btnDark {
  background-color: $primary-color-dark;
}

.btnLight {
  background-color: $primary-color-light;
}