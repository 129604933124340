@use '../../../common/styleConstants.scss' as *;

.spinner {
  position: absolute;
  width: 100%;
  height: calc(100vh - 120px);
  top: 40px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  &.spinnerDark {
    background-color: $body-background-dark;
  }

  &.spinnerLight {
    background-color: $body-background-light;
  }

  i {
    font-size: 40px;
  }
}

.stockingPopulations {
  width: 100%;
  margin: 0px 10px;
  margin-top: -15px;
  position: relative;

  .rowHeader {
    padding: 10px 0px;
  }

  .columnFlex {
    display: flex;
    flex-direction: column;
    flex: 1;

    .stockingInfoSection,
    .currentStateInfoSection {
      .row {
        margin-top: 5px;
      }
    }

    .stockingInfoSection {
      margin-top: 5px;
    }
  
    .stockingInfoSection,
    .currentStateInfoSection {
      .sectionTitle {
        font-size: 15px;
        font-weight: 600;

        &.sectionTitleLight {
          color: $title-color-light;
        }

        &.sectionTitleDark {
          color: $title-color-dark;
        }
      }
  
      .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;

        .infoCell.infoCellToAppear {
          display: none;
        }
  
        .infoCell {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-bottom: 5px;
          font-size: 15px;
    
          .title {
            margin-bottom: 2px;

            &.titleDark {
              color: $table-title;
            }

            &.titleLight {
              color: $sonic-silver;
            }
          }
    
          .description {
            &.descriptionLight {
              color: $title-color-light;
            }

            &.descriptionDark {
              color: $title-color-dark;
            }
          }
        }
  
        .infoDensityCell {
          flex: 2,
        }
      }
    }

    .divider {
      margin: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  
    .errorLabel {
      color: $orange-red;
      font-size: 13px;
      padding: 5px 0px;
    }
  
    .populationsTable {
      tbody tr td:not(:first-child),
      thead tr th {
        text-align: center;
      }

      .populationRow {
        &:first-child {
          align-items: flex-end;
        }
    
        .titleInput {
          color: $sonic-silver;
        }

        .populationTypeCell {
          .populationTypeIcon {
            font-size: 18px;
            padding-right: 7px;
          }
          
          .transferIcon,
          .harvestIcon {
            color: $orange-red;
          }
      
          .fromBindingIcon {
            color: $blue-cola;
          }
        }
  
        .iconCell {
          padding: 0px;

          .rightIcon {
            margin: 0 auto;
          }
        }
  
        .cell {
          span {
            font-size: 14px;
          }
  
          .populationDateContainer :global {
            .ant-picker-input input {
              font-size: 14px;
              text-align: center;
            }
            span.ant-picker-sufix {
              display: none;
            }
          }

          .populationDate {
            background-color: transparent;
          }
      
          .populationAnimalsNumber:global(.hasPopulationError) {
            border: 1.5px solid $orange-red;

            input {
              color: $orange-red;
            }
          }
      
          .populationAnimalsNumber:global(
              .hasPopulationError .ant-input-number-focused
            ) {
            box-shadow: 0 0 0 1px rgba(255, 77, 79, 0.3137254902); // Same color of orange-red but with opacity
          }
      
          .populationDateContainer :global(div.ant-picker-input .ant-picker-suffix) {
            display: none;
          }
      
          .populationDateContainer :global(div.ant-picker.ant-picker-disabled) {
            border: 1px solid transparent;
            
            cursor: auto;
            * {
              cursor: text;
            }
          }

          .populationAnimalsNumber {
            input {
              text-align: center;
              font-size: 14px;
            }
          }
      
          .populationAnimalsNumber {
            &.readOnly {
              border: 1px solid transparent;
  
      
              &:focus {
                border: 1px solid transparent;
                box-shadow: none;
              }
          
              &:global(.ant-input-number-focused) {
                border: 1px solid transparent;
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .footerButtons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 5px 0px;

      .addPopulationBtn {
        border-radius: 4px;
        margin-right: 10px;
      }
    }
  }
}

.popoverContent {
  li a {
    color: currentColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Table scrolls
.stockingPopulations {
  .populationsSection {
    width: calc(100vw - 290px);
    overflow-x: scroll;

    .populationsTable {
      width: calc(100vw - 290px);
      max-width: unset;
    
      // vertical scroll
      :global .ant-table-body {
        height: auto;
        max-height: calc(100vh - 510px);
      }
    }
  }

  @media (max-width: 1420px) {
    .populationsSection {
      width: calc(100vw - 120px);

      .populationsTable {
        width: calc(100vw - 120px);
      }
    }
  }

  @media (max-width: 1150px) {
    .populationsSection {
      width: calc(100vw - 120px);

      .populationsTable {
        width: calc(100vw + 120px);
      }
    }
  }

  @media (max-width: 950px) {
    .populationsSection {
      width: calc(100vw - 35px);

      .populationsTable {
        width: 1100px;
      }
    }
  }
}

// Responsive sections
.stockingPopulations {
  @media (max-width: 900px) {
    .columnFlex {
      .currentStateInfoSection, .stockingInfoSection {
        .row.rowToDisappear {
          display: none;
        }

        .row {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
          justify-content: center;

          .infoCell {
            flex: none;
            width: 153px;
          }

          .infoCell.infoCellToAppear {
            display: flex;
          }

          .infoDensityCell {
            width: 306px;
          }
        }
      }
    }
  }
}
