@use '../../common/styleConstants.scss' as *;

.title {
  text-align: center;
  color: $davys-grey-5;
  font-size: 1.05em;
  font-weight: bold;
}

.labelAxisY {
  text-align: left;
  font-size: 0.9em;

  .lightText {
    color: $davys-grey-5;
  }

  .darkText {
    color: $bright-gray;
  }
}

.labelAxisX {
  .lightText {
    color: $davys-grey-5;
  }
}

.preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $davys-grey-5;
  height: 100%;
  padding: 0 16px 0 32px;

  .icon {
    font-size: 8em;
    display: flex;
    justify-content: center;
    color: $silver-foil;
  }

  .text {
    text-align: center;
    padding: 0 50px;

    span {
      font-size: 1.05em;
    }
  }
}

.chart {
  position: relative;
}
