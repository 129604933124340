@use '../../../common/styleConstants.scss' as *;

.moon {
  border-radius: 50%;
  border: 1px solid $gray-x11;
  margin: auto;
  height: 30px;
  width: 30px !important;
}

.fullMoon {
  background-color: $cultured;
}

.newMoon {
  background-color: $cetacean-blue;
}

.firstQuarterMoon {
  background: linear-gradient(to right, $cetacean-blue 50%, $cultured 50%);
}

.lastQuarterMoon {
  background: linear-gradient(to right, $cultured 50%, $cetacean-blue 50%);
}
