@use '../../../common/styleConstants.scss' as *;

.lightButton {
  color: $title-color-light;
  border: 1px solid $title-color-light;
  border-radius: 4px;

  &:focus,
  &:hover {
    color: $primary-color-dark;
    border-color: $primary-color-dark;
  }
}

.darkButton {
  color: $title-color-dark;
  border: 1px solid $light-silver;
  background-color: $dark-gunmetal;
  border-radius: 4px;

  &:focus,
  &:hover {
    color: $primary-color-dark;
    border-color: $primary-color-dark;
  }
}
