@use './../../styleConstants.scss' as *;

.functionalities {
  .larvia {
    font-weight: bold;
  }

  .options {
    padding: 20px 20px 5px 20px;

    .title {
      color: $primary-color-dark;
      font-weight: bold;
    }

    .description {
      padding-bottom: 15px;
    }
  }

  @media (max-width: 860px) {
    .options {
      padding: 15px 15px 5px 15px;

      .description {
        padding-bottom: 10px;
      }
    }
  }
}
