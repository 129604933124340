@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  position: relative;
  width: 100%;
  height: 100%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.container {
  height: 100%;
  width: 100%;

  .hideChart {
    display: none;
  }

  .showChart {
    position: relative;
    display: flex;
    margin-bottom: 12px;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .labelAxisX {
    text-align: center;
    font-size: 1em;
  }

  .axisLight {
    color: $davys-grey-5;
  }

  .axisDark {
    color: $quick-silver;
  }

  .padding {
    height: 40px;
  }

  .slider {
    margin-right: 24px;
    margin-left: 60px;
  }
}
