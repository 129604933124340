@use '../../../../src/common/styleConstants.scss' as *;

.stockingRow {
  width: 100%;
  margin-left: -12px;
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i::before {
      margin-right: 2px;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
