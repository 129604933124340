@use '../../../common/styleConstants.scss' as *;

:global {
  .ant-picker-input > input {
    transition: 0s;
  }

  .ant-picker-status-error.ant-picker,
  .ant-picker-status-error.ant-picker:not([disabled]):hover {
    background-color: transparent;
  }
}

.container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.darkContainer {
  .titleDark {
    color: $dark-gray-x11;
    font-size: 14px;
  }

  .darkDatePicker {
    transition: 0s;
    color: $bright-gray;
    background-color: $body-background-dark;
    border: 1px solid $dropdown-border-color;
    border-radius: 4px;

    :global {
      .ant-picker-suffix {
        span {
          svg {
            fill: $silver-chalice;
          }
        }
      }

      .ant-picker-clear {
        background-color: $silver-chalice;
      }

      .ant-picker-input > input[disabled] {
        transition: 0s;
        color: $bright-gray;
      }
    }
  }

  :global {
    .ant-picker.ant-picker-disabled {
      border: 1px solid #3653b880;
    }
  }
}

.lightContainer {
  .titleLight {
    color: $top-bar-icon;
    font-size: 14px;
  }

  .lightDatePicker {
    background-color: $body-background-light;
    border: 1px solid $silver-chalice;
    border-radius: 4px;

    :global {
      .ant-picker-input {
        input {
          transition: 0s;
          color: $top-bar-icon;
        }
      }

      .ant-picker-suffix {
        span {
          svg {
            fill: $silver-chalice;
          }
        }
      }

      .ant-picker-clear {
        background-color: $body-background-light;
      }
    }
  }

  :global {
    .ant-picker-focused {
      box-shadow: 0 0 0 1px $blue-50;
      border: 1px solid $primary-color-dark;
    }
  }
}

.darkDropdown {
  :global {
    .ant-picker-panel-container {
      background-color: $body-background-dark;

      .ant-picker-header button {
        color: $white;
      }

      .ant-picker-cell {
        color: $dim-gray;
      }

      .ant-picker-cell-in-view {
        color: $title-color-dark;
      }

      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-start
        .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-end
        .ant-picker-cell-inner {
        color: $white;
        background: $primary-color-dark;
      }

      .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
        .ant-picker-cell-inner,
      .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
          .ant-picker-cell-range-start
        ):not(.ant-picker-cell-range-end):not(
          .ant-picker-cell-range-hover-start
        ):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        color: $white;
        background: $primary-color-dark;
      }

      .ant-picker-cell-inner:hover {
        color: $white;
        background: $primary-color-dark;
      }

      .ant-picker-cell-in-view.ant-picker-cell-today
        .ant-picker-cell-inner::before {
        border-color: $primary-color-dark;
      }

      .ant-picker-today-btn {
        color: $white;
      }
    }
  }
}

.lightDropdown {
  :global {
    .ant-picker-panel-container {
      background-color: $body-background-light;

      .ant-picker-header button {
        color: $title-color-light;
      }

      .ant-picker-cell {
        color: $sonic-silver;
      }

      .ant-picker-cell-in-view {
        color: $title-color-light;
      }

      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-start
        .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-end
        .ant-picker-cell-inner {
        color: $white;
        background: $primary-color-dark;
      }

      .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
        .ant-picker-cell-inner,
      .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
          .ant-picker-cell-range-start
        ):not(.ant-picker-cell-range-end):not(
          .ant-picker-cell-range-hover-start
        ):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        color: $white;
        background: $primary-color-dark;
      }

      .ant-picker-cell-in-view.ant-picker-cell-today
        .ant-picker-cell-inner::before {
        border-color: $primary-color-dark;
      }

      .ant-picker-today-btn {
        color: $title-color-light;
      }
    }
  }
}
