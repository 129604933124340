@use './common/styleConstants.scss' as *;

.app {
  height: 100vh;
}

.bodyLight {
  background-color: $body-background-light;
}

.bodyDark {
  background-color: $body-background-dark;
}

.mainContent {
  padding: 15px 15px;
  display: flex;
}

.mainContentDark {
  background: $body-background-dark;
}

.mainContentLight {
  background: $body-background-light;
}

@media (max-width: 950px) {
  .mainContent {
    padding: 15px 10px;
  }
}

@media (max-width: 540px) {
  .mainContent {
    padding: 15px 5px;
  }
}

.cornerRibbon {
  width: 100px;
  height: 90px;
  overflow: hidden;
  position: absolute;
  z-index: 1001;
}

.cornerRibbon > span {
  position: absolute;
  display: block;
  width: 150px;
  padding: 6px 0;
  background-color: $shandy;
  color: $black;
  font: 700 16px/1 "Lato", sans-serif;
  text-transform: uppercase;
  text-align: center;
  right: -10px;
  top: 20px;
  transform: rotate(-45deg);
}

@font-face {
  font-family: "LexendDeca-Light";
  src: local("LexendDeca-Light"),
    url("./assets/fonts/LexendDeca-Light.ttf") format("truetype");
}
