@use '../../common/styleConstants.scss' as *;

.tableBindingCode {
  .errorRow {
    font-size: 12px;
    color: $orange-red;
  }

  .hiddenRow {
    font-size: 12px;
    color: transparent;
  }

  :global {
    .ant-table-tbody {
      tr {
        &:nth-child(even) {
          background-color: $body-background-light;
        }

        &:nth-child(odd) {
          background-color: $body-background-light;
        }
      }

      .ant-table-row:hover .ant-table-cell {
        background-color: $body-background-light;
      }

      .ant-table-cell {
        div {
          div {
            font-size: 14px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.newItem {
  display: flex;
  justify-content: flex-end;
}
