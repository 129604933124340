@use '../../styleConstants.scss' as *;

.extraActions {
  margin-left: -10px;
  color: $bright-gray;
  transition: 0.3s;

  &:focus {
    background-color: transparent;
  }

  i {
    border-radius: 10px;
  }
}

.extraActionsLight {
  &:focus i {
    transition: 0.3s;
    background-color: $lotion;
  }

  &:not(:active) i {
    background-color: transparent;
  }
}

.extraActionsDark {
  &:focus i {
    transition: 0.3s;
    background-color: $davis-grey;
  }

  &:not(:active) i {
    background-color: transparent;
  }
}
