@use '../../common/styleConstants.scss' as *;

.spinnerContainer {
  width: 100%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.content {
  width: 100%;
  margin: -28px 10px 0 10px;

  .rowFlex {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
