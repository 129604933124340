@use '../../common/styleConstants.scss' as *;

.value {
  font-size: 1.2em;
  color: $davys-grey;
  text-align: center;
  font-weight: bold;
}

.field {
  font-size: 1em;
  color: $quick-silver;
  text-align: center;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .title {
    display: flex;
    justify-content: center;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $davys-grey;

      div {
        font-size: 1.8em;
        text-transform: uppercase;
      }

      span {
        font-size: 1.8em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
