@use '../../../common/styleConstants.scss' as *;

.floatingButton {
  .lightContainerAvatar {
    border: 5px solid #cccccc67;
  }

  .darkContainerAvatar {
    border: 5px solid #d9d9d91f;
  }

  .containerAvatar {
    border-radius: 100px;

    .center {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .closeIcon {
      color: $black;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 24px;
      }
    }

    .avatar {
      padding: 3px;
      cursor: pointer;
    }

    .larviaAvatar {
      background-color: blue;
    }

    .larvaeAvatar {
      background-color: blue;
    }

    .juvenileAvatar {
      background-color: $cadmium-green;
    }

    .growOutAvatar {
      background-color: $french-wine;
    }

    .avatarClose {
      padding: 3px;
      background-color: $body-background-light;
      cursor: pointer;
    }
  }
}

.containerPanel {
  position: fixed;
  background-color: $body-background-light;
  top: 0;
  right: -264px;
  height: 100%;
  z-index: 1000;

  .hidePanel {
    visibility: hidden;
    opacity: 0;
    transform: translateX(0px);
    transition: 0.3s;
    height: 100%;
  }

  .showPanel {
    visibility: visible;
    opacity: 1;
    transform: translateX(-264px);
    transition: 0.3s;
    height: 100%;
  }

  .filterPanel {
    background-color: $body-background-light;
    border-left: 1px solid $platinum;
    width: 264px;
    height: 100%;

    .container {
      height: 100%;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        .rightButtons {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .closeButton,
        .cleanButton {
          i {
            color: $sonic-silver;
          }
        }

        .closeButton i {
          font-size: 20px;
        }
      }

      .line {
        background-color: $platinum;
        height: 1px;
      }

      .body {
        padding: 20px;
        width: 100%;
      }
    }
  }
}