@use '../../../../src/common/styleConstants.scss' as *;

.lineasStylesDark {
  fill: transparent;
  stroke-width: 1;
  stroke: $bright-gray !important;
}

.lineasStylesLight {
  fill: transparent;
  stroke-width: 1;
  stroke: $title-color-light !important;
}

.lineasPdf {
  fill: transparent;
  stroke-width: 1;
  stroke: $davys-grey !important;
}

.animalsStylesDark {
  font-size: 11px;
  fill: $bright-gray !important;
}

.animalsStylesLight {
  font-size: 11px;
  fill: $title-color-light !important;
}

.animalsPdf {
  font-size: 11px;
  fill: $davys-grey !important;
}
