@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  position: relative;
  width: 100%;
  height: 100%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.geneticsAnalysis {
  margin: -15px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .maturationFilters {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;

    .selectCompany,
    .selectPhaseType,
    .selectScale {
      margin-right: 8px;
      width: 150px;
    }

    .selectParameter {
      margin-right: 8px;
      width: 180px;
    }

    .buttonContainer {
      margin-left: 10px;
      margin-top: 22px;

      .generatePdf {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media (max-width: 1140px) {
      .separator {
        display: none;
      }

      .generatePdf {
        margin-left: 0;
      }
    }
  }

  .title {
    font-family: $font-family;
    font-weight: bold;
    font-size: 1.6em;
  }

  .main {
    display: flex;
    width: 100%;
    margin-top: 16px;

    .maturationOptions {
      min-width: 160px;
      max-width: 240px;
      margin: 20px 10px 10px 0px;
      overflow: auto;

      &.maturationOptionsLight {
        border-right: $chinese-silver-c 1px solid;
      }

      &.maturationOptionsDark {
        border-right: $davys-grey-5 1px solid;
      }

      .maturation {
        padding-bottom: 6px;

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 10px;

          .checkbox {
            cursor: pointer;
            padding-left: 10px;
            width: 80%;

            span {
              top: 0;

              span {
                width: 10px;
                height: 10px;
              }
            }

            .label {
              font-size: 12px;
              font-weight: 500;
            }
          }

          .checkboxEnabled {
            span {
              span {
                &:after {
                  transform: rotate(45deg) scale(0.75) translate(-90%, -40%);
                }
              }

              &:after {
                border-radius: 2px;
              }
            }
          }

          .icon {
            font-size: 0.9em;
          }
        }

        .geneticCode {
          display: flex;
          flex-direction: column;

          .checkbox {
            margin-left: 37px;

            span {
              top: 0;

              span {
                width: 10px;
                height: 10px;
              }
            }

            .label {
              font-size: 12px;
              font-weight: 500;
            }
          }

          .checkboxEnabled {
            span {
              span {
                &:after {
                  transform: rotate(45deg) scale(0.75) translate(-90%, -40%);
                }
              }

              &:after {
                border-radius: 2px;
              }
            }
          }
        }
      }

      .maturationDark {
        .header {
          .checkbox {
            span {
              span {
                background-color: $body-background-dark;
                border-color: $title-color-dark;
              }
            }

            .label {
              color: $title-color-dark;
            }
          }

          .checkboxEnabled {
            span {
              span {
                background-color: white;
                border-color: $title-color-dark;

                &:after {
                  border-color: black;
                }
              }

              &:after {
                border-color: $title-color-dark;
              }
            }
          }

          .icon {
            color: $title-color-dark;
          }
        }

        .geneticCode {
          .checkbox {
            span {
              span {
                background-color: $body-background-dark;
                border-color: $title-color-dark;
              }
            }

            .label {
              color: $title-color-dark;
            }
          }

          .checkboxEnabled {
            span {
              span {
                background-color: white;
                border-color: $title-color-dark;

                &:after {
                  border-color: black;
                }
              }

              &:after {
                border-color: $title-color-dark;
              }
            }
          }
        }
      }

      .maturationLight {
        .header {
          .checkbox {
            span {
              span {
                background-color: $body-background-light;
                border-color: $sonic-silver;
              }
            }

            .label {
              color: $sonic-silver;
            }
          }

          .checkboxEnabled {
            span {
              span {
                background-color: white;
                border-color: $sonic-silver;

                &:after {
                  border-color: black;
                }
              }

              &:after {
                border-color: $sonic-silver;
              }
            }
          }

          .icon {
            color: $sonic-silver;
          }
        }

        .geneticCode {
          .checkbox {
            span {
              span {
                background-color: $body-background-light;
                border-color: $sonic-silver;
              }
            }

            .label {
              color: $sonic-silver;
            }
          }

          .checkboxEnabled {
            span {
              span {
                background-color: white;
                border-color: $sonic-silver;

                &:after {
                  border-color: black;
                }
              }

              &:after {
                border-color: $sonic-silver;
              }
            }
          }
        }
      }
    }

    .chart {
      position: relative;

      .maturationChart {
        position: relative;
      }
    }

    .labelAxisX {
      text-align: center;
      font-size: 1em;

      .lightText {
        color: $davys-grey-5;
      }

      .darkText {
        color: $bright-gray;
      }
    }

    .slider {
      margin-right: 24px;
      margin-left: 60px;
    }
  }

  .containerIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .labelAxisY {
      text-align: left;
      font-size: 1em;
      margin-left: 30px;
      margin-bottom: 8px;

      .lightText {
        color: $davys-grey-5;
      }

      .darkText {
        color: $bright-gray;
      }
    }

    .icons {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      margin-right: 22px;

      .icon {
        margin-left: 6px;

        svg {
          padding: 3px;
        }
      }
    }
  }
}
