@use '../../../common/styleConstants.scss' as *;

.container {
  width: 100%;
  height: 90%;

  .rowHeader {
    width: 100%;
    height: 70px;
    padding: 10px 0px;
    margin-top: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select {
      width: 160px;
    }
  }

  .title {
    font-family: $font-family;
    font-weight: bold;
    font-size: 1.6em;
  }

  .rowFlex {
    .table {
      :global .ant-table-body {
        height: calc(100vh - 235px);
        overflow-y: scroll;
      }

      :global .ant-spin-nested-loading div .ant-spin-spinning {
        height: calc(100% - 35px);
      }

      .globalReference {
        display: flex;

        .name {
          margin-right: 4px;
        }
      }
    }
  }
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i {
      margin-right: 4px !important;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
