@use '../../common/styleConstants.scss' as *;

@media (max-width: 480px) {
  .progressBar {
    min-width: 55% !important;
  }

  .gridItem {
    width: 45% !important;
  }
}

.grid {
  max-height: 300px;
  overflow: auto;
  margin: 5px 0;
  overflow-x: hidden;

  .separate {
    display: flex;
    justify-content: center;
  }
}

.balanceBorderError {
  border: 1px solid $orange-red !important;
}

.alertError {
  color: $orange-red;
  clear: both;
  min-height: 24px;
  padding-top: 0;
  font-size: 14px;
  line-height: 1.5715;
}

.balanceModal {
  width: 1100px !important;

  .content {
    display: flex;
    flex-direction: row;

    .margin {
      margin: auto;
    }

    .column {
      padding: 1em;
      flex: 1;
      justify-content: center;

      .prevChart {
        min-height: 480px;
        min-width: 480px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .icon {
          font-size: 10em;
          display: flex;
          justify-content: center;
          color: $sonic-silver;
        }

        .text {
          text-align: center;
          padding: 0 50px;

          span {
            font-size: 1.05em;
          }
        }
      }

      .gridContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        min-width: 360px;

        .balanceControls {
          display: flex;

          .containerProgressBar {
            position: relative;

            .containerSwitch {
              position: absolute;
              top: 5px;
              right: 6px;
              z-index: 99;

              .button {
                padding: 0px 4px;
                height: auto;
                color: $dark-charcoal-3;

                span {
                  font-size: 12px;
                }

                &:hover {
                  background-color: transparent;
                }
              }

              .mobileExtraActions,
              .extraActions {
                visibility: hidden;
                opacity: 0;
                transition: 0.3;
              }

              .mobileExtraActions {
                visibility: visible;
                opacity: 1;
              }
            }

            &:hover .containerSwitch {
              background-color: $title-color-dark;

              .extraActions {
                visibility: visible;
                opacity: 1;
                transition: 0.3s;
              }
            }
          }

          .progressBar {
            min-width: 62%;
          }

          .progressBarSlim {
            width: 50%;
          }
        }

        .gridItem {
          color: white;
          text-align: center;
          background-color: $blue-cola;
          border-radius: 2px;
          border: 1px solid $blue-cola;
          line-height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
          width: 38%;

          .quotaUsage {
            width: 35%;
            font-size: 0.95em;
            text-align: right;
          }

          .centerText {
            text-align: center;
          }

          .slash {
            width: 10%;
            font-size: 0.95em;
            margin-left: 2px;
          }

          .quotaCompany {
            width: 45%;
            padding: 0 0 0 2px;
            text-align: left;
          }

          .containerQuota {
            width: 45%;
          }

          .quota {
            padding: 0 0 0 3px;
            font-size: 1em;
            border: none;
            border-bottom: 1px dashed $body-background-light;
            line-height: 0.6;
            background-color: $blue-cola;
            color: $body-background-light;

            &:hover {
              border-top-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;
              border-bottom-color: white;
            }

            &:focus {
              border-top-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;
              border-bottom-color: white;
              box-shadow: none;
            }
          }
        }

        .gridEmpty {
          background-color: transparent;
          border: 1px solid transparent;
        }

        .gridPostpaidItem {
          width: 30%;
        }

        .gridPrepaidItem {
          width: 20%;
        }

        .gridPrepaidPlan {
          width: 20%;
          background-color: transparent;
          border-color: transparent;
        }

        .balanceColumn {
          font-size: 0.95em;
          background-color: $blue-cola;
          border-color: $blue-cola;
        }
      }

      .containerChartBalance {
        min-height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .header {
          .title {
            font-size: 1.1em;
            text-align: center;
            width: 100%;
            display: inline-block;
          }

          .legends {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .legend {
              display: flex;
              align-items: center;
              margin: 0 15px;

              .line {
                width: 18px;
                height: 0;
                border-width: 2px;
                border-style: solid;
                display: inline-block;
                margin-right: 5px;
              }

              .text {
                display: inline-block;
              }
            }

            .usage {
              .line {
                border: 1px dashed $sonic-silver;
              }
            }

            .accreditation {
              .line {
                border-color: $green-crayola;
                background-color: $green-crayola;
              }
            }

            .debit {
              .line {
                border-color: $salmon;
                background-color: $salmon;
              }
            }
          }

          .options {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .dateSelected {
              width: 150px;
              margin: 15px 0;
            }

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;

              margin: 8px;
              padding: 2px 3px;
              border: 1px solid $silver-chalice;
              cursor: pointer;

              i {
                transition: 0.3s;
                color: $dark-charcoal-3;
              }

              &:not(.disabled):hover i {
                transition: 0.3s;
                color: $bright-gray;
              }

              &:not(.disabled):focus {
                background-color: unset;

                &:hover {
                  background-color: $sonic-silver;
                }
              }
            }

            .disabled {
              background-color: $disabled-color;
              cursor: not-allowed;
              border-color: $body-background-dark;

              i {
                color: $body-background-dark;
              }
            }
          }

          @media (max-width: 1048px) {
            .legends {
              padding-bottom: 15px;
            }
          }

          @media (max-width: 540px) {
            .title {
              font-size: 1em;
            }

            .options {
              justify-content: center;
            }

            .legends {
              .text {
                font-size: 13px;
              }
            }
          }
        }

        .body {
          margin: auto;
        }
      }

      @media (max-width: 1048px) {
        .containerChartBalance {
          min-width: auto;
          min-height: auto;
        }
      }
    }
  }

  @media (max-width: 1048px) {
    .content {
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 1048px) {
  .balanceModal {
    width: 520px !important;
  }
}
