@use '../../../common/styleConstants.scss' as *;

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 16px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 18px;

    .line {
      margin-left: 10px;
      margin-right: 10px;
      display: inline-block;
      width: 28px;
      height: 1px;
    }

    .parameter {
      background-color: $blue-ryb;
      height: 1.5px;
    }

    .referenceLine {
      border: 1px dashed $green-screamin;
    }
  }
}
