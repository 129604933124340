@use '../../../src/common/styleConstants.scss' as *;

.clientsDashboard {
  height: calc(100vh - 136px);
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;

  @media (max-width: 1000px) {
    .charts {
      height: calc(100vh - 160px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .maturationChart {
    height: 100%;
    width: 100%;
  }

  .row {
    height: 50%;
    width: 100%;

    .widget {
      height: 100%;
    }
  }

  .analysisPerStage {
    height: 50%;
    width: 100%;

    .widget {
      height: 100%;
    }
  }

  @media (max-width: 1460px) {
    .row {
      height: 46%;
    }

    .analysisPerStage {
      height: 46%;
    }
  }

  @media (max-width: 990px) {
    .row {
      height: 92%;

      .usersActivity {
        margin-bottom: 15px;
      }

      .analysisResults {
        margin-bottom: 15px;
      }
    }
  }

  .firstRow {
    margin-bottom: 15px !important;
  }

  .filters {
    .col {
      width: 100%;
    }

    @media (max-width: 1460px) {
      .col {
        margin-bottom: 15px;
      }
    }
  }

  .lastDays {
    display: none;
  }

  @media (max-width: 1280px) {
    .lastDays {
      display: block;
    }
  }
}

@media (max-width: 1120px) {
  .clientsDashboard {
    height: calc(100vh - 156px);
  }
}
