@use '../../common/styleConstants.scss' as *;

.mobileSearchInputContainer {
  display: none;

  .mobileInputSearch {
    border: 1px solid $violet-blue;
    border-right: none;
    border-radius: 5px 0px 0px 5px;

    &:focus {
      box-shadow: none;
      background-color: $body-background-light;
    }
  }

  .mobileInputSearchDark {
    background-color: $body-background-dark;
    &:focus {
      box-shadow: none;
      background-color: $btn-bg-hover;
    }
  }

  .mobileInputSearchLight {
    background-color: $body-background-light;
    &:focus {
      box-shadow: none;
      background-color: $body-background-light;
    }
  }

  .mobileBtnSearchStokings {
    border-radius: 0px 5px 5px 0px !important;
    border: 1px solid $violet-blue;
    color: $bright-gray;
  }

  .activeSearch {
    visibility: visible;
    transform: translateX(0px);
    opacity: 1;
    transition: 0.3s;
  }

  .mobileBtnLight {
    background-color: $body-background-light;

    i {
      color: $title-color-light;
    }

    &:hover {
      background-color: $btn-bg-hover-light;
      border-radius: 50px;
    }
  }

  .mobileBtnDark {
    background-color: $body-background-dark;

    i {
      color: $title-color-dark;
    }

    &:hover {
      background-color: $btn-bg-hover;
      border-radius: 50px;
    }
  }
}

@media (max-width: 500px) {
  .mobileSearchInputContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 12px;

    div {
      width: 100%;
    }
  }
}
