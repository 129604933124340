@use '../../../common/styleConstants.scss' as *;

.labelAxisX {
  text-align: center;
  font-size: 0.9em;
  color: $davys-grey-5;
  margin-top: -6px;
}

.labelAxisXPDF {
  font-size: 1.2em;
}

.labelAxisY {
  font-size: 0.9em;
  color: $davys-grey-5;
}

.labelAxisYPDF {
  font-size: 1.2em;
}
