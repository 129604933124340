@use '../../../common/styleConstants.scss' as *;

.container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  
  :global {
    .ant-input-number-status-error:not(.ant-input-number-disabled):not(
        .ant-input-number-borderless
      ).ant-input-number,
    .ant-input-number-status-error:not(.ant-input-number-disabled):not(
        .ant-input-number-borderless
      ).ant-input-number:hover {
      background-color: transparent;
      border-radius: 4px;
    }

    .ant-input-number-handler-wrap {
      border-radius: 0px 3px 3px 0px;
    }
  }
}

.darkContainer {
  .titleDark {
    color: $dark-gray-x11;
    font-size: 14px;
  }

  .darkInputNumber {
    background-color: transparent;
    width: 100%;
    border: 1px solid $dropdown-border-color;
    border-radius: 4px;

    :global {
      .ant-input-number-input {
        border-radius: 4px;
      }

      .ant-input-number-handler {
        border-color: $dropdown-border-color;
      }

      .ant-input-number-handler-wrap {
        border-left-color: $dropdown-border-color !important;
        background-color: $primary-color-dark !important;

        svg {
          fill: $body-background-light;
          height: 10px;
        }

        .ant-input-number-handler-up {
          border-color: $primary-color-hover-dark;
        }

        .ant-input-number-handler-down {
          border-color: $primary-color-hover-dark;

          svg {
            margin-top: 1px;
          }
        }
      }
    }
  }
}

.lightContainer {
  .titleLight {
    color: $top-bar-icon;
    font-size: 14px;
  }

  .lightInputNumber {
    border: 1px solid $silver-chalice;
    background-color: transparent;
    width: 100%;
    border-radius: 4px;

    :global {
      .ant-input-number-input {
        color: $top-bar-icon;
        border-radius: 4px;
      }

      .ant-input-number-handler-wrap {
        border-left-color: $dark-gray-x11 !important;
        background-color: $blueberry !important;

        svg {
          fill: $body-background-light;
          height: 10px;
        }

        .ant-input-number-handler-down {
          svg {
            margin-top: 1px;
          }
        }
      }
      
      .ant-input-number-disabled {
        border: 1px solid $disabled-color;
      }

      .ant-input-number-focused {
        box-shadow: 0 0 0 1px $blue-50;
        border: 1px solid $primary-color-dark;
      }
    }
  }
}