@use '../../common/styleConstants.scss' as *;

.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: white;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;

    .colorBox {
      margin-left: 20px;
      margin-right: 10px;
      display: inline-block;
      width: 16px;
      height: 16px;
    }

    .stockingName {
      font-size: 1.2em;
      color: $davys-grey;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
