@use '../../../../src/common/styleConstants.scss' as *;

.container {
  height: calc(90vh - 96px);
}

@media (max-width: 1280px) {
  .container {
    height: calc(90vh - 120px);
  }
}

@media (max-width: 1040px) {
  .container {
    height: calc(90vh - 124px);
  }
}

.spinnerContainer {
  position: relative;
  width: 100%;
  height: calc(90vh - 80px);

  @media (max-width: 1280px) {
    height: calc(90vh - 104px);
  }

  @media (max-width: 1040px) {
    height: calc(90vh - 124px);
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    i {
      font-size: 40px;
    }
  }
}
