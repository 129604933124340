@use '../../../src/common/styleConstants.scss' as *;

.parent {
  margin: 20px 0 20px 20px;

  .item {
    .row {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;

      input {
        font-size: 15px;
      }

      .icon {
        margin: 0 10px;
        color: $quick-silver;
      }

      .select {
        width: 100%;
        min-width: 50px;
        max-width: 200px;
      }
    }
  }

  @media (max-width: 540px) {
    .item {
      margin-bottom: 10px !important;
    }
  }

  .child {
    position: absolute;
    top: 26px;
    left: 15px;

    .tree {
      font: normal normal 14px/20px Arial;
      margin-left: 15px;
      padding: 10px;
      position: relative;
      list-style: none;

      li {
        margin: 0;
        padding: 28px 0;
        position: relative;
        font-size: 13px;
        color: $sonic-silver;

        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          top: 34px;
          left: -19px;
          width: 9px;
          height: 9px;
          -moz-border-radius: 4.5px;
          -webkit-border-radius: 4.5px;
          border-radius: 4.5px;
          background-color: $title-color-dark;
          border-color: $sonic-silver;
          border-style: solid;
          border-width: 1px;
        }

        &::before {
          background-color: $primary-color-dark;
        }

        &:first-child {
          &::before {
            background-color: $title-color-dark;
          }
        }

        &::after {
          content: "";
          position: absolute;
          left: -15px;
        }

        &:after {
          border-left: 1px dashed $sonic-silver;
          height: 100%;
          width: 0px;
          top: 38px;
        }

        &:last-child::after {
          display: none;
        }
      }

      @media (max-width: 540px) {
        li {
          padding: 28px 0 14px 0;
        }
      }
    }
  }
}

.campusInactive {
  background-color: $persimmon;
  border: none;
  color: white;
  text-transform: none;
  width: fit-content;
  font-size: 12px;
  height: auto;
  padding: 2px 5px 2px 5px;
}
