@use '../../styleConstants.scss' as *;

.header {
  position: fixed;
  z-index: 1;
  width: 100%;

  .logo {
    float: left;
  }

  .options {
    float: right;
    height: 100%;
    position: absolute;
    right: 0px;

    .item {
      font-weight: bold;
    }
  }
}

.main {
  background: #f8f9fa;
  margin-top: 100px;
  height: auto;
  min-height: calc(100vh - 165px);
  position: relative;

  .content {
    margin-top: 20px;

    .text {
      color: black;
    }
  }
}

.footer {
  text-align: center;
  color: white;
  background: $content-background;
  height: 65px;
  bottom: 0;
  width: 100%;
}
