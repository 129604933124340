@use '../../common/styleConstants.scss' as *;

.prepaid {
  width: 100%;

  .container {
    flex-direction: column;
    width: 100%;

    .months {
      margin-left: 17.5%;
      margin-right: 1%;
      display: flex;
      justify-content: space-around;

      div {
        display: flex;
        align-items: center;

        .month {
          font-size: 1.6em;
        }

        .button {
          padding-top: 0px;
        }
      }

      .days {
        width: 100%;
        display: flex;
        margin-top: 10px;

        div {
          width: 100%;
          font-size: 13px;
        }
      }
    }
  }

  .grid {
    height: calc(100vh - 382px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 2px 10px 10px 10px;

    .row {
      align-items: center;
      display: grid;
      grid-template-columns: 16% 83%;
      column-gap: 10px;

      &:last-child {
        margin-bottom: 0px;
      }

      .company {
        font-size: 1.35em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .percentages {
        width: 100%;

        .tooltip {
          display: none;
        }

        .months {
          width: 100%;

          .item {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            border: 1px solid #606060;
            border-left-width: 0px;
            border-bottom-width: 0px;

            .month {
              display: flex;
              width: 100%;
              border-left: 1px solid #606060;
              height: 36px;
            }

            .monthDark {
              background-color: $content-background;
            }

            .monthLight {
              background-color: $sider-background-light;
            }

            .absolute {
              width: 100%;
              height: 36px;
              position: absolute;
              display: flex;
              align-items: center;
            }
          }

          .showBorderBottom {
            border-bottom: 1px solid #606060;
          }
        }
      }
    }
  }

  .pagination {
    margin: 14px 14px 0 0;

    div {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.popover {
  color: black;
  div {
    font-size: 12px;
  }
}
