@use '../../../../common/styleConstants.scss' as *;

.maturations {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;

    .ant-collapse-arrow {
      position: relative;
      top: 1px;
    }
  }
}
