@use '../../../common/styleConstants.scss' as *;

.containerNormalLegend {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .normalLegend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .labelAxisY {
      text-align: center;
      font-size: 1em;

      .lightText {
        color: $davys-grey-5;
      }

      .darkText {
        color: $bright-gray;
      }
    }

    .icons {
      display: flex;
      justify-self: flex-end;
      font-size: 16px;
      margin-right: 32px;

      .icon {
        margin: 0 4px;

        svg {
          display: flex;
          align-items: center;
          align-content: center;
          color: white;
          margin-top: -2px;
          padding: 3px;
        }
      }
    }

    .legend {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 8px;

        .lightText {
          color: $davys-grey-5;
          font-size: 13px;
        }

        .darkText {
          color: $bright-gray;
          font-size: 13px;
        }

        .circle {
          margin-left: 20px;
          margin-right: 4px;
          margin-bottom: 2px;
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 100px;
        }

        .below {
          background-color: crimson;
        }

        .over {
          background-color: green;
        }

        .darkNormal {
          background-color: $light-silver;
        }

        .lightNormal {
          background-color: $maximun-blue-purple;
        }

        .consolidated {
          background-color: $state-blue;
        }

        .unusualAverageWeight {
          background-color: $dark-tangerine;
        }

        .moonPhaseBox {
          margin-left: 4px;
          margin-right: 4px;
          display: inline-block;
          width: 16px;
          height: 16px;
        }

        .newMoonLight {
          background-color: rgba(0, 0, 0, 0.5);
        }

        .newMoonDark {
          background-color: rgba(0, 0, 0, 0.5);
          border: 1px solid rgba(255, 255, 255, 0.5);
        }

        .firstQuarterMoon {
          background-color: rgb(212, 175, 55, 0.5);
        }

        .lastQuarterMoon {
          background-color: rgb(112, 128, 144, 0.5);
        }

        .fullMoon {
          background-color: rgba(255, 255, 255, 0.4);
          border: 1px solid rgba(0, 0, 0, 0.2);
        }

        .colorBox {
          margin-left: 4px;
          margin-right: 4px;
          display: inline-block;
          width: 28px;
          height: 4px;
        }

        .pigmentation {
          border-style: solid;
          border-color: $primary-color-dark;
          border-width: 1px;
          height: 1px;
        }

        .growthDelta {
          border-style: solid;
          border-color: $primary-color-dark;
          border-width: 1px;
          height: 1px;
        }

        .movingAverage {
          border-style: dashed;
          border-color: $gray;
          border-width: 1px;
          height: 1px;
        }
      }
    }
  }

  .rect {
    width: 240px;
  }
}

.containerStockingLegend {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .stockingLegend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    width: 80%;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 20px;
      cursor: pointer;

      &.itemLight {
        color: $davys-grey-5;
      }

      &.itemDark {
        color: $bright-gray;
      }

      .colorBox {
        margin-right: 10px;
        display: inline-block;
        width: 16px;
        height: 16px;
      }

      .colorBoxSelected {
        margin-left: 20px;
      }

      span {
        font-size: 14px;
      }

      .disabledStocking {
        text-decoration: line-through;
      }
    }

    .itemSelected {
      background-color: $primary-color-hover-dark;
      color: $title-color-light;
      padding-right: 20px;

      &.itemLight {
        color: $bright-gray;
      }
    }

    .disabled {
      background-color: orange;
    }
  }

  .labelAxisY {
    .lightText {
      color: $davys-grey-5;
    }

    .darkText {
      color: $bright-gray;
    }
  }
}