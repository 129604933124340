@use '../../common/styleConstants.scss' as *;

.content {
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;

  .rowFlex {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
