@use '../../../common/styleConstants.scss' as *;

.popoverItem {
  padding: 1px 10px;

  .popoverMenuTitle {
    margin-top: 3px;
    font-size: 13px;
    color: $title-color-light;
    font-weight: bold;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .popoverMenuIcon {
    font-size: 24px;
    color: $title-color-light;
  }
}

.popoverItem:hover,
.popoverItemSelected {
  background-color: $chinese-white;

  .col {
    background-color: $chinese-white;
  }
}

.popoverItemButton {
  width: 100%;
  height: auto;
  transition: 0s;
  padding: 1px 10px;

  .popoverMenuTitle {
    margin-top: 2px;
    font-size: 13px;
    color: $title-color-light;
    font-weight: bold;
    text-align: start;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .popoverMenuIcon {
    font-size: 24px;
    color: $title-color-light;
  }
  
  &:hover {
    background-color: $chinese-white !important;
  }
}

