@use '../../../../common/styleConstants.scss' as *;

.newMaturation {
  .addCodeButton {
    padding: 4px 15px 4px 11px;
    float: right;

    &:hover {
      color: $bright-gray;
    }
  }

  .codeRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    > div:first-child {
      width: 90%;
    }
  }

  .codes {
    margin-bottom: 4px;
    padding-left: 2px;
    padding-top: 2px;
  }

  .maturations {
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 2px;
  }

  .maturationPublic {
    display: flex;
    justify-content: space-between;
    width: 100%;

    div {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
