@use '../../../common/styleConstants.scss' as *;

.container {
  margin: -15px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .graphs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    max-height: 72vh;
    overflow-y: auto;
    margin: 0 12px;
  }

  .graphs > * {
    flex: 0 1 calc(12.5% - 10px);
    box-sizing: border-box;
  }
}
