@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  width: 100%;
  height: 100%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
