@use '../../../common/styleConstants.scss' as *;

.table {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  .body {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 4px;
    border: 1px solid $davys-grey;
    overflow: hidden;

    th,
    td {
      padding: 2px 8px 2px 8px;
      font-size: 0.9em;

      .bold {
        font-weight: bold;
      }
    }

    .cursor {
      cursor: pointer;
    }

    .expandIcon {
      //position: absolute;
      font-size: 20px;
      //top: 6px;
    }

    .expandIconLight {
      color: $dark-charcoal-3;
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid $davys-grey;
    }

    td:nth-child(1) {
      background-color: $bright-gray;
    }

    thead > tr:not(:last-child) > th,
    head > tr:not(:last-child) > td,
    tbody > tr:not(:last-child) > th,
    tbody > tr:not(:last-child) > td,
    tfoot > tr:not(:last-child) > th,
    tfoot > tr:not(:last-child) > td,
    tr:not(:last-child) > td,
    tr:not(:last-child) > th,
    thead:not(:last-child),
    tbody:not(:last-child),
    tfoot:not(:last-child) {
      border-bottom: 1px solid black;
    }
  }

  .bodyHarvestedAnimals {
    th,
    td {
      font-size: 0.85em;

      .bold {
        font-weight: bold;
      }
    }

    td:nth-child(1) {
      background-color: transparent;
    }

    .title {
      background-color: $bright-gray !important;
    }
  }

  @media (max-width: 440px) {
    .body {
      th,
      td {
        font-size: 0.7em;
      }
    }
  }

  .maturation {
    display: flex;
  }

  .maturationStatus {
    background-color: pink;
    margin-top: -2px;
  }
}
