@use '../../styleConstants.scss' as *;

.tagWrapper {
  background-color: $body-background-dark;
  border-radius: 36px;
  padding: 12px 2px 12px 10px;
  margin: 5px 10px 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  min-width: 75px;
  height: 20px;
  cursor: default;

  .text {
    font-size: 12px;
  }

  .close {
    margin-left: 10px;
    padding: 5px;
    background-color: $content-background;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
