@use '../../../common/styleConstants.scss' as *;

.survivalRateLine {
  fill: none;
  stroke-dasharray: 0;
}

.survivalRateLineLight {
  stroke: $han-blue;
  stroke-width: 3;
}

.survivalRateLineDark {
  stroke: $white;
  stroke-width: 2;
}

.globalAvgLine {
  fill: none;
  stroke: red;
  stroke-width: 1;
  stroke-dasharray: 3;
}

.companyAvgLine {
  fill: none;
  stroke: $gray;
  stroke-width: 1.5;
  stroke-dasharray: 4;
}

.yearsText {
  fill: none;
  stroke: black;
  font-size: 0.65em;
  font-weight: 100;
}

.yearsLine {
  fill: none;
  stroke: black;
  stroke-width: 1;
  stroke-dasharray: 3;
}

.overlay {
  fill: none;
  pointer-events: all;
}

.circleLight {
  fill: $han-blue;
}

.circleDark {
  fill: $white;
}

.focus circle {
  fill: none;
  stroke: steelblue;
}

.axis {
  text {
    fill: $philippine-gray;
    font-size: 0.8em;
  }
}

.x {
  line,
  path {
    stroke: none;
  }
}

.axisLight {
  text {
    fill: $davys-grey-5;
  }
}

.axisDark {
  text {
    fill: $philippine-gray;
  }
}

.lightRectAxisX {
  z-index: -1;
  fill: $body-background-light;
}

.darkRectAxisX {
  z-index: -1;
  fill: $body-background-dark;
}

.y {
  line,
  path {
    stroke: orange;
  }
}

.tooltip {
  z-index: 9 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  -webkit-transition: 250ms ease-in-out;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;

  &.rightAlignedTooltip {
    &:before {
      -webkit-transform: rotate(135deg) skew(15deg, 15deg);
      transform: rotate(135deg) skew(15deg, 15deg);

      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;
      left: unset;
      right: -8px;
    }

    &:after {
      left: unset;
      right: -16px !important;
      border-width: 8px 0 8px 16px;
    }
  }

  &.leftAlignedTooltip {
    &:before {
      -webkit-transform: rotate(45deg) skew(25deg, 25deg);
      transform: rotate(135deg) skew(25deg, 25deg);

      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;
      top: calc(50% - 8px);
      left: -8px;
      right: unset;
    }
  }

  .content {
    background-color: white;
    border-radius: 5px;

    overflow-y: auto;
    overflow-x: hidden;
    width: 140px;

    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

    hr {
      border: 0;
      height: 1px;
      background: #d5d5d5;
      margin: 0;
    }

    .entry {
      padding: 5px 10px 10px;
      color: black;

      .entryContent {
        .stat {
          font-size: 0.8em;

          strong {
            font-size: 0.8em;
          }
        }
      }
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 16px 6px 0;
    border-color: transparent white;
    display: block;
    width: 0;
    z-index: 1;
    left: -16px;
    top: calc(50% - 6px);
  }
}

.labelLight,
.labelDark {
  font-size: 0.8em;
}

.labelDark {
  fill: $philippine-gray;
}

.labelLight {
  fill: $granite-gray-2;
}

.badDark {
  fill: rgba(255, 86, 137, 0.18);
}

.goodDark {
  fill: rgba(58, 255, 172, 0.25);
}

.veryGoodDark {
  fill: rgba(58, 255, 172, 0.2);
}

.excellentDark {
  fill: rgba(58, 255, 172, 0.15);
}

.badLight {
  fill: rgba(255, 86, 137, 0.18);
}

.goodLight {
  fill: rgba(60, 255, 172, 0.1);
}

.veryGoodLight {
  fill: rgba(58, 255, 172, 0.25);
}

.excellentLight {
  fill: rgba(58, 255, 172, 0.4);
}

.hide {
  fill: $body-background-dark;
}
