@use '../../../../src/common/styleConstants.scss' as *;

.content {
  overflow-y: auto;

  .table {
    display: flex;
    justify-content: center;
    background-color: transparent;
    margin: auto;

    .tbody {
      display: flex;
      border-collapse: collapse;
      border-top: 1px solid $title-color-light;
      border-left: 1px solid $title-color-light;
      margin: auto;

      .userActivity {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .date {
          min-width: 100px;
          height: 86px;
          padding: 4px 10px;
          display: flex;

          justify-content: center;
          align-items: center;
          border-bottom: 1px solid $title-color-light;
          border-right: 1px solid $title-color-light;

          .left {
            margin-right: 4px;
            font-size: 30px;
            color: $sonic-silver;
          }

          @media (min-height: 860px) {
            .left {
              font-size: 32px;
            }
          }

          .rigth {
            color: $sonic-silver;

            .day {
              font-size: 13px;
              font-weight: bold;
              line-height: 1.1;
            }

            .month {
              font-size: 13px;
              line-height: 1.1;
            }
          }
        }

        .count {
          min-width: 100px;
          height: 86px;
          padding: 4px 10px;
          font-size: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid $title-color-light;
          border-right: 1px solid $title-color-light;
          text-shadow: 2px 2px 16px #000000;
          color: $title-color-dark;
        }

        .dash {
          color: $sonic-silver;
        }

        @media (min-height: 860px) {
          .count {
            font-size: 32px;
          }
        }

        @media (max-width: 1480px) {
          .date {
            height: 72px;

            .left {
              font-size: 30px;
            }

            .rigth {
              .day {
                font-size: 12px;
              }

              .month {
                font-size: 12px;
              }
            }
          }

          .count {
            height: 72px;
            font-size: 30px;
          }
        }

        @media (max-width: 1320px) {
          .date {
            height: 66px;

            .left {
              font-size: 28px;
            }
          }

          .count {
            height: 66px;
            font-size: 28px;
          }
        }

        @media (max-width: 1020px) {
          .date {
            min-width: 120px;
            height: 58px;

            .left {
              font-size: 28px;
            }
          }

          .count {
            min-width: 120px;
            font-size: 28px;
            height: 58px;
          }
        }

        @media (max-width: 490px) {
          .date {
            height: 56px;

            .left {
              font-size: 24px;
            }
          }

          .count {
            height: 56px;
            min-width: 128px;
            font-size: 24px;
          }
        }

        @media (max-width: 360px) {
          .date {
            min-width: 100px;
            height: 48px;

            .left {
              font-size: 24px;
            }
          }

          .count {
            min-width: 100px;
            font-size: 24px;
            height: 48px;
          }
        }

        @media (max-width: 300px) {
          .date {
            min-width: 90px;
            height: 40px;

            .rigth {
              padding: 2px;

              .day {
                font-size: 11px;
              }

              .month {
                font-size: 11px;
              }
            }
          }

          .count {
            min-width: 90px;

            height: 40px;
          }
        }
      }
    }

    @media (max-width: 1020px) {
      .tbody {
        flex-direction: column;

        .userActivity {
          flex-direction: row;
        }
      }
    }
  }
}
