@use '../../common/styleConstants.scss' as *;

.stockings {
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;

  * {
    box-sizing: border-box;
  }

  .mobileSearchInputContainer {
    display: none;
    flex-direction: row;

    .mobileInputSearch {
      border: 1px solid $violet-blue;
      border-right: none;
      border-radius: 5px 0px 0px 5px;

      &:focus {
        box-shadow: none;
        background-color: $body-background-light;
      }
    }

    .mobileInputSearchDark {
      background-color: $body-background-dark;
      &:focus {
        box-shadow: none;
        background-color: $btn-bg-hover;
      }
    }

    .mobileInputSearchLight {
      background-color: $body-background-light;
      &:focus {
        box-shadow: none;
        background-color: $body-background-light;
      }
    }

    .mobileBtnSearchStokings {
      border-radius: 0px 5px 5px 0px;
      border: 1px solid $violet-blue;
      color: $bright-gray;
    }

    .mobileBtnLight {
      background-color: $body-background-light;

      i {
        color: $title-color-light;
      }

      &:hover {
        background-color: $btn-bg-hover-light;
        border-radius: 50px;
      }
    }

    .mobileBtnDark {
      background-color: $body-background-dark;

      i {
        color: $title-color-dark;
      }

      &:hover {
        background-color: $btn-bg-hover;
        border-radius: 50px;
      }
    }

    .hideSearch {
      transform: scale(1, 1);
      transform: translateX(50px);
      opacity: 0;
      width: 0;
      visibility: hidden;
      transition: 0.3s;
    }

    .activeSearch {
      visibility: visible;
      transform: translateX(0px);
      opacity: 1;
      transition: 0.3s;
    }
  }

  @media (max-width: 500px) {
    .mobileSearchInputContainer {
      display: flex;
      margin-top: 6px;
      width: 100%;
    }
  }

  .table {
    margin-top: 16px;
  }

  .body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: calc(100vh - 200px);
    overflow-y: scroll;

    .stocking {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1350px) {
    .body {
      height: calc(100vh - 260px);
    }
  }

  @media (max-width: 1210px) {
    .body {
      height: calc(100vh - 272px);
    }
  }

  @media (max-width: 568px) {
    .body {
      height: calc(100vh - 304px);
    }
  }

  @media (max-width: 380px) {
    .body {
      height: calc(100vh - 336px);
    }
  }

  @media (max-width: 320px) {
    .body {
      height: calc(100vh - 368px);
    }
  }
}

@media (max-width: 500px) {
  .inputSearch,
  .btnSearchStokings {
    display: none;
  }
}

.alertError {
  color: $orange-red;
  clear: both;
  min-height: 24px;
  padding-top: 0;
  font-size: 14px;
  line-height: 1.5715;
}

.hasError {
  display: none;
}

.parent {
  margin: 20px 0 20px 20px;

  .item {
    .row {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;

      input {
        font-size: 15px;
      }

      .icon {
        margin: 0 10px;
        i {
          color: $quick-silver;
        }
      }

      .select {
        width: 100%;
        min-width: 50px;
        max-width: 200px;
      }
    }
  }

  @media (max-width: 540px) {
    .item {
      margin-bottom: 10px !important;
    }
  }

  .child {
    position: absolute;
    top: 22px;
    left: 20px;

    .tree {
      font: normal normal 14px/20px Arial;
      margin-left: 15px;
      padding: 10px;
      position: relative;
      list-style: none;

      li {
        margin: 0;
        padding: 28px 0;
        position: relative;
        font-size: 13px;
        color: $sonic-silver;

        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          top: 34px;
          left: -19px;
          width: 9px;
          height: 9px;
          -moz-border-radius: 4.5px;
          -webkit-border-radius: 4.5px;
          border-radius: 4.5px;
          background-color: $title-color-dark;
          border-color: $sonic-silver;
          border-style: solid;
          border-width: 1px;
        }

        &::before {
          background-color: $primary-color-dark;
        }

        &:first-child {
          &::before {
            background-color: $title-color-dark;
          }
        }

        &::after {
          content: "";
          position: absolute;
          left: -15px;
        }

        &:after {
          border-left: 1px dashed $sonic-silver;
          height: 100%;
          width: 0px;
          top: 38px;
        }

        &:last-child::after {
          display: none;
        }
      }

      @media (max-width: 540px) {
        li {
          padding: 28px 0 14px 0;
        }
      }
    }
  }
}

.addFloatingButton {
  width: 50px !important;
  height: 50px !important;
  -webkit-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.4),
    -5px -1px 5px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.4),
    -5px -1px 5px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.4),
    -5px -1px 5px 1px rgba(0, 0, 0, 0.4);
}

.addFloatingButton i {
  font-size: 25px !important;
}

.warning {
  background-color: $dark-orange;
  border: none;
  color: $top-bar-icon;
  text-transform: none !important;
  width: fit-content;
  font-size: 12px !important;
  height: auto !important;
}

.stockingIsPublished {
  border: none;
  color: white;
  text-transform: none !important;
  width: fit-content;
  font-size: 14px !important;
  height: auto !important;
  padding: 2px 5px 2px 5px !important;
}

.element {
  display: inline-flex;
  align-items: center;
}

.canvasInvoice {
  display: none;
}

.refferedGuide {
  color: $sonic-silver;
}

.newStockingModal {
  top: 60px;

  .table {
    thead tr th {
      font-size: 12px !important;
    }

    tbody tr td div div input {
      font-size: 13px;
    }
  }
}

.newStockingModal,
.settingStockingModal {
  .referenceCurve {
    background-color: $platinum-e7;
    border-radius: 6px;
    margin: 0 0 12px 0 !important;
    padding: 0 4px;

    .formItem {
      margin: 4px 0;

      .text {
        font-size: 0.95em;
      }
    }
  }
}

.settingStockingModal {
  .imageContainer {
    display: block;
    justify-content: center;
    width: 100%;
  }

  .labelImage {
    padding-bottom: 6px;
  }
}

.stockingBindingName {
  padding: 0px;
  margin: 0px;
  height: 24px;
  width: 24px;

  i {
    font-size: 1.02em;
  }
}

.analysisCountDark {
  width: max-content;
  background-color: $black;
  border-radius: 9px;
  border: 1px solid $raising-black;
}

.analysisCountLight {
  width: fit-content;
  background-color: $sider-background-light;
  border-radius: 9px;
  border: 1px solid $raising-black;
}

.statusCell {
  justify-content: flex-end;
  width: 100%;
}

th.sortColumnCellDark {
  cursor: pointer;

  &:hover {
    background-color: $row-selected-dark !important;
  }
}

th.sortColumnCellLight {
  cursor: pointer;

  &:hover {
    background-color: $row-selected-light !important;
  }
}

.stockingBindingCode {
  .iconClose {
    color: $quick-silver;
  }

  span {
    button {
      margin-top: -4px;
    }
  }
}

.bindingCode {
  display: flex;
  justify-content: flex-end;
}

.tableBindingCode {
  .errorRow {
    font-size: 12px;
    color: $orange-red;
  }

  .hiddenRow {
    font-size: 12px;
    color: transparent;
  }

  :global {
    .ant-table-tbody {
      .ant-table-cell {
        div {
          div {
            font-size: 14px;
          }
        }
      }
    }
  }
}
