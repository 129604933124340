@use '../../common/styleConstants.scss' as *;

.settings {
  .tabs {
    .ant-tabs-left {
      .ant-tabs-content-holder {
        margin-left: -1px;
        border-left: 1px solid $davis-grey;
      }
    }
  }

  .ant-input {
    &:disabled {
      background-color: $gainsboro;
      color: $davis-grey;
    }
  }
}
