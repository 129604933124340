@use '../../../../src/common/styleConstants.scss' as *;

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.containerMain {
  background-color: white;
  margin: auto;
  width: 793.7px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .headerQrTanks {
    align-self: center;
    color: black;
    font-size: 26px;
    width: 680px;
  }

  .containerPage {
    padding: 30px 26px 30px 26px;
    background-color: white;
    width: 793.7px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .pageNumber {
    position: absolute;
    width: 730px;
    padding-top: 1030px;
    text-align: right;
    color: black;
  }

  .containerRow {
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-star;
    align-items: center;
  }
}
