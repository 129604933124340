@use '../../../common/styleConstants.scss' as *;

.lrvTooltip :global .ant-tooltip-content {
  .ant-tooltip-inner {
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
  }

  .ant-tooltip-arrow {
    bottom: 0.1px;
  }
}

.darkStyle :global .ant-tooltip-content {
  .ant-tooltip-inner {
    background-color: $background-light;
    color: $title-color-light;
  }
  .ant-tooltip-arrow .ant-tooltip-arrow-content::before {
    background: $background-light;
  }
}

.lightStyle :global .ant-tooltip-content {
  .ant-tooltip-inner {
    background-color: $black;
    color: $title-color-dark;
  }
  .ant-tooltip-arrow .ant-tooltip-arrow-content::before {
    background: $black;
  }
}
