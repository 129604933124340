@use '../../../src/common/styleConstants.scss' as *;

.loginForm {
  .ant-input-affix-wrapper {
    border: 1px solid $bdazzled-blue;
  }
  input::placeholder {
    color: grey;
  }

  .ant-input-password-icon.anticon {
    color: $dark-gray-x11;
  }
}
