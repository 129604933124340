@use '../../common/styleConstants.scss' as *;

.containerInputSearch {
  position: relative;

  .inputSearch {
    width: 146px;
    border: 1px solid $violet-blue;
    border-right: none;
    border-radius: 5px 0px 0px 5px;
  }

  @media (max-width: 770px) {
    .inputSearch {
      width: 120px;
    }
  }

  .inputSearchDark {
    &:focus {
      box-shadow: none;
      background-color: $btn-bg-hover;
    }
  }

  .inputSearchLight {
    &:focus {
      box-shadow: none;
      background-color: $body-background-light;
    }
  }

  .hideSearch {
    transform: scale(1, 1);
    transform: translateX(50px);
    opacity: 0;
    width: 0;
    visibility: hidden;
    transition: 0.3s;
  }

  .activeSearch {
    visibility: visible;
    transform: translateX(0px);
    opacity: 1;
    transition: 0.3s;
  }

  .btnSearchStokings {
    background-color: transparent;
    border: none;
    color: $bright-gray;
    transition: 0.3s;
  }

  .activeBtn {
    border: 1px solid $violet-blue;
    border-radius: 0px 5px 5px 0px;
    transition: 0.3s;
  }
}

@media (max-width: 500px) {
  .containerInputSearch {
    display: none;
  }
}
