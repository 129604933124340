@use '../../../common/styleConstants.scss' as *;

.popoverLight {
  :global {
    .ant-popover-arrow-content::before {
      background-color: $white !important;
    }

    .ant-popover-inner {
      background-color: $white;
    }
  }
}

.popoverDark {
  :global {
    .ant-popover-arrow-content::before {
      background-color: $bright-gray !important;
    }

    .ant-popover-inner {
      background-color: $bright-gray;
    }
  }
}

.popoverMenu {
  :global {
    .ant-popover-arrow {
      .ant-popover-arrow-content::before {
        -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
      }
    }

    .ant-popover-inner {
      width: 228px;
      padding: 12px 0px;
      border-radius: 10px;
    }

    .ant-popover-inner-content {
      width: 228px;
      padding: 0px;

      :local div {
        .popoverDivider {
          background-color: $divider-color;
          margin: 12px 0;
        }
      }
    }
  }
}
