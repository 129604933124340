.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .select {
    width: 150px;
  }

  .addBtn {
    padding: 4px 15px 4px 11px;
  }

  @media (max-width: 3600px) {
    .select {
      width: 120px;
    }
  }
}
