@use '../../../../src/common/styleConstants.scss' as *;

.container:not(.consolidatedGrowOutChart) {
  .barChartContainer {
    position: relative;
    height: calc(50vh - 120px);
    border-radius: 8px 8px 8px 8px;
    
    svg {
      height: calc(50vh - 145px);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.container:not(.consolidatedGrowOutChart) .barChartContainer.containerLight {
  border: 1px solid $davys-grey-5;
}

.container:not(.consolidatedGrowOutChart) .barChartContainer.containerDark {
  border: 1px solid $pagination-border-dark;
  background-color: $even-row-background-dark;
}

.container.consolidatedGrowOutChart {
  .barChartContainer {
    position: relative;
    height: calc(50vh - 150px);
    border-radius: 8px 8px 8px 8px;
    
    svg {
      height: calc(50vh - 175px);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media (max-width: 1420px) {
  .container:not(.consolidatedGrowOutChart) .barChartContainer{
    height: calc(50vh - 135px);

    svg {
      height: calc(50vh - 160px);
    }
  }

  .container.consolidatedGrowOutChart .barChartContainer {
    height: calc(50vh - 140px);

    svg {
      height: calc(50vh - 160px);
    }
  }
}

.rangesDark {
  font-size: 12px;
  font-family: $font-family;
  fill: $bright-gray;
}

.rangesLight {
  font-size: 12px;
  font-family: $font-family;
  fill: $title-color-light;
}

.textInsideBarClassName {
  font-size: 14px;
  font-family: $font-family;
  fill: black;
}

.textOutsideBarClassNameDark {
  font-size: 14px;
  font-family: $font-family;
  fill: $bright-gray;
}

.textOutsideBarClassNameLight {
  font-size: 14px;
  font-family: $font-family;
  fill: $title-color-light;
}

.axis {
  text {
    fill: red;
    font-size: 12px;
  }

  line,
  path {
    stroke: #323b69;
  }
}

.title {
  font-size: 1.1em;
  color: white;
}

.row {
  justify-content: space-between;
}
