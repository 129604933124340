@use '../../common/styleConstants.scss' as *;

.payments {
  width: 100%;
  margin: 0px 10px;

  .status {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .name {
      padding-left: 10px;
      text-align: left;
    }
  }

  .table {
    .rowTable {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.pendingReviewIcon {
  color: $urobilin;
}

.approvedIcon {
  color: $forest-green;
}

.rejectedIcon {
  color: $deep-puce;
}
