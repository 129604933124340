@use '../../common/styleConstants.scss' as *;

.harvests {
  width: 100%;
  padding: 0 15px;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 98%;
  }

  .table {
    margin-top: 16px;

    .children {
      :global {
        .ant-table-container {
          margin-left: -40px;
        }

        .ant-table-content {
          margin-left: -9px;
        }
      }
    }

    .expandIcon {
      position: absolute;
      font-size: 22px;
      top: 6px;
    }

    .expandIconLight {
      color: $dark-charcoal-3;
    }

    .expandIconDark {
      color: $title-color-dark;
    }
  }

  .tableLight {
    .rowEven {
      background-color: $even-row-background-light !important;
    }

    .rowOdd {
      background-color: $odd-row-background-light !important;
    }

    .border {
      border: 1px solid $primary-color-light;
    }

    .openRow {
      border-top: 1px solid $primary-color-light;
      border-left: 1px solid $primary-color-light;
      border-right: 1px solid $primary-color-light;
    }

    .children {
      .row {
        border-left: 1px solid $primary-color-light;
        border-right: 1px solid $primary-color-light;
      }

      .lastRow {
        border-bottom: 1px solid $primary-color-light;
      }
    }
  }

  .tableDark {
    .rowEven {
      background-color: $even-row-background-dark !important;
    }

    .rowOdd {
      background-color: $odd-row-background-dark !important;
    }

    .border {
      border: 1px solid $primary-color-dark;
    }

    .openRow {
      border-top: 1px solid $primary-color-dark;
      border-left: 1px solid $primary-color-dark;
      border-right: 1px solid $primary-color-dark;
    }

    .children {
      .row {
        border-left: 1px solid $primary-color-dark;
        border-right: 1px solid $primary-color-dark;
      }

      .lastRow {
        border-bottom: 1px solid $primary-color-dark;
      }
    }
  }

  .tag {
    font-size: 12px !important;
  }

  .tagDark {
    text-shadow: none;
  }

  .tagLight {
    background-color: $white;
    text-shadow: none;
    color: $title-color-light;
  }

  .consolidatedTagDark {
    border: 1px solid $dark-charcoal-3;
  }

  .consolidatedTagLight {
    border: 1px solid $chinese-silver;
  }

  .harvestedTag {
    border: 1px solid $majorelle-blue;
  }

  .partialHarvestedTag {
    border: 1px solid $cadmium-green;
  }
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i {
      margin-right: 2px !important;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
