@use '../../common/styleConstants.scss' as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;

  :global {
    .ant-btn:disabled {
      border: none;
    }
  }
}
