@use '../../styleConstants.scss' as *;

.defaultComment {
  border: 1px solid $body-background-dark;
  color: $top-bar-icon;
  text-transform: none !important;
  justify-content: left;
  width: fit-content;
  font-size: 12px;
  margin-bottom: 12px;
}
