@use '../../../../../src/common/styleConstants.scss' as *;

.editSample {
  position: absolute;
  top: 20px;
  left: 15px;
  border-radius: 8px;
}

.editSampleLight {
  border: 1px solid $title-color-light;
  background-color: $body-background-light;
}

.editSampleDark {
  border: 1px solid $title-color-dark;
  background-color: $body-background-dark;
}

.containerButton {
  position: absolute;
  top: 20px;
  left: 65px;

  .saveButton {
    border-radius: 4px;
  }
}
