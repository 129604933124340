@use '../../styleConstants.scss' as *;

.ant-descriptions-title {
  color: $white;
}

.ant-descriptions-item-label {
  color: $white;
}

.ant-descriptions-item-content {
  color: $gray-x11;
}
