@use '../../../styleConstants.scss' as *;

.shadedPlot {
  .ant-space-item {
    text-align: center;
  }

  @media (max-width: 540px) {
    .ant-space-item {
      width: 100%;
    }

    .ant-radio-group {
      display: flex;
      justify-content: center;
    }

    .ant-radio-button-wrapper {
      width: 100%;
    }

    .ant-space {
      width: 100%;
    }

    .ant-select {
      width: 100%;
    }
  }

  @media (max-width: 326px) {
    .ant-radio-group {
      display: block;
    }
  }

  .ant-badge-count {
    background-color: $white;
    color: $top-bar-icon;
    box-shadow: 0 0 0 1px $light-silver inset;
    -webkit-box-shadow: 0 0 0 1px $light-silver inset;
  }
}

.line {
  fill: none;
  stroke-width: 3;
}

.lineAxisX,
.lineAxisY {
  stroke: rgba(107, 107, 107, 0.6);
}

.lightLine {
  stroke: $han-blue;
}

.darkLine {
  stroke: $white;
}

.lines {
  fill: none !important;
  stroke-width: 2.5;
}

.hideCircle {
  display: none;
}

.labelsPointsLight {
  font-size: 0.8em;
  fill: $title-color-light;
}

.labelsPointsDark {
  font-size: 0.8em;
  fill: $davys-grey;
}

.labelsXAxis {
  font-size: 0.9em;
  fill: $philippine-gray;
  text-anchor: middle;
}

rect.hideBorder {
  animation-duration: 0s !important;
  transition: 0s !important;
}
