@use '../../../common/styleConstants.scss' as *;

.container {
  display: flex;
  justify-content: space-between;

  .filters {
    .select {
      width: 160px;
    }

    .selectStage {
      width: 100px;
    }
  }

  .options {
    display: flex;
    align-items: flex-end;
  }
}

.sidePanel {
  display: none;
}

@media (max-width: 760px) {
  .container {
    display: none;
  }

  .sidePanel {
    display: flex;

    .title {
      color: $title-color-light;
      font-size: 16px;
      font-weight: bold;
    }

    .bodyPanel {
      width: 100%;
    }
  }
}
