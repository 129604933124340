@use '../../common/styleConstants.scss' as *;

.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 20px;

    .colorBox {
      margin-left: 20px;
      margin-right: 10px;
      display: inline-block;
      width: 24px;
      height: 16px;
    }

    span {
      font-size: 1.2em;
    }
  }
}
