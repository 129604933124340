@use '../../../common/styleConstants.scss' as *;

.svg {
  background-color: transparent;
}

.axisY {
  line,
  path {
    stroke: transparent;
  }
  text {
    font-size: 0.65em;
  }
}

.axisLight {
  text {
    fill: $davys-grey-5;
  }
}

.axisDark {
  text {
    fill: $philippine-gray;
  }
}

.textLight {
  fill: $davys-grey-5;
}

.textDark {
  fill: $philippine-gray;
}

.lines {
  stroke: $blue-ryb;
  fill: transparent;
  stroke-width: 2;
}

.referenceLines {
  stroke: $green-screamin;
  fill: transparent;
  stroke-width: 2;
  stroke-dasharray: 4;
}

.text {
  font-size: 0.6em;
}

.linesAxis {
  stroke: #959595;
  fill: transparent;
  stroke-width: 0.5;
}
