@use '../../common/styleConstants.scss' as *;

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 64px;
  width: 100%;

  .leftHeader,
  .analysisShared {
    display: flex;
    align-items: center;
    height: 100%;

    .rowHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .image {
      margin: 0 30px;
    }

    .titlePublic {
      display: inline;
      font-weight: bold;
      font-size: 22px;
      margin-top: 8px;
      line-height: normal;
    }

    .title {
      display: inline;
      margin: 0 0 0 25px;
      font-weight: bold;
      font-size: 21px;

      .subtitle {
        font-size: 18px;
        margin-left: 4px;
      }
    }

    @media (max-width: 720px) {
      .title {
        font-size: 18px;
      }
    }

    @media (max-width: 640px) {
      .title {
        font-size: 16px;
      }
    }

    @media (max-width: 460px) {
      .title {
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 120px;
      }
    }

    @media (max-width: 360px) {
      .title {
        font-size: 12px;
        max-width: 100px;
      }
    }
  }

  .centerHeader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .select {
      min-width: 100px;
      max-width: 200px;

      div {
        border-color: transparent;
        span {
          font-size: 16px;
        }

        &:after {
          width: 4px;
        }
      }

      span {
        i {
          margin-top: 3px !important;
        }
      }
    }

    @media (max-width: 640px) {
      .select {
        max-width: 150px;

        div {
          span {
            font-size: 14px;
          }
        }
      }
    }

    @media (max-width: 460px) {
      .select {
        max-width: 120px;
      }
    }

    @media (max-width: 360px) {
      .select {
        max-width: 100px;

        div {
          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .rightHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;

    .switchTheme {
      display: flex;
    }

    .hamburger {
      display: none;
    }

    .phaseType.extraPadding {
      margin-right: 15px;
    }

    .phaseType {
      min-width: 142px;
    }

    .analysisCount {
      display: flex;
      flex-direction: row;
      margin-right: 20px;
      font-weight: bold;
      font-size: 18px;

      @media (max-width: 640px) {
        font-size: 16px;
      }

      @media (max-width: 460px) {
        font-size: 14px;
      }

      @media (max-width: 360px) {
        font-size: 12px;
      }
    }

    @media (max-width: 640px) {
      .phaseType {
        margin-right: 0px;
        min-width: 0;
      }
    }
  }

  @media (max-width: 950px) {
    .leftHeader {
      margin-left: 15px;

      .title {
        margin-left: 0px;
      }
    }

    .rightHeader {
      .hamburger {
        display: flex;
        margin-top: 2px;
        cursor: pointer;
        margin-right: 4px;

        * {
          font-size: 1.3em;
        }
      }
    }
  }

  .lightIcon {
    color: $rich-black !important;
  }

  .darkIcon {
    color: $white !important;
  }

  @media (max-width: 1280px) {
    .leftHeader {
      .title {
        .subtitle {
          display: none;
        }
      }
    }
  }

  @media (max-width: 660px) {
    .leftHeader {
      flex-direction: column;
      justify-content: center;
      margin-left: 15px;

      .title {
        margin: 0;
      }
    }

    .analysisShared {
      .image {
        margin: 0 20px;
        height: 20px;
      }

      .titlePublic {
        font-size: 16px;
        line-height: normal;
      }
    }

    .rightHeader {
      margin-right: 15px;
    }
  }

  @media (max-width: 420px) {
    .analysisShared {
      .image {
        margin: 0 10px 0 20px;
        height: 18px;
      }

      .titlePublic {
        line-height: normal;
        font-size: 14px;
      }
    }
  }
}

.headerDark {
  color: $title-color-dark;
  background-color: $body-background-dark;
}

.headerLight {
  color: $dark-charcoal;
  background-color: $body-background-light;
}

.mobileHamburger {
  margin-left: 10px;

  i {
    font-size: 22px;
  }
}

.arrow {
  font-size: 1.4em;
}

.button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
