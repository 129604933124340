@use '../../../common/styleConstants.scss' as *;

.scrollGradient {
  overflow: auto !important;  
  
  background-position: left center, right center, left center, right center !important;
	background-repeat: no-repeat !important;
	background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100% !important;
	
	background-attachment: local, local, scroll, scroll !important;
}

.scrollGradientLight {
  background: 
    linear-gradient(to right, $background-light, $background-light),
    linear-gradient(to right, $background-light, $background-light),

    linear-gradient(to right, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0)) !important;   

	background-color: $background-light !important;
}

.scrollGradientDark {
  background: 
    linear-gradient(to right, $content-background, $content-background),
    linear-gradient(to right, $content-background, $content-background),

    linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)) !important;   

	background-color: $content-background !important;
}
