@use '../../../common/styleConstants.scss' as *;

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.lrvModal {
  :global .ant-modal-content {
    .ant-modal-close-x {
      width: 32px;
      height: 32px;
      line-height: normal;
      margin-right: 24px;
      margin-top: 16px;
      border-radius: 100px;

      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 1.5em;
      }
    }
  }
}

.lightModal {
  :global .ant-modal-content {
    background-color: $body-background-light;
    border-radius: 8px;

    .ant-modal-title {
      color: $title-color-light;
      font-size: 20px;
      border-radius: 8px;
      margin-top: 4px;
    }

    .ant-modal-close-x {
      transition: 0.3s;
      i {
        color: $title-color-light;
      }

      &:hover {
        transition: 0.3s;
        background-color: $btn-bg-hover-light;
      }
    }

    .ant-modal-header {
      background-color: $body-background-light;
      border: none;
      border-radius: 8px;
    }

    .ant-modal-body {
      background-color: $body-background-light;
      border-radius: 8px;
      padding: 0px 24px;
    }

    .ant-modal-footer {
      border-top-color: transparent;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 20px 25px 20px 25px;

      .ant-btn {
        text-transform: capitalize;
        border-radius: 4px;
        border: none;
        box-shadow: none;
        padding: 0px 12px 1px 12px;

        span {
          font-size: 14px;
        }
      }

      .ant-btn-default {
        background-color: transparent;
        color: $title-color-light;
      }

      .ant-btn.ant-btn-primary {
        color: $white;
        background-color: $primary-color-light;

        &:disabled {
          color: $title-color-light;
          background-color: $disabled-color;
        }

        &:hover:not(:disabled) {
          background-color: $primary-color-hover-light;
        }
      }
    }
  }
}

.darkModal {
  :global .ant-modal-content {
    background-color: $body-background-dark;

    .ant-modal-title {
      color: $title-color-dark;
    }

    .ant-modal-close-x {
      i {
        color: $title-color-dark;
      }

      &:hover {
        background-color: $btn-bg-hover;
      }
    }

    .ant-modal-header {
      background-color: $body-background-dark;
      border: none;
    }

    .ant-modal-body {
      background-color: $body-background-dark;
    }

    .ant-modal-footer {
      border-top-color: $body-background-dark;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 20px 25px;

      .ant-btn {
        text-transform: capitalize;
      }
    }
  }
}
