@use '../../../common/styleConstants.scss' as *;

.container {
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;

  .alignButtonRight {
    display: flex;
    justify-content: flex-end;
  }
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i::before {
      margin-right: 2px;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
