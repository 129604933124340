@use '../../../../common/styleConstants.scss' as *;

.editMaturation {
  .switch {
    display: flex;
    justify-content: flex-end;

    .status {
      margin-right: 8px;
    }
  }

  .addCodeButton {
    padding: 4px 15px 4px 11px;
    float: right;

    &:hover {
      color: $bright-gray;
    }
  }

  .codeRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    > div:first-child {
      width: 100%;
    }
  }

  .codeRowWithButton {
    > div:first-child {
      width: 90%;
    }
  }

  .codes {
    max-height: 240px;
    overflow-y: scroll;
    margin-bottom: 4px;
    padding-left: 2px;
    padding-top: 2px;
  }

  .activeMaturationButton,
  .activeMaturationCodeButton {
    background-color: $body-background-light;
    border: 1px solid $active-status-color;
    border-radius: 5px;
    color: $top-bar-icon;

    &:hover {
      background-color: $active-status-color;
      color: $bright-gray;
    }
  }

  .deactiveMaturationButton,
  .deactiveMaturationCodeButton {
    background-color: $body-background-light;
    border: 1px solid $ue-red;
    border-radius: 5px;
    color: $top-bar-icon;

    &:hover {
      background-color: $ue-red;
      color: $bright-gray;
    }
  }
}
