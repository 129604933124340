@use '../../common/styleConstants.scss' as *;

.alignButtonRight {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.rowsCircles {
  display: flex;

  .containerCircle {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    .circle {
      width: 28px;
      height: 28px;
      border-radius: 100px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        font-size: 22px;
      }
    }

    .circleSelected {
      width: 32px;
      height: 32px;
    }
  }
}

.rowsIcons {
  display: flex;

  .item {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .containerIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border-radius: 100px;

      .icon {
        font-size: 20px;
        cursor: pointer;
      }

      .iconSelectedLight {
        font-size: 22px;
        color: $white;

        &:hover {
          color: $white;
        }
      }

      .iconSelectedDark {
        font-size: 22px;
        color: $black;

        &:hover {
          color: $black;
        }
      }
    }

    .containerIconSelected {
      width: 32px;
      height: 32px;
      background-color: $bright-gray;
    }
  }
}
