@use '../../../../src/common/styleConstants.scss' as *;

.content {
  height: 100%;

  .calendar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    .cell {
      width: 100%;
      height: 100%;
    }
  }
}
