@use '../../common/styleConstants.scss' as *;

.widgetModal,
.appActivityModal,
.usersActivityModal,
.campusActivityModal,
.analysisResultsModal,
.analysisByStageModal {
  width: 100% !important;
  padding: 20px;

  .ant-modal-content {
    width: 100%;
    left: 0 !important;

    .ant-modal-body {
      padding: 0 0 15px;
      min-height: calc(100vh - 94px);
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.row {
  width: 100% !important;
  height: 100% !important;
}

.col {
  width: 100% !important;
  height: 100% !important;
}

.customCol {
  width: 100% !important;
  height: 90% !important;
}

.linkDark {
  cursor: pointer !important;
  color: white;

  &:hover {
    text-decoration: underline;
    color: white;
  }
}

.linkLight {
  cursor: pointer !important;
  color: $sonic-silver;

  &:hover {
    text-decoration: underline;
    color: $sonic-silver;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #696ffbcf !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: #595fd3 !important;
}

.ant-picker-cell-disabled:before {
  background: #dededed9 !important;
}

.maturationComparison,
.survivalRateChartModal {
  width: 100% !important;
  padding: 20px;
  top: 0;

  .ant-modal-content {
    width: 100%;
    left: 0 !important;

    .ant-modal-body {
      padding: 0 0 15px;
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.survivalRateHistogramModal {
  width: 60vw !important;
  top: calc((100vh - (100vh - 145px)) / 2);

  .ant-modal-content {
    width: 100%;
    left: 0 !important;

    .ant-modal-body {
      padding: 0 15px 15px 15px;
      min-height: calc(100vh - 200px);
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.samplesByUser {
  width: 60vw !important;
  top: 20px;

  @media (max-width: 1200px) {
    width: 72vw !important;
  }

  @media (max-width: 780px) {
    width: 80vw !important;
  }

  .ant-modal-content {
    width: 100%;
    left: 0 !important;

    .ant-modal-body {
      height: calc(100vh - 108px);
      overflow: auto;
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.userActivityDashboardModal {
  width: 920px !important;
  padding: 20px;
  top: 0;

  .ant-modal-content {
    width: 100%;

    .ant-modal-body {
      padding: 0 15px 15px 15px;
      height: calc(100vh - 140px);
    }

    @media (max-width: 1500px) {
      .ant-modal-body {
        height: calc(100vh - 110px);
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.weeklyUserActivityDashboardModal {
  width: 920px !important;
  top: 120px;

  .ant-modal-content {
    .ant-modal-body {
      height: calc(100vh - 320px);
    }
  }
}

@media (max-width: 1020px) {
  .weeklyUserActivityDashboardModal {
    top: 60px;

    .ant-modal-content {
      .ant-modal-body {
        height: calc(100vh - 200px);
      }
    }
  }
}

@media (max-width: 880px) {
  .weeklyUserActivityDashboardModal {
    top: 20px;

    .ant-modal-content {
      .ant-modal-body {
        height: calc(100vh - 140px);
      }
    }
  }
}

@media (max-width: 496px) {
  .weeklyUserActivityDashboardModal {
    top: 10px;

    .ant-modal-content {
      .ant-modal-body {
        height: calc(100vh - 80px);
      }
    }
  }
}
