@use '../../../../../src/common/styleConstants.scss' as *;

.tools {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 320px;
  border-radius: 8px;
  border: 1px solid $title-color-dark;
  background-color: $body-background-dark;
  padding: 15px;

  .toolRow {
    padding-left: 10px;

    .col {
      display: flex;
      align-items: center;

      .toolCheckbox {
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: center;
          font-size: 1em;

          &:first-child {
            margin-top: -4px;
          }
        }
      }

      .value {
        font-size: 1em;
      }
    }

    &:hover {
      background-color: $content-background;
      cursor: pointer;
    }

    .colorBox {
      margin-left: 20px;
      margin-right: 10px;
      display: inline-block;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      color: $title-color-light;

      .image {
        color: $title-color-light;
      }
    }

    .image {
      width: 14px;
      height: 14px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    .group {
      display: flex;

      .iconContainer {
        margin-right: 18px;
        cursor: pointer;
      }
    }

    .label {
      font-size: 0.9em;
    }

    .labelLight {
      font-weight: bold;
      color: $title-color-light;
    }

    .labelDark {
      color: $title-color-dark;
    }
  }
}

@media (max-width: 600px) {
  .tools {
    position: sticky;
    bottom: 110px;
    margin: 0 auto;
  }
}

@media (max-width: 380px) {
  .tools {
    width: 280px;
    bottom: 130px;

    .toolRow {
      .col {
        .toolCheckbox {
          span {
            font-size: 0.9em;
          }
        }

        .value {
          font-size: 0.9em;
        }
      }
    }
  }
}

.toolsDark {
  border: 1px solid $title-color-dark;
  background-color: $body-background-dark;

  .toolRow {
    &:hover {
      background-color: $content-background;
    }

    .toolCheckbox {
      color: $title-color-dark;
    }
  }
}

.toolsLight {
  border: 1px solid $title-color-light;
  background-color: $body-background-light;

  .toolRow {
    &:hover {
      background-color: $row-selected-light;
    }

    .toolCheckbox {
      color: $title-color-light;
    }
  }
}
