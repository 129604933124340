@use '../../common/styleConstants.scss' as *;

.inputUpperCase {
  text-transform: uppercase;
}

.modules {
  width: 100%;
  margin: 0px 10px;
  margin-top: -5px;

  .rowHeader {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding: 10px 0px;

    .btnAddModule {
      padding: 4px 15px 4px 11px;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .rowFlex {
    display: flex;
    flex: 1;
    width: 100%;

    height: calc(100vh - 230px) !important;

    .moduleRow {
      margin: 0px 10px !important;
    }

    .selectedRowDark {
      background-color: $row-selected-dark !important;
    }

    .selectedRowLight {
      background-color: $row-selected-light !important;
    }
  }
}

.confirmModal {
  width: 100%;

  .description {
    font-size: 14px;
  }

  .campusInactive {
    color: $title-color-dark;
    background-color: $persimmon;
    border-radius: 2px;
    height: auto;
    font-size: 12px;
    text-transform: none;
    padding: 2px 5px 2px 5px;
  }
}

.optionsCell {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i {
      margin-right: 2px !important;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
