@use '../../styleConstants.scss' as *;

.lightConfirm {
  :global {
    .ant-modal-content {
      background-color: $body-background-light;
      border-radius: 8px;

      .ant-modal-confirm-body {
        svg {
          fill: $title-color-light;
        }

        span.ant-modal-confirm-title {
          color: $title-color-light;
        }

        .ant-modal-confirm-content {
          color: $sonic-silver;
        }
      }

      .ant-modal-confirm-btns {
        float: right;

        .ant-btn.ant-btn-default {
          background-color: transparent;
          border: none;
          color: $title-color-light;
        }

        .ant-btn.ant-btn-primary {
          background-color: $primary-color-light;
  
          &:hover:not(:disabled) {
            background-color: $primary-color-hover-light;
          }
        }

        .ant-btn.ant-btn-default.ant-btn-dangerous {
          border-radius: 5px;
          background-color: $primary-color-dark;
          color: $bright-gray;

          &:hover {
            background-color: $primary-color-hover-dark;
          }
        }
      }
    }
  }
}
