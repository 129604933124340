.container {
  width: 100%;
  height: calc(50vh - 120px);
  position: relative;

  div {
    img {
      border-radius: 8px;
    }
  }
}

@media (max-width: 1420px) {
  .container {
    height: calc(50vh - 130px);
  }
}

.notAvailable {
  width: 100%;
  height: calc(50vh - 118px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.1em;
  color: white;
}

.row {
  justify-content: space-between;
  padding-bottom: 10px;
}

.map {
  width: 100%;
}
