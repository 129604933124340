@use '../../common/styleConstants.scss' as *;

.accreditation {
  font-weight: bold;
  fill: $green-crayola;
}

.debit {
  font-weight: bold;
  fill: $salmon;
}

.availableQuota {
  font-weight: bold;
  fill: $sonic-silver;
}

.crossedLines {
  stroke: $sonic-silver;
  stroke-width: 1.5px;
  stroke-dasharray: 4;
}

.lines {
  stroke-width: 2.5px;
}

.axisX {
  line,
  path {
    stroke: $dim-gray;
    stroke-width: 0.5;
  }
  text {
    fill: $sonic-silver;
    font-size: 11px;
  }
}

.axisY {
  line,
  path {
    stroke: $dim-gray;
    stroke-width: 0.5;
  }
  text {
    fill: $sonic-silver;
    font-size: 11px;
  }
}

.hours {
  fill: $sonic-silver;
  font-size: 11px;
}

.tooltip {
  z-index: 9 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  -webkit-transition: 250ms ease-in-out;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;

  &.rightAlignedTooltip {
    &:before {
      -webkit-transform: rotate(135deg) skew(15deg, 15deg);
      transform: rotate(135deg) skew(15deg, 15deg);

      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;
      left: unset;
      right: -8px;
    }

    &:after {
      left: unset;
      right: -16px !important;
      border-width: 8px 0 8px 16px;
    }
  }

  &.leftAlignedTooltip {
    &:before {
      -webkit-transform: rotate(135deg) skew(25deg, 25deg);
      transform: rotate(135deg) skew(25deg, 25deg);

      -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;
      top: calc(50% - 8px);
      left: -8px;
      right: unset;
    }
  }

  &.bottomAlignedTooltip {
    &:before {
      -webkit-transform: rotate(45deg) skew(15deg, 15deg);
      transform: rotate(45deg) skew(15deg, 15deg);

      -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;
      left: calc(50% - 8px);
      top: unset;
      bottom: -8px;
    }

    &:after {
      left: calc(50% - 8px);
      top: unset;
      bottom: -16px !important;
      border-width: 16px 8px 0 8px;
      border-color: white transparent;
    }
  }

  .contentTooltip {
    background-color: white;
    border-radius: 5px;
    min-height: 60px;
    max-height: 200px;
    overflow: auto;
    width: 300px;

    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);

    hr {
      border: 0;
      height: 1px;
      background: #d5d5d5;
      margin: 0;
    }

    .entry {
      padding: 10px;
      color: black;
      display: flex;
      justify-content: flex-start;

      .entryAvatar {
        margin-right: 10px;

        .avatar {
          background-color: yellow;
          width: 26px;
          height: 26px;
          display: flex !important;
          justify-content: center !important;
          align-items: center;
          border-radius: 35px;
          border: 1px solid gold;

          span {
            color: $raisin-black;
            font-weight: bold;
            font-size: 11px;
          }
        }
      }

      .entryContent {
        margin-top: 3px;

        * {
          font-size: 12px !important;
        }

        .statIcon {
          align-items: center;
          display: flex;
        }

        .quotaPrevious {
          text-decoration: line-through;
          font-size: 10px;
          font-weight: bold;
          color: red;
          margin-left: 4px;
        }
      }

      @media (max-width: 540px) {
        .entryContent {
          * {
            font-size: 10px !important;
          }
        }
      }
    }
  }

  @media (max-width: 540px) {
    .contentTooltip {
      width: 270px;
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 16px 6px 0;
    border-color: transparent white;
    display: block;
    width: 0;
    z-index: 1;
    left: -16px;
    top: calc(50% - 6px);
  }
}
