.containerMap {
  .leaflet-bottom,
  .leaflet-right {
    display: none;
  }

  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-popup-content {
    margin: 10px 12px 10px 12px;
  }
}
