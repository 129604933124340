@use '../../../../src/common/styleConstants.scss' as *;

.previousNextButtons.lightTheme {
  .leftButton {
    background-color: $primary-color-light;
    border-color: $primary-color-light;
    color: $title-color-light;
  }

  .rightButton {
    background-color: $primary-color-light;
    border-color: $primary-color-light;
    color: $title-color-light;
  }

  .leftButton, .rightButton {
    &:not(.disabledButton):hover {
      background-color: $primary-color-hover-light;
    }
  }

  .disabledButton {
    background: $disabled-color !important;
    border: 1px solid $disabled-color !important;
    color: $primary-color-light !important;
    cursor: not-allowed;
  }

  .disabledIcon {
    color: $primary-color-light;
  }
}

.previousNextButtons.darkTheme {
  .leftButton {
    background-color: $primary-color-dark;
    border-color: $primary-color-dark;
    color: $title-color-dark;
  }

  .rightButton {
    background-color: $primary-color-dark;
    border-color: $primary-color-dark;
    color: $title-color-dark;
  }

  .leftButton, .rightButton {
    &:not(.disabledButton):hover {
      background-color: $primary-color-hover-dark;
    }
  }

  .disabledButton {
    background: $disabled-color !important;
    border: 1px solid $disabled-color !important;
    color: $primary-color-dark !important;
    cursor: not-allowed;
  }

  .disabledIcon {
    color: $primary-color-dark;
  }
}

.previousNextButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;

  .ant-tooltip-disabled-compatible-wrapper {
    border-color: transparent !important;
  }

  .leftButton {
    height: 28px;
    line-height: 26px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .rightButton {
    height: 28px;
    line-height: 26px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .disabledButton {
    cursor: not-allowed;
  }

  .icon {
    color: $disabled-color;
  }
}
