@use '../../../../src/common/styleConstants.scss' as *;

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.containerMain {
  background-color: white;
  margin: auto;
  height: 1187px;
  width: 1680px;

  .colData {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    .stockings {
      margin-top: 10px;
    }

    .labelAxisX {
      text-align: center;
      font-size: 1em;
      color: $quick-silver;
    }

    .originsMultiphase {
      color: $quick-silver;
      position: relative;
      margin: 0px 72px 0 72px;

      .rowClassName {
        td {
          div {
            color: $davys-grey;
          }
        }
      }
    }

    .chart {
      background-color: white;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .legends {
    position: relative;
    margin: 10px 72px 0 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .labelAxisY {
      font-size: 1em;
      color: $quick-silver;
    }

    .transparent {
      color: transparent;
    }
  }
}
