@use '../../../common/styleConstants.scss' as *;

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 16px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 8px;

    .lightText {
      color: $davys-grey-5;
      font-size: 13px;
    }

    .darkText {
      color: $bright-gray;
      font-size: 13px;
    }

    .line {
      margin-left: 20px;
      margin-right: 8px;
      display: inline-block;
      width: 28px;
      height: 1px;
    }

    .parameter {
      background-color: $blue-ryb;
      height: 1.5px;
    }

    .referenceLine {
      border: 1px dashed $green-screamin;
    }
  }
}
