@use '../../common/styleConstants.scss' as *;

.settingClientModal {
  :global .ant-modal-content .ant-modal-body {
    overflow-y: scroll;
  }

  .assignedCompanies {
    background-color: $body-background-light;
    border: 1px solid $silver-chalice;

    :global .ant-card-head {
      border-bottom: 1px solid $silver-chalice;

      .ant-card-head-title {
        color: $sonic-silver;
        font-weight: normal;
      }
    }
  }
}
