@use '../../../common/styleConstants.scss' as *;

.containerNormalLegend {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .normalLegend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .labelAxisY {
      text-align: center;
      font-size: 1em;

      .lightText {
        color: $davys-grey-5;
      }

      .darkText {
        color: $bright-gray;
      }
    }

    .icons {
      display: flex;
      justify-self: flex-end;
      font-size: 16px;
      margin-right: 12px;

      .icon {
        margin: 0 4px;

        svg {
          display: flex;
          align-items: center;
          align-content: center;
          color: white;
          margin-top: -2px;
          padding: 3px;
        }
      }
    }

    .legend {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 8px;

        .lightText {
          color: $davys-grey-5;
        }

        .darkText {
          color: $bright-gray;
        }

        .circle {
          margin-left: 20px;
          margin-right: 4px;
          margin-bottom: 2px;
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 100px;
        }

        .below {
          background-color: crimson;
        }

        .over {
          background-color: green;
        }

        .darkNormal {
          background-color: $light-silver;
        }

        .lightNormal {
          background-color: $maximun-blue-purple;
        }

        .consolidated {
          background-color: $state-blue;
        }

        .colorBox {
          margin-left: 4px;
          margin-right: 4px;
          display: inline-block;
          width: 28px;
          height: 4px;
        }

        .pigmentation {
          border-style: solid;
          border-color: $primary-color-dark;
          border-width: 1px;
          height: 1px;
        }

        .growthDelta {
          border-style: solid;
          border-color: $primary-color-dark;
          border-width: 1px;
          height: 1px;
        }

        .movingAverage {
          border-style: dashed;
          border-color: $gray;
          border-width: 1px;
          height: 1px;
        }
      }
    }
  }
}
