@use '../../../common/styleConstants.scss' as *;

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;

  .containerParameters {
    border-radius: 8px;
    padding: 4px 12px;
    display: flex;
    align-items: center;

    .parameters {
      display: flex;

      .parameter,
      .dateOption {
        cursor: pointer;

        span {
          font-size: 13px;

          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .selectedParameterLight,
      .selectedDateOptionLight {
        background-color: $row-selected-light;
      }

      .selectedParameterDark,
      .selectedDateOptionDark {
        background-color: $row-selected-dark;
      }

      div {
        padding: 2px 5px;
        border-radius: 8px;
      }
    }
  }
}
