@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  position: relative;
  width: 100%;
  height: 95%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.stockingParameters {
  background-color: white;
  margin: auto;
  height: 1187px;
  width: 1680px;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95%;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $davys-grey;
    padding-top: 20px;

    div {
      font-size: 1.8em;
      text-transform: uppercase;
    }

    span {
      font-size: 1.8em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .body {
    width: 100%;

    .grid {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      padding: 4px 8px;

      .containerText {
        width: 100%;
        height: 100%;
        padding: 12px 0px 12px 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .label {
          text-align: left;
          font-size: 14px;

          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 1.1;
        }

        .red {
          color: $mandy;
        }

        .green {
          color: limegreen;
        }

        .value {
          text-align: left;
          font-size: 25px;
          line-height: 1.1;
        }
      }
    }

    .chart {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      padding: 16px 24px 16px 20px;

      .text {
        text-align: center;
      }

      .containerLegends {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .rowLegends {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 20px 0px 6px 56px;

          .containerAxisYLabel {
            font-size: 14px;

            .axisYLabel {
              width: 160px;
              line-height: 1;
            }

            .axisY2Label {
              text-align: right;
            }

            .axisY3Label {
              right: -20px;
              width: 102px;
              position: relative;
            }

            div > span {
              font-size: 13px;
            }
          }

          .legends {
            display: flex;

            .legend {
              display: flex;
              font-size: 13px;
              align-items: center;
              justify-content: center;

              .square {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }

              .plusSign {
                font-size: 20px;
                font-weight: bold;
                margin-right: 4px;
              }

              .circle {
                width: 12px;
                height: 12px;
                border-radius: 100px;
                margin-right: 4px;
              }

              .totalFeedDark {
                background-color: royalblue;
              }

              .totalFeedLight {
                background-color: $maximun-blue-purple;
              }

              .growthRateDark {
                color: $white;
              }

              .growthRateLight {
                color: royalblue;
              }

              .parameterWithoutFrequencies {
                background-color: indianred;
              }
            }
          }
        }
      }

      .containerChart {
        height: 100%;
        width: 100%;

        .parameterChart {
          position: relative;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
