.spinner {
  position: absolute;
  width: calc(100%);
  height: calc(100% - 58px);
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}
