@use '../../../common/styleConstants.scss' as *;

.lightRadio {
  color: $sonic-silver;

  :global {
    .ant-radio-inner {
      border-color: $silver-chalice;
      background-color: $body-background-light;
    }

    .ant-radio-inner::after {
      background-color: $primary-color-dark;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $primary-color-dark;
    }
  }
}
