@use '../../../common/styleConstants.scss' as *;

.container {
  background-color: transparent;
  display: flex;
  flex-direction: column;

  :global {
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer)
      .ant-select-selector {
      background-color: transparent;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: transparent;
    }
  }
}

.darkContainer {
  .darkTitle {
    color: $dark-gray-x11;
    font-size: 14px;
  }

  .darkSelect {
    :global {
      .ant-select-selector {
        transition: 0s;
        background-color: $body-background-dark;
        border: 1px solid $dropdown-border-color;
        border-radius: 4px;

        .ant-select-selection-item {
          transition: 0s;
          color: $bright-gray;

          i {
            color: inherit;
          }
        }
      }

      .ant-select-arrow i {
        color: $title-color-dark;
      }
    }
  }

  .darkSelect.disabled {
    :global {
      .ant-select-selector {
        transition: 0s;
        border: 1px solid $violet-blue;
        background-color: $body-background-dark;
        border-radius: 4px;
      }

      .ant-select-selection-placeholder {
        color: $sonic-silver;
      }
    }
  }

  :global {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: $bright-gray;
    }

    .ant-select-multiple {
      .ant-select-selection-item {
        background: $body-background-dark;
        border: 1px solid $dropdown-border-color;

        .ant-select-selection-item-content {
          color: $bright-gray;
        }

        .ant-select-selection-item-remove {
          span {
            color: $bright-gray;
          }
          i {
            color: $bright-gray;
          }
        }
      }
    }
  }
}

.lightContainer {
  .lightTitle {
    color: $title-color-light;
    font-size: 14px;
  }

  .lightSelect {
    :global {
      .ant-select-selector {
        transition: 0s;
        border: 1px solid $silver-chalice;
        background-color: $body-background-light;
        border-radius: 4px;

        .ant-select-selection-item {
          transition: 0s;
          color: $dark-charcoal-3;

          i {
            color: inherit;
          }
        }
      }

      .ant-select-arrow i {
        color: $spanish-gray;
      }

      .ant-select-selection-search-input {
        color: $dark-charcoal-3;
      }
    }
  }

  .lightSelect.disabled {
    :global {
      .ant-select-selector {
        transition: 0s;
        border: 1px solid $disabled-color;
        background-color: $body-background-light;
        border-radius: 4px;

        .ant-select-selection-placeholder {
          color: $disabled-color;
        }
      }
    }
  }

  :global {
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: 0 0 0 1px $blue-50;
      border: 1px solid $primary-color-dark;
    }

    .ant-select-multiple {
      .ant-select-selection-item {
        background: $white;
        border: 1px solid $chinese-silver;

        .ant-select-selection-item-content {
          color: $title-color-light;
        }

        .ant-select-selection-item-remove {
          span {
            color: $title-color-light;
          }
          i {
            color: $title-color-light;
          }
        }
      }
    }
  }
}

.darkPopup {
  background-color: $body-background-dark;

  :global {
    .rc-virtual-list-holder .rc-virtual-list-holder-inner {
      .ant-select-item-option-selected {
        background-color: $body-background-dark;
      }
    }
  }
}

.lightPopup {
  background-color: $body-background-light;

  :global {
    .ant-select-item-group {
      color: $dark-charcoal-3;
    }

    .rc-virtual-list-holder .rc-virtual-list-holder-inner {
      .ant-select-item.ant-select-item-option {
        background-color: $body-background-light;

        .ant-select-item-option-content {
          color: $dark-charcoal-3;
          i {
            color: $dark-charcoal-3;
          }
        }
      }

      .ant-select-item-option-selected {
        background-color: $body-background-light;
      }

      .ant-select-item.ant-select-item-option.ant-select-item-option-active:hover {
        background-color: $dropdown-hover-color-light;
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: $body-background-light;
      }
    }

    .ant-select-dropdown {
      background-color: $body-background-light;
    }
  }
}
