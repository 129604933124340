@use '../../../common/styleConstants.scss' as *;

.container {
  width: 220px;

  .tanks {
    overflow: auto;
    height: calc(100vh - 300px);

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
    }

    .itemList {
      cursor: auto;

      span {
        font-size: 14px;
      }

      .btnAddLocation {
        border-radius: 4px;
      }
    }
  }

  .tanksDark {
    .tank {
      color: $cultured;
    }

    &::-webkit-scrollbar {
      border-left: 1px solid $dropdown-border-color !important;
    }

    .tankSelected {
      color: $cultured;

      &:global.ant-list-item {
        border-left: 6px solid $primary-color-dark;
        padding-left: 10px;
      }
    }
  }

  .tanksLight {
    .tank {
      color: $top-bar-icon;
    }

    &::-webkit-scrollbar {
      border-left: 1px solid $dropdown-border-color-light !important;
    }

    .tankSelected {
      color: $top-bar-icon;

      &:global.ant-list-item {
        border-left: 5px solid $primary-color-light;
        padding-left: 11px;
      }
    }
  }

  .tanksTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;

    .title {
      font-size: 14px;
      margin-top: 2px;
    }

    .icons {
      display: flex;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 2px;
        margin-left: 6px;
        width: 24px;
        height: 24px;

        i {
          transition: 0.2s;
        }
      }

      .icon:not(.disabled):hover {
        i {
          transition: 0.2s;
          color: $sonic-silver;
        }
      }

      .disabled {
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }

  .dropdownContainerDark {
    .icon {
      background-color: $indigo;
    }
  }

  .dropdownContainerLight {
    .icon {
      background-color: $lavander-blue;
    }
  }
}
