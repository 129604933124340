@use '../../common/styleConstants.scss' as *;

.harvestsStockingInfoModal {
  .stockingName {
    font-size: 14px;
  }

  .options {
    display: flex;
    justify-content: flex-end;

    .actionButton {
      i {
        color: $dark-charcoal-3;
      }
    }
  }

  .footerModal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .closeStockingBtn {
      margin-left: 10px;
    }
  }
}
