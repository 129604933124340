@use '../../../common/styleConstants.scss' as *;

.containerFull {
  height: calc(100vh - 100px);
  margin-top: -15px;
  padding: 0 15px;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;

    .filters {
      .select {
        width: 150px;
      }
    }

    .rowRight {
      display: flex;
      align-items: flex-end;
    }

    .sidePanel {
      display: none;
    }
  }

  @media (max-width: 1510px) {
    .header {
      .filters {
        .select {
          width: 124px;
        }
      }
    }
  }

  @media (max-width: 1320px) {
    .header {
      align-items: center;

      .filters {
        display: none;
      }

      .sidePanel {
        display: flex;

        .title {
          color: $title-color-light;
          font-size: 16px;
          font-weight: bold;
        }

        .bodyPanel {
          width: 100%;
        }
      }
    }
  }

  .height {
    height: 96%;
  }

  .row {
    margin-top: 16px;

    .col {
      width: 100%;

      .icons {
        display: flex;
        justify-content: flex-end;

        .icon {
          margin: 0 4px;

          svg {
            padding: 3px;
          }
        }
      }

      .table {
        margin-top: 10px;
      }
    }

    .containerChart {
      .labelAxisX {
        text-align: center;
        font-size: 1em;
      }

      .labelAxisY {
        font-size: 1em;

        .lightText {
          color: $davys-grey-5;
        }

        .darkText {
          color: $bright-gray;
        }
      }

      .axisLight {
        span {
          color: $davys-grey-5;
        }
      }

      .axisDark {
        span {
          color: $philippine-gray;
        }
      }

      .containerLegends {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-direction: row;

        .legend {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          .item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px 8px;

            .lightText {
              color: $davys-grey-5;
            }

            .darkText {
              color: $bright-gray;
            }

            .colorBox {
              margin-left: 4px;
              margin-right: 4px;
              display: inline-block;
              width: 28px;
              height: 4px;
            }

            .pigmentation {
              border-style: solid;
              border-color: $primary-color-dark;
              border-width: 1px;
              height: 1px;
            }

            .movingAverage {
              border-style: dashed;
              border-color: $gray;
              border-width: 1px;
              height: 1px;
            }
          }
        }
      }

      .chart {
        position: relative;
      }
    }
  }

  @media (max-width: 1199px) {
    .row {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 94%;
    }
  }

  .graphInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}
