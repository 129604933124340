@use '../../../common/styleConstants.scss' as *;

.lightInput {
  transition: 0s;
  border: 1px solid $silver-chalice;
  background-color: $body-background-light;
  background: $body-background-light !important;
  border-radius: 4px;

  :global {
    .ant-input {
      transition: 0s;
      color: $sonic-silver;
      background-color: $body-background-light;
      background: $body-background-light;
    }

    .ant-input-suffix {
      color: $sonic-silver;
    }

    .ant-input-password-icon.anticon {
      color: $sonic-silver;
    }
  }
}

.darkInput {
  transition: 0s;
  border: 1px solid $dropdown-border-color;
  border-radius: 4px;

  :global {
    .ant-input {
      transition: 0s;
      color: $title-color-dark;
      background-color: transparent;
      background: transparent;
    }

    .ant-input-suffix {
      color: $title-color-dark;
    }

    .ant-input-password-icon.anticon {
      color: $title-color-dark;
    }
  }
}
