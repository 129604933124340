@use '../../../common/styleConstants.scss' as *;

.showResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;

  .icon {
    color: $orange-red !important;
  }

  .message {
    font-size: 18px;
    position: relative;
    .icon {
      font-size: 1.5em;
      position: absolute;
      top: -8px;
      left: -35px;
      color: indianred;
    }
  }
}
