.settings {
  width: 100%;
  margin-top: -15px;
}

.columnFlex {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .content {
    height: 100%;

    .tabPane {
      min-height: 340px;
    }

    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}