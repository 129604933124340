@use '../../../../src/common/styleConstants.scss' as *;

.containerQRCode {
  margin: 3px 8px 3px 8px;
  padding: 10px 10px 5px 10px;
  border: 3px solid #dbeeee;

  .qrcode {
    background-color: $white;
  }

  .name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    text-align: center;
    max-width: 90px;
    line-height: 1;
    color: black;
    font-size: 12px;
  }
}
