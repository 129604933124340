@use '../../../common/styleConstants.scss' as *;

.button {
  &:global.ant-btn.ant-btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
  }
}

.lightButton {
  color: $sonic-silver;
  background-color: transparent;
  border: 1px solid $violet-blue;
  border-radius: 5px;
  display: block;

  span {
    align-self: center;
  }

  &:hover,
  &:focus {
    background-color: $sonic-silver;
    color: $title-color-light;
  }

  &:global.ant-btn.ant-btn-link {
    &:hover {
      border-radius: 50px;
      background-color: $btn-bg-hover-light;
    }
  }
}

.darkButton {
  color: $title-color-dark;

  background-color: $body-background-dark;
  color: $gray-x11;
  border: 1px solid $violet-blue;
  border-radius: 5px;
  display: flex;
  align-self: center;

  span {
    align-self: center;
  }

  &:hover,
  &:focus {
    background-color: $body-background-dark;
    color: $title-color-dark;
  }
}
