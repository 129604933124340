@use '../../../../../src/common/styleConstants.scss' as *;

.loading {
  position: relative;
  height: calc(50vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: calc(50vh - 120px);
  position: relative;

  .actions {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -33px;
    right: 10px;

    .warningIcon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: darkorange;
      cursor: pointer;
    }
  }

  .previousNextButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 20px;

    .ant-tooltip-disabled-compatible-wrapper {
      border-color: transparent !important;
    }

    .leftButton {
      background-color: $primary-color-dark;
      border-color: $primary-color-dark;
      color: $title-color-dark;
      height: 28px;
      line-height: 26px;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    .rightButton {
      background-color: $primary-color-dark;
      border-color: $primary-color-dark;
      color: $title-color-dark;
      height: 28px;
      line-height: 26px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .disabledButton {
      background: $disabled-color !important;
      border: 1px solid $disabled-color !important;
      color: $primary-color-dark !important;
      cursor: not-allowed;
    }

    .icon {
      color: $disabled-color;
    }

    .disabledIcon {
      color: $primary-color-dark;
    }
  }
}

@media (max-width: 1420px) {
  .container {
    height: calc(50vh - 130px);
  }
}

.containerImage {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  canvas {
    border-radius: 8px;
  }
}

.containerFullScreen {
  width: 100%;
  position: relative;

  .containerImageFullScreen {
    width: 100%;
    margin: auto;
    position: relative;
  }

  .editSample {
    position: absolute;
    top: 20px;
    left: 15px;
    border-radius: 8px;
    padding: 5px;
  }

  .floatingButton {
    background-color: $primary-color-dark;
    bottom: 64px;

    .filterIcon {
      font-size: 1.5em;
    }
  }
}

.center {
  position: absolute;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 1.1em;
  color: white;
}

.row {
  justify-content: space-between;
  padding-bottom: 10px;
}

.generateJuvenileMask {
  color: greenyellow;
}
