.container {
  width: 100%;
  margin: -28px 10px 0;

  .tabs {
    position: relative;

    .tab {
      height: 100%;
    }
  }
}
