@use './../../styleConstants.scss' as *;

.hideFullScreen {
  visibility: hidden;
  opacity: 0;
  transform: translateX(100px);
  transition: 0.3s;
}

.showFullScreen {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
  transition: 0.3s;
}

.fullScreenFrame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 99;

  .header {
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;

    .title {
      align-self: flex-start;
      font-size: 1.3em;
    }

    .titleDark {
      color: $title-color-dark;
    }

    .titleLight {
      color: $title-color-light;
    }

    .close {
      cursor: pointer;
      margin-right: 10px;
      top: 12px;

      * {
        font-size: 1.3em;
      }
    }
  }

  @media (max-width: 540px) {
    .header {
      padding: 0;
      margin: 0 auto;
    }

    .title {
      width: 100%;
      text-align: center;
      font-weight: bold;
    }

    .close {
      top: 0 !important;
    }
  }
}

.fullScreenFrameDark {
  background-color: $body-background-dark;

  .header {
    background-color: $body-background-dark;
  }
}

.fullScreenFrameLight {
  background-color: $body-background-light;

  .header {
    background-color: $body-background-light;
  }
}
