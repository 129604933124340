@use '../../../common/styleConstants.scss' as *;

.container {
  .lightDragger {
    background-color: $body-background-light;
    border-color: $silver-chalice;

    :global {
      .ant-upload-drag-container {
        color: $sonic-silver;
      }

      .anticon-inbox {
        svg {
          font-size: 48px;
        }
      }
    }
  }
}
