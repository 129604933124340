@use '../../common/styleConstants.scss' as *;

#menu-notifications-submenu-popup {
  background-color: transparent;
  border-radius: 12px;
}

.spin {
  text-align: center;
}

.menu {
  li {
    div {
      padding-right: 16px !important;

      i {
        display: none;
      }
      span {
        i {
          display: inline;
        }
      }
    }
  }
}
