@use '../../../common/styleConstants.scss' as *;

.survivalRateHistogram {
  height: calc(100vh - 260px);
  margin: 0 15px;

  .hide {
    display: none;
  }

  .labelAxisX {
    text-align: center;
    position: absolute;
    width: 100%;
    font-size: 14px;
  }

  .labelAxisY {
    text-align: center;
    position: absolute;
    left: -100px;
    bottom: calc((100vh - 240px) / 2);
    font-size: 14px;
    transform: rotate(270deg);
  }

  .filters {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 15px;
    margin: 0px auto;

    .separator {
      width: 2px;
      background-color: $body-background-dark;
      height: 32px;
      margin: 0 15px 0 7px;
    }

    .select {
      margin-right: 8px;
      width: 150px;
    }

    .datePicker {
      width: 150px;
      margin-right: 8px;
    }
  }
}

.containerResult {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
  width: 100%;
  margin: 0 -15px;

  .result {
    margin: auto;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  i {
    font-size: 40px;
  }
}
