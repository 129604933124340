@use '../../common/styleConstants.scss' as *;

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .select {
    width: 140px;
  }

  .checkbox {
    margin-left: 6px;
    margin-bottom: 4px;
  }

  .sidePanel {
    display: none;
  }
}

@media (max-width: 1120px) {
  .container {
    .select {
      width: 125px;
    }
  }
}

@media (max-width: 760px) {
  .container {
    .filters {
      display: none;
    }

    .sidePanel {
      display: flex;

      .title {
        color: $title-color-light;
        font-size: 16px;
        font-weight: bold;
      }

      .bodyPanel {
        width: 100%;
      }
    }
  }
}
