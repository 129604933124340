@use '../../../src/common/styleConstants.scss' as *;

.formOptions {
  display: flex;
  justify-content: space-between;
}

.loginForm {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #030829 inset !important;
    -webkit-text-fill-color: white;
    border-radius: 0;
  }
}

.forgotPassword {
  padding-top: 7px;
  color: $title-color-dark;

  &:hover {
    color: $title-color-dark;
  }
}

.loginIcon {
  display: none;
}

.alertError {
  color: $orange-red;
  clear: both;
  min-height: 24px;
  padding-top: 0px;
  font-size: 14px;
  line-height: 1.5715;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
