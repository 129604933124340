@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  position: relative;
  width: 100%;
  height: 100%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.prevStats {
  margin: -15px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .filtersContainer {
    .filters {
      .select {
        width: 160px;
      }
    }
  }

  .main {
    width: 100%;
    margin-top: 20px;
    display: flex;

    .availableTanks {
      padding: 0px 10px 0px 10px;
      margin: 20px 10px 10px 10px;
      min-width: 200px;
      max-width: 240px;
      height: calc(100vh - 200px);
      overflow-y: auto;

      .phaseTypeContainer .divider span {
        font-weight: 500;
      }

      .module {
        font-size: 14px;
      }

      .tanksContainer {
        margin: 5px 0px 5px 0px;

        .tankItem {
          padding-left: 15px;

          .checkbox {
            span {
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                width: 14px;
                height: 14px;
              }
            }
          }

          .tankLabel {
            font-size: 13px;
          }
        }
      }

      .prevModulesContainer {
        margin: 15px 0px 15px 0px;
        width: 180px;
      }
    }

    .availableTanks.availableTanksLight {
      .phaseTypeContainer .divider {
        width: 100%;
        border-bottom: 1px solid $philippine-gray;

        span {
          color: $davys-grey-5;
        }
      }
    }

    .availableTanks.availableTanksDark {
      .phaseTypeContainer .divider {
        width: 100%;
        border-bottom: 1px solid $chinese-white;

        span {
          color: $bright-gray;
        }
      }
    }

    .chart {
      position: relative;

      .centerAbsolute {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;

        .emptyContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: calc(100% - 120px);
          margin: 0;
        }

        &.lightBackground {
          background-color: $body-background-light;
        }

        &.darkBackground {
          background-color: $body-background-dark;
        }
      }

      .prevStatsChart {
        position: relative;
      }
    }

    .tagContainer {
      padding: 0px 5px;
      border-radius: 3px;
      margin-right: 4px;
      margin-top: 2px;
      margin-bottom: 2px;
      min-width: 75px;

      .tagItem {
        display: flex;
        justify-content: space-between;

        .tagLabel,
        i {
          color: $black;
        }

        i {
          cursor: pointer;
        }
      }
    }

    .rowData {
      .labelAxisX {
        margin-right: -20px;
        text-align: center;
      }
    }

    .labelAxisX {
      margin-top: 0px;
      text-align: center;
      font-size: 1em;

      .lightText {
        color: $davys-grey-5;
      }

      .darkText {
        color: $bright-gray;
      }
    }

    .labelAxisY {
      text-align: left;
      font-size: 1em;
      margin-left: 30px;
      margin-bottom: 8px;

      .lightText {
        color: $davys-grey-5;
      }

      .darkText {
        color: $bright-gray;
      }
    }
  }
}

.disabledOptions :global {
  .rc-virtual-list-holder .rc-virtual-list-holder-inner {
    .ant-select-item.ant-select-item-option.ant-select-item-option:not(
        .ant-select-item-option-selected
      ) {
      cursor: not-allowed;

      .ant-select-item-option-content {
        color: $inactive-status-color;
      }
    }

    .ant-select-item.ant-select-item-option.ant-select-item-option-active:not(
        .ant-select-item-option-selected
      ):hover {
      background-color: $white;
    }
  }
}
