@use '../../../common/styleConstants.scss' as *;

.radioGroup {
  box-shadow: none;

  :global {
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):focus-within {
      box-shadow: none;
    }
  }
}

.lightRadioGroup {
  display: flex;
}

.darkRadioGroup {
  display: flex;
}

.lightContainer {
  .lightTitle {
    color: $dropdown-color-light;
    font-size: 14px;
  }

  :global {
    .ant-radio {
      .ant-radio-inner {
        background-color: $white;
        border-color: $dropdown-color-light;

        &::after {
          background-color: $white;
        }
      }
    }
    .ant-radio-wrapper {
      color: $dropdown-color-light;
      span {
        font-size: 12px;
      }
    }

    .ant-radio-checked .ant-radio-inner {
      background-color: blue;
      border-color: blue;
    }
  }
}

.darkContainer {
  .darkTitle {
    color: $dark-gray-x11;
    font-size: 14px;
  }

  :global {
    .ant-radio {
      .ant-radio-inner {
        background-color: $white;
        border-color: $dark-gray-x11;

        &::after {
          background-color: $white;
        }
      }
    }
    .ant-radio-wrapper {
      color: $dark-gray-x11;
      span {
        font-size: 12px;
      }
    }

    .ant-radio-checked .ant-radio-inner {
      background-color: blue;
      border-color: blue;
    }
  }
}
