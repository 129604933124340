@use '../../styleConstants.scss' as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .label {
    color: $sonic-silver;
    margin-right: 5px;
  }

  svg {
    font-size: 14px;
    color: $sonic-silver;
    margin-bottom: -2px;

    &:hover {
      color: $sonic-silver;
    }
  }
}
