@use '../../common/styleConstants.scss' as *;

.finishStockingModal {
  :global .ant-modal-content .ant-modal-body {
    max-height: calc(100vh - 240px);
    padding: 0px;
  }

  :global .ant-modal-content .ant-modal-footer {
    display: none;
  }

  .radio {
    display: block;
    height: 30px;
    line-height: 30px;
  }

  .closeInfoMessage {
    padding: 4px 0px;
    font-style: italic;
    font-size: 14px;

    span {
      color: $sonic-silver;
    }
  }

  .finishStockingTabs {
    :global .ant-tabs-nav {
      margin: 0px;
      margin: 0px 24px;
    }

    .transferTab,
    .discardedTab,
    .harvestTab {
      max-height: calc(100vh - 280px);
    }

    .formTransferStocking,
    .formDiscardedStocking,
    .formHarvestStocking {
      max-height: calc(100vh - 350px);
      padding: 0px 24px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .formDiscardedStocking,
    .formTransferStocking {
      padding-top: 20px;
    }

    .buttonsContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 10px 20px 20px 10px;

      .cancelButton {
        border: none;
        margin-right: 10px;
      }

      .submitButton span {
        color: $white;
      }

      .submitButton:disabled span {
        color: $dark-charcoal-3;
      }
    }
  }
}