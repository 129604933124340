@use '../../../common/styleConstants.scss' as *;

.dropdown {
  ul {
    border-radius: 8px;

    li {
      border-bottom: 1px solid #dcdcdc;
    }

    li:last-child {
      border-bottom: 1px solid transparent;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  height: 38px;
  cursor: pointer;
  border: 1px solid transparent;

  &.larvaeBorder:hover {
     border-color: blue;
  }

  &.juvenileBorder:hover {
    border-color: $cadmium-green;
  }

  &.growOutBorder:hover {
    border-color: $french-wine;
  }

  .phase {
    display: flex;
    margin: -26px 16px 0px 16px;
    height: 38px;
  }

  .avatar {
    display: flex;
    padding: 3px;
  }

  @media (max-width: 640px) {
    height: 34px;

    .phase {
      display: none;
    }

    .avatar {
      padding: 2px;
    }
  }
}

.containerDark {
  background-color: $light-silver-20;
}

.containerLight {
  background-color: #c8d9f762;
}

.row {
  display: grid;
  grid-template-columns: 2fr 12fr 4fr;
  grid-gap: 1px;
  padding: 4px 0;
}

.icon {
  color: $primary-color-dark;
}

.text {
  color: $title-color-light;
}
