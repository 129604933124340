@use '../../common/styleConstants.scss' as *;

.stockingParameterForm {
  .title {
    display: flex;
    justify-content: center;
    margin: 4px 0 20px 0;
  }

  .containerButtons {
    margin: 20px 0 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .radioGroup {
    .radioButton {
      border-radius: 0px !important;
    }

    .borderRight {
      border-right: none !important;
    }

    div {
      label {
        &:global {
          &:first-child {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          }

          &:last-child {
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
          }
        }
      }
    }
  }
}
