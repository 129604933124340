@use '../../../common/styleConstants.scss' as *;

.line {
  fill: none;
  stroke: white;
  stroke-dasharray: 3, 5;
  stroke-width: 0.5;
}

.triangle {
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #959595;
}

.chart {
  position: relative;
  margin-bottom: 10px;
}

.tooltipOption {
  position: absolute;
  z-index: 9;
  opacity: 0;
  background-color: whitesmoke;
  font-size: 13px;
  color: #000;
  border-radius: 8px;
  padding: 6px 8px;
  width: auto;

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 0 6px 12px;
    border-color: transparent whitesmoke;
    display: block;
    width: 0;
    z-index: 1;
    left: unset;
    right: -12px;
    top: calc(50% - 6px);
  }
}

.tooltip {
  z-index: 9 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  -webkit-transition: 250ms ease-in-out;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;

  &.rightAlignedTooltip {
    &:before {
      -webkit-transform: rotate(135deg) skew(15deg, 15deg);
      transform: rotate(135deg) skew(15deg, 15deg);

      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;
      left: unset;
      right: -8px;
    }

    &:after {
      left: unset;
      right: -16px !important;
      border-width: 8px 0 8px 16px;
    }
  }

  &.leftAlignedTooltip {
    &:before {
      -webkit-transform: rotate(45deg) skew(25deg, 25deg);
      transform: rotate(135deg) skew(25deg, 25deg);

      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

      border-color: transparent;
      border-width: 16px 0px 0px 16px;
      z-index: -1;
      top: calc(50% - 8px);
      left: -8px;
      right: unset;
    }
  }

  .extraPadding {
    bottom: 0px;
    position: absolute;
    background-color: transparent;
    height: 100%;
  }

  .content {
    background-color: white;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 160px;
    max-width: 200px;

    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

    hr {
      border: 0;
      height: 1px;
      background: #d5d5d5;
      margin: 0;
    }

    .entry {
      padding: 5px 10px 10px;
      color: black;

      .entryHeader {
        display: table;
        width: 100%;

        .entryTitle {
          font-size: 10px;
          font-weight: bold;
          margin-top: -2px;
          margin-bottom: 2px;
          color: royalblue;
          letter-spacing: 0;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          display: table-cell;
          vertical-align: middle;

          strong {
            font-size: 10px;
            font-weight: bold;
          }
        }
      }

      .entryContent {
        .stat {
          font-weight: 600;
          font-size: 10px;
          color: black;
          font-weight: normal;

          a {
            color: black;
            font-size: 10px;
            font-weight: normal;

            &:hover {
              text-decoration: underline;
            }

            strong {
              font-size: 10px;
              font-weight: 600;
            }
          }

          strong {
            font-size: 10px;
              font-weight: 600;
          }
        }
      }

      .entryActions {
        font-size: 10px;
        margin-bottom: 2px;
        padding-top: 5px;
        color: royalblue;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: space-between;

        &:focus {
          border: none;
        }

        .action {
          font-size: 10px;
          background: transparent;
          border: none;
          cursor: pointer;
          outline: none;
          padding: 0px;

          i {
            padding-top: 5px;
            font-size: 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 16px 6px 0;
    border-color: transparent white;
    display: block;
    width: 0;
    z-index: 1;
    left: -16px;
    top: calc(50% - 6px);
  }
}

.axisX {
  line,
  path {
    stroke: rgba(107, 107, 107, 0.6);
    stroke-dasharray: 4;
    stroke-width: 0.5;
  }
  text {
    font-size: 0.9em;
  }
}

.axisY {
  line,
  path {
    stroke: transparent;
  }
  text {
    font-size: 0.9em;
  }
}

.axisLight {
  text {
    fill: $davys-grey-5;
  }
}

.axisDark {
  text {
    fill: $philippine-gray;
  }
}

.hideCircle {
  display: none;
}
