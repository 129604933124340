@use '../../../src/common/styleConstants.scss' as *;

.analysis {
  width: 100%;
  margin: 0px 10px;
  margin-top: -28px;
  position: relative;

  .columnFlex {
    display: flex;
    flex-direction: column;
    flex: 1;

    .analysisCount {
      padding: 5px 0px;
    }

    .rowHeader {
      height: 70px;
      padding: 10px 0px;
    }

    .row {
      display: flex;
      align-items: center;

      .button {
        display: flex;
        padding-top: 3px;

        span {
          margin-top: 2px;
        }

        .icon {
          margin-top: -1px !important;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      padding-bottom: 16px;

      .select {
        width: 200px;
      }

      .typeHistogram {
        .radioButton {
          height: 28px;
          line-height: 26px;
          background-color: transparent;

          span {
            div {
              font-size: 14px;
            }
          }
        }

        .radioButtonDark {
          border: 1px solid $primary-color-dark;

          &:not(.radioSelected):hover {
            background-color: $btn-bg-hover;
          }

          span {
            div {
              color: $bright-gray;
            }
          }
        }

        .radioButtonLight {
          border: 1px solid $primary-color-light;

          &:not(.radioSelected):hover {
            background-color: $btn-bg-hover-light;
          }

          span {
            div {
              color: $title-color-light;
            }
          }
        }

        .radioButtonLight.radioSelected {
          background-color: $primary-color-light;

          span div {
            transition: 0.3s;
            color: $white;
          }
        }

        .radioButtonDark.radioSelected {
          background-color: $primary-color-dark;
        }

        .analysisOption {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
          border-right-width: 0px;
        }

        .parameterOption {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-left-width: 0px;
        }
      }
    }
  }
}

.confirmModal {
  width: 100%;

  .description {
    font-size: 14px;
  }
}

.campusInactive {
  background-color: $persimmon;
  border-radius: 2px;
  color: white;
  text-transform: none;
  width: fit-content;
  font-size: 12px !important;
  height: auto !important;
  padding: 2px 5px 2px 5px;
}

.table {
  .tags {
    text-align: right;
  }

  .consolidatedDark {
    border: 1px solid $dark-charcoal-3;
    text-shadow: none;
  }

  .consolidatedLight {
    background-color: $white;
    border: 1px solid $chinese-silver;
    text-shadow: none;
    color: $title-color-light;
  }

  .analysisRow {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease-in-out;

    .tagsCell {
      justify-content: flex-end;
      width: 100%;

      .eyeIcon {
        margin-left: 2px;
      }

      .icon {
        color: $white;

        &:hover {
          color: inherit;
        }

        i {
          color: $white;

          &:hover {
            color: inherit;
          }
        }
      }
    }

    &:hover {
      .icon {
        color: inherit;
        i {
          color: inherit;
        }
      }
    }
  }

  .selectedRow .showAnalysis {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  .selectedRowDark {
    background-color: $denim-blue !important;
  }

  .selectedRowLight {
    background-color: $denim-blue !important;
    td {
      color: $white !important;
    }
  }

  .showAnalysisDark {
    background-color: $denim !important;
  }

  .showAnalysisLight {
    background-color: $true-blue !important;

    td {
      color: $white !important;
    }
  }
}

.lightAnalysisCode {
  color: $title-color-light;

  &:hover {
    color: $primary-color-light;
  }
}

.darkAnalysisCode {
  color: $title-color-dark;

  &:hover {
    color: $primary-color-dark;
  }
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i {
      margin-right: 2px !important;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}

.moveLabel {
  cursor: pointer;
  text-decoration: underline;
}

.exclamationIcon {
  svg {
    font-size: 24px;
  }
}

.warningIcon {
  svg {
    color: $dark-orange;
  }
}
