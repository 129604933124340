@use '../../../common/styleConstants.scss' as *;

:global .ant-checkbox-wrapper-disabled:local(.lightCheckbox) {
  span {
    color: $title-color-light;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    transition: 0s;
    background-color: $body-background-light;
    border-color: $silver-chalice !important;
  }
}

:global .ant-checkbox-wrapper-disabled:local(.darkCheckbox) {
  span {
    color: $title-color-dark;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    transition: 0s;
    background-color: $body-background-dark;
    border-color: $violet-blue-light !important;
  }
}

.lightCheckbox {
  color: $title-color-light;

  :global {
    .ant-checkbox-inner {
      transition: 0s;
      background-color: $body-background-light;
      border: 1px solid $silver-chalice;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $primary-color-dark;
      border-color: $primary-color-dark;
    }
  }
}

.darkCheckbox {
  color: $title-color-dark;

  :global {
    .ant-checkbox-inner {
      transition: 0s;
      background-color: $body-background-dark;
      border: 1px solid $violet-blue-light;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
  }
}
