@use '../../../src/common/styleConstants.scss' as *;

.inputUpperCase {
  text-transform: uppercase;
}

.campusTitle {
  font-size: large;
  font-weight: bold;
}

.campus {
  width: 100%;
  position: relative;

  .rowFlex {
    .table {
      height: calc(100vh - 235px);
    }

    .selectedRowDark {
      background-color: $row-selected-dark !important;
    }

    .selectedRowLight {
      background-color: $row-selected-light !important;
    }
  }
}

.switch {
  margin-left: 10px;
}

.referenceCurves {
  .table {
    thead tr th {
      font-size: 13px !important;
    }

    tbody tr td div div input {
      font-size: 13px;
    }
  }

  .formItem {
    .button {
      padding: 4px 15px 4px 11px;
      float: right;
    }
  }

  .input {
    div {
      input {
        background-color: $body-background-dark;
        height: inherit !important;
      }
    }
  }

  .buttonDelete {
    font-size: 13px;

    .icon {
      color: $top-bar-icon;
    }
  }
}

.statusCell {
  z-index: 1;
}

.optionsCell {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i {
      margin-right: 2px !important;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}

.menuSearch {
  background-color: $body-background-light;
  padding: 8px;

  .inputSearch {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }

  .buttonSearch {
    width: 90px;
    border-radius: 5px;
  }

  .buttonReset {
    border: 1px solid $title-color-light;
    background-color: transparent;
    border-radius: 5px;
    color: $title-color-light;
    width: 90px;

    &:hover {
      background-color: $chinese-white;
    }
  }
}

.searchOutlinedIconDark svg {
  fill: $title-color-dark;
}

.searchOutlinedIconDark:hover svg {
  fill: $chinese-silver;
}

.searchOutlinedIconLight svg {
  fill: $silver-chalice;
}

.searchOutlinedIconLight:hover svg {
  fill: $title-color-light;
}
