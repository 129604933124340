@use '../../../common/styleConstants.scss' as *;

.survivalRate {
  margin-top: -15px;
  padding: 0 15px;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;

    .filters {
      .select {
        width: 150px;
      }
    }

    .rowRight {
      display: flex;
      align-items: flex-end;
    }

    .sidePanel {
      display: none;
    }
  }

  @media (max-width: 1190px) {
    .header {
      .filters {
        .select {
          width: 120px;
        }
      }
    }
  }

  @media (max-width: 1040px) {
    .header {
      align-items: center;

      .filters {
        display: none;
      }

      .sidePanel {
        display: flex;

        .title {
          color: $title-color-light;
          font-size: 16px;
          font-weight: bold;
        }

        .bodyPanel {
          width: 100%;
        }
      }
    }
  }
}
