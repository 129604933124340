@use '../../../../src/common/styleConstants.scss' as *;

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.containerMain {
  background-color: white;
  margin: auto;
  height: 1187px;
  width: 1680px;

  .rowData {
    display: flex;
    justify-content: space-between;
    margin: 0 24px 0 24px;

    .labelAxisX {
      text-align: center;
      font-size: 1em;
      color: $quick-silver;
    }
  }

  .chart {
    background-color: white;
    margin: 0 auto;
    width: 100%;
    position: relative;
  }

  .headerPadding {
    padding: 0;
  }

  .titleContent {
    color: $davys-grey;
    padding: 0;
    font-size: 1.2em !important;
  }
}
