@use '../../../../src/common/styleConstants.scss' as *;

.detailAnalysis {
  .ant-row {
    .ant-col {
      padding-left: 9px;
      padding-right: 9px;
    }
  }

  .ant-switch {
    background-color: grey;
  }

  .ant-switch-checked {
    background-color: $primary-color-dark;
  }

  .ant-picker-suffix {
    span {
      svg {
        fill: white;
      }
    }
  }
}

.commercialSizesTabs {
  .ant-tabs-nav {
    margin: 0px;
    
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding-top: 0px;
          padding-bottom: 6px;
        }
      }
    }
  }
}
