@use '../../../../../../src/common/styleConstants.scss' as *;

.title {
  font-size: 0.9em;
}

@media (max-width: 400px) {
  .title {
    font-size: 0.8em;
  }
}
