.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  i {
    font-size: 40px;
    color: red;
  }
}
