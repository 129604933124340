@use '../../styleConstants.scss' as *;

.tinyLabel {
  border-radius: 5px;
  padding: 2px 12px;
  text-align: left;
  cursor: default;
  width: auto;
  display: inline-block;
}
