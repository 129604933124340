@use './common/styleConstants.scss' as *;

#root {
  height: 100%;

  .ant-layout {
    min-height: calc(100vh - 200px);
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.superAdminAction {
  color: darkorange !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-webkit-autofill::first-line {
  font-size: 14px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: $font-family;
    font-size: 15px;
    letter-spacing: 0.3px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-form {
  .ant-input-affix-wrapper {
    padding-top: 8px;
  }
}

.ant-btn i {
  font-size: 1.2em;
}

.ant-btn.ant-btn-text {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.ant-btn-text[disabled] {
  color: white !important;
}

.ant-btn-link {
  color: $title-color-dark !important;
}

.ant-notification-notice {
  background-color: $body-background-light !important;
}

.ant-notification-notice-content {
  .ant-notification-notice-message {
    margin-top: 2px !important;
    margin-bottom: 0px !important;
    color: $title-color-light !important;
  }
}

.ant-notification-close-x i {
  color: $title-color-light !important;
  font-size: 1.2em !important;
}

.ant-notification-notice-close {
  margin-top: -2px !important;

  i {
    font-size: 1.4em !important;
  }
}

.ant-notification-notice-icon {
  margin-top: -2px !important;

  i {
    font-size: 1em !important;
    color: $primary-color-dark;
  }
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: $table-title;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: $table-thead;
}

.ant-layout {
  background-color: $sider-background-dark !important;
}

.ant-select-dropdown,
.ant-select-item-option-selected {
  background-color: $body-background-dark;
}

textarea {
  resize: none !important;
}

.ant-radio-button-wrapper {
  border-color: $dark-gunmetal;

  &:not(:first-child) {
    &::before {
      background-color: transparent !important;
    }
  }
}

.ant-radio-button-wrapper-checked {
  color: $title-color-dark;
  border-color: $primary-color-dark;
  background-color: $primary-color-dark;

  &:before {
    content: initial;
  }

  &:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: none;
  }
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none !important;
}

// Dropdown

.ant-dropdown .ant-dropdown-menu {
  background-color: $body-background-light;

  .ant-dropdown-menu-item {
    color: $top-bar-icon;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdow-menu-item.ant-dropdown-menu-item-active {
    background-color: $dropdown-hover-color-light;
  }
}

.ant-dropdown-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > i {
    margin-right: 10px !important;
  }

  > span {
    width: 100%;
    text-align: left;
  }
}

.ant-dropdown-arrow::before {
  background-color: $body-background-light !important;
}

.ant-input-suffix {
  .ant-input-search-icon {
    color: $dim-gray;
  }
}

.ant-popover-inner .ant-popover-title {
  color: white;
}

.ant-tooltip {
  z-index: 9999 !important;

  .ant-tooltip-content {
    .ant-tooltip-inner {
      font-size: 12px;
      text-align: center;
    }
  }
}

.ant-input-lg {
  padding: 4px 11px !important;
}

.ant-input-group-addon {
  background-color: transparent !important;
}

.ant-input-search-button {
  height: 35px !important;
}

.ant-tag {
  color: white;
  font-weight: bold;
  text-shadow: 0 0 4px black;
}

.ant-modal-confirm-title {
  color: $title-color-dark;
}

.ant-modal-confirm-btns {
  display: flex !important;
}

.ant-modal-confirm-body {
  .anticon {
    color: $title-color-dark !important;
  }
}

.react-joyride__overlay:empty {
  pointer-events: auto !important;
}

.ant-popover-message {
  display: flex;
}

.spyIcon {
  font-size: 1.2em;
}

.ant-btn-compact-item.ant-btn-primary:not([disabled])
  + .ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
  background-color: transparent !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}
