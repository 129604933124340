@use '../../../common/styleConstants.scss' as *;

.containerMultiphaseLegend {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rect {
    width: 210px;
  }

  .legends {
    position: relative;
    margin-top: 6px;
    margin-right: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .lightText {
      color: $davys-grey-5;
     }

     .darkText {
       color: $bright-gray;
     }
     
    .labelAxisY {
      width: 100%;
      font-size: 1em;

      @media (max-width: 1420px) {
        font-size: 12px;
      }
    }

    .containerPhaseType {
      width: 100%;

      .phaseTypes {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;

        .phaseType {
          margin-left: 16px;
          cursor: pointer;

          &:hover {
            text-decoration: line-through;
          }

          @media (max-width: 1420px) {
            font-size: 12px;
          }
        }

        .phaseTypeDark {
          &:hover {
            text-decoration-color: $title-color-dark;
          }
        }

        .phaseTypeLight {
          &:hover {
            text-decoration-color: $sonic-silver;
          }
        }

        .lineThrough {
          text-decoration: line-through;
        }

        .lineThroughDark {
          text-decoration: line-through;
          text-decoration-color: $title-color-dark;

          &:hover {
            text-decoration-color: $title-color-dark;
          }
        }

        .lineThroughLight {
          text-decoration: line-through;
          text-decoration-color: $sonic-silver;

          &:hover {
            text-decoration-color: $sonic-silver;
          }
        }

        .notAllowed {
          cursor: not-allowed;
        }
      }
    }
  }
}

.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 8px;

    .circle {
      margin-left: 20px;
      margin-right: 4px;
      margin-bottom: 2px;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 100px;
    }

    .normalDark {
      background-color: $white;
    }

    .normalLight {
      background-color: $maximun-blue-purple;
    }

    .consolidated {
      background-color: $state-blue;
    }

    @media (max-width: 1420px) {
      span {
        font-size: 12px;
      }
    }
  }
}
