@use '../../styleConstants.scss' as *;

.tinyCounter {
  margin-right: 5px;
  border-radius: 15px;
  padding: 5px 7px 5px 7px;
  height: 20px;
  font-size: 10px;
  display: flex;
  min-width: 30px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: default;
}
