@use '../../../common/styleConstants.scss' as *;

.lightText {
  color: $dark-charcoal-3;
  font-size: inherit;
}

.darkText {
  color: $title-color-dark;
  font-size: inherit;
}
