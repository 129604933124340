@use '../../styleConstants.scss' as *;

.modal {
  & :global .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .description {
    align-self: flex-start;
    padding-bottom: 10px;
    color: $title-color-light;
  }

  .select {
    width: 120px;
  }
}