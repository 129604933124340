@use '../../common/styleConstants.scss' as *;

.successNotification {
  i {
    color: $primary-color-dark;
  }
  :hover {
    color: $primary-color-dark;
  }
}

.error {
  i {
    color: $orange-red;
  }
  :hover {
    color: $orange-red;
  }
}

.warningNotification {
  i {
    color: $dark-orange;
  }
  :hover {
    color: $dark-orange;
  }
}
