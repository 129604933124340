@use '../../../common/styleConstants.scss' as *;

.container {
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;

  .table {
    margin-top: 16px;
  }

  .avatar {
    background-color: $medium-purple !important;
  }

  .tableCell {
    padding: 16px 30px 16px 10px !important;
  }

  .resetBalanceIcon,
  .addCompanyIcon,
  .addUserIcon {
    color: $blue-violet;
  }

  .accreditationIcon {
    color: $forest-green;
  }

  .debitIcon,
  .userInactivationIcon {
    color: $deep-puce;
  }

  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .name {
      padding-left: 10px;
      text-align: left;

      span {
        font-weight: bold;
      }
    }

    .underline {
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
    }
  }
}
