@use '../../../common/styleConstants.scss' as *;

.containerFull {
  &.databaseContainer {
    .row {
      padding: 6px;
    }
  }

  &.biometricsContainer {
    .row {
      padding: 0px 6px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;

    .description {
      margin: 0 10px 12px 10px;

      .text {
        margin-bottom: 6px;
        font-size: 14px;
      }
    }

    .filters {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 0 10px 12px 10px;

      .datePicker {
        width: 150px;
        margin-right: 12px;
      }

      .select {
        width: 120px;
        margin-right: 12px;
      }
    }

    .filterTypeFile {
      display: flex;
      flex-direction: column;

      .dataToDownload {
        font-size: 14px;
      }

      .reportType {
        .radioButtonLeft {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .radioButtonRight {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .rowButtons {
    display: flex;
    justify-content: flex-end;
    padding: 24px 6px 24px 6px;
    flex-flow: nowrap;
    text-align: right;

    .dropdown {
      margin-left: 12px;
      margin-right: 10px;
      width: auto;
      color: $white;

      .downloadIcon {
        margin-right: 4px !important;
        font-size: 1em !important;
      }

      :global .ant-btn.ant-btn-primary {
        background-color: $primary-color-light;

        &:disabled {
          background-color: $primary-color-light;
          color: $white;
        }

        &:hover {
          background-color: $primary-color-hover-light;
        }

        &.ant-btn-compact-first-item {
          border-radius: 4px 0px 0px 4px;
        }
        &.ant-btn-compact-last-item {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }

    .downloadButton {
      button {
        border: 1px solid $primary-color-dark;

        .description {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .typeFile {
          padding-left: 8px;
        }
      }

      button:disabled {
        border: 1px solid $primary-color-dark;
        background-color: $primary-color-hover-light;
        color: $white;
      }
    }
  }
}

.radioButton {
  display: flex;
  align-items: center;
}

.typeFile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px 0 4px;

  .format {
    margin-left: 2px;
    font-size: 0.6em;
  }

  .icon {
    font-size: 0.9em !important;
  }
}
