.container {
  display: flex;

  .select {
    width: 150px;
  }
}

@media (max-width: 680px) {
  .container {
    .select {
      width: 120px;
    }
  }
}
