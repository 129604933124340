@use '../../../common/styleConstants.scss' as *;

.lightContainer {
  .lightTitle {
    color: $title-color-light;
    font-size: 14px;
  }

  .lightList {
    color: $sonic-silver;
    border-color: $dropdown-border-color-light;

    :global {
      .ant-list-item {
        border-color: $dropdown-border-color-light;
      }
    }
  }
}

.darkContainer {
  .darkTitle {
    color: $dark-gray-x11;
    font-size: 14px;
  }

  .darkList {
    border-color: $dropdown-border-color;

    :global {
      .ant-list-item {
        border-color: $dropdown-border-color;
      }
    }
  }
}
