.ant-layout-header {
  // line-height: normal !important;
  padding: 0 !important;
  height: auto;
}

.ant-layout-header .ant-menu {
  display: flex;
  width: 75px;
}
