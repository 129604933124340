.space {
  margin: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 680px) {
    flex-direction: column;
  }

  .containerImage {
    overflow: hidden;
  }
}
