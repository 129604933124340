@use '../../../common/styleConstants.scss' as *;

.stockingParameterSelected {
  .title {
    display: flex;
    justify-content: center;
    margin: 4px 0 20px 0;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    column-gap: 10px;
    row-gap: 20px !important;

    .gridItem {
      border-radius: 6px;
      border: 1px solid $gray;
      padding: 4px;
      text-align: center;
      cursor: pointer;
    }

    .itemSelected {
      background-color: $green-screamin;
      border-color: $active-status-color;
    }
  }

  .currentDate {
    margin: 20px 0 0 6px;

    span {
      font-size: 14px;
    }
  }

  .containerStartButton {
    margin: 20px 0 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .table {
    .buttonDelete {
      font-size: 12px;

      .icon {
        color: $top-bar-icon;
      }
    }

    .repeatedParameter {
      border: 1px solid red;
    }
  }
}
