@use '../../../../src/common/styleConstants.scss' as *;

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.containerMain {
  background-color: white;
  margin: auto;
  height: 1187px;
  width: 1680px;

  .rowData {
    display: flex;
    justify-content: space-between;
    margin: 0 24px 0 24px;
  }

  .containerTable {
    width: 19%;

    .table {
      width: 100%;
      margin-top: 20px;

      td,
      th {
        padding: 6px 8px;
      }

      .header {
        background-color: $dark-cornflower-blue;
        border: 1px solid $dark-cornflower-blue;
      }

      .body {
        border: 1px solid #6b6b6b99;

        td {
          color: #6b6b6b99;
          border: 1px solid #6b6b6b99;
        }
      }
    }
  }

  .containerChart {
    width: 100%;

    .labelAxisX {
      text-align: center;
      font-size: 1em;
      color: $quick-silver;
    }
  }

  .chart {
    background-color: white;
    margin: 0 auto;
    width: 100%;
  }

  .headerPadding {
    padding: 0;
  }

  .titleContent {
    color: $davys-grey;
    padding: 0;
    font-size: 1.2em !important;
  }

  .legend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 8px;

      span {
        color: $davys-grey;
      }

      .colorBox {
        margin-left: 4px;
        margin-right: 4px;
        display: inline-block;
        width: 28px;
        height: 4px;
      }

      .pigmentation {
        border-style: dashed;
        border-color: $gray;
        border-width: 1px;
        height: 1px;
      }

      .movingAverage {
        border-style: solid;
        border-color: $primary-color-dark;
        border-width: 1px;
        height: 1px;
      }
    }
  }
}
