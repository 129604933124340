@use '../../../common/styleConstants.scss' as *;

.content {
  position: relative;
  width: 100%;
  margin: -15px 10px 0 10px;

  .select {
    width: 200px;
  }

  .activeTanks {
    height: calc(100vh - 160px);
    overflow-y: scroll;
    width: 100%;

    .chart {
      position: relative;
    }

    .labelAxisX {
      font-size: 1em;
      display: flex;
      justify-content: center;
    }
  }

  .table {
    margin-top: 12px;
  }

  .tableDark {
    .cell {
      &:hover {
        background-color: $row-selected-dark;
      }
    }
  }

  .tableLight {
    .cell {
      &:hover {
        background-color: $row-selected-light;
      }
    }
  }

  .height {
    height: calc(100vh - 182px);
    overflow-y: auto;

    .empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.spinnerContainer {
  width: 100%;
  height: 90%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}
