@use '../../../common/styleConstants.scss' as *;

.gridItem {
  width: 100%;
  display: grid !important;
  grid-template-rows: auto auto;
  padding: 6px 6px 6px 12px;

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .row {
      display: flex;

      .value {
        font-size: 14px;
      }
    }
  }

  .uniformityValue {
    font-size: 10px;
    text-align: center;

    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    max-width: 43px;
  }

  .value {
    font-size: 14px;
    text-align: center;
  }

  .field {
    font-size: 12.5px;
    text-align: center;

    // Ellipsis styles
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Max lines allowed before ellipsing */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fieldLight {
    color: $granite-gray-2;
  }

  .fieldDark {
    color: $gray;
  }
}
