@use '../../styleConstants.scss' as *;

.tag {
  font-size: 12px;
  font-weight: 500;
  border-radius: 14px;
  padding: 3px 10px;
  margin-right: 0;
}

.tagDark {
  transition: 0s;
  background-color: $black;
  color: $bright-gray;
}

.tagLight {
  transition: 0s;
  background-color: $sider-background-light;
  color: $title-color-light;
  text-shadow: 0 0 4px $white;
}

.tag.active {
  border: 1px solid $majorelle-blue;
}

.tag.error {
  border: 1px solid $ue-red;
}

.tag.info {
  border: 1px solid $safety-orange;
}

.tag.transfer {
  border: 1px solid $cadmium-green;
}

.tag.default {
  border: none;
}
