@use '../../../common/styleConstants.scss' as *;

.modal {
  min-height: 418px;

  .searchContainer {
    margin: 10px 0 18px 0;

    :global .ant-input-affix-wrapper {
      line-height: 1.96;
    }

    .search {
      background-color: transparent;
    }

    .suffixIcon {
      cursor: pointer;
    }
  }

  .table {
    min-height: 264px;

    .stockingActive {
      background-color: $body-background-dark;
      color: white;
      font-size: 11px;
      border: 1px solid #5a5adf;
    }

    .stockingFinished {
      background-color: $body-background-dark;
      color: white;
      font-size: 11px;
    }

    .icon {
      color: $primary-color-dark;
    }

    .rowSelected > td {
      border-width: 2px;
      border-top: solid 2px $primary-color-dark;
      border-bottom: solid 2px $primary-color-dark;
    }

    .rowSelected > td:first-child {
      border-left: solid 2px $primary-color-dark;
    }

    .rowSelected > td:last-child {
      border-right: solid 2px $primary-color-dark;
    }
  }
}
