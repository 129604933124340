@use '../../../common/styleConstants.scss' as *;

.lightCollapse {
  background-color: $body-background-light;
  border-color: $silver-chalice;

  :global {
    .ant-input {
      color: $sonic-silver;
      background-color: $body-background-light;
      border: 1px solid $silver-chalice;
    }

    .ant-input-suffix {
      color: $sonic-silver;
    }

    .ant-collapse-item {
      border-color: $silver-chalice;

      .ant-collapse-header {
        color: $sonic-silver;
      }
    }

    .ant-collapse-content {
      border-color: $silver-chalice;
    }

    .ant-collapse-content-box {
      background-color: $body-background-light;
    }
  }
}

.darkCollapse {
  background-color: $body-background-dark;
  border-color: $silver-chalice;

  :global {
    .ant-input {
      color: $title-color-dark;
      background-color: $body-background-dark;
      border: 1px solid $silver-chalice;
    }

    .ant-input-suffix {
      color: $sonic-silver;
    }

    .ant-collapse-item {
      border-color: $silver-chalice;

      .ant-collapse-header {
        transition: 0s;
        color: $title-color-dark;
      }
    }

    .ant-collapse-content {
      border-color: $silver-chalice;
    }

    .ant-collapse-content-box {
      background-color: $content-background;
    }
  }
}
