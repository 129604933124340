@use '../../../common/styleConstants.scss' as *;

.lightEmpty {
  color: $sonic-silver;

  :global {
    .ant-empty-image {
      .ant-empty-img-default-path-1 {
        fill: #dce0e6;
      }

      .ant-empty-img-default-ellipse {
        fill: #dce0e6;
      }
      .ant-empty-img-default-path-3 {
        fill: #ececec;
      }
      .ant-empty-img-default-path-4 {
        fill: $silver-chalice;
      }
      .ant-empty-img-default-path-5 {
        fill: $silver-chalice;
      }
    }
  }
}

.darkEmpty {
  color: $title-color-dark;
}
