@use '../../common/styleConstants.scss' as *;

.containerMain {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;

  .header {
    display: block;
    padding-top: 8px;
    font-size: 26px;
    text-align: center;
  }

  .containerSubtitle {
    margin: auto;
    display: flex;
    justify-content: space-between;

    .subtitle {
      font-size: 14px;
    }

    .open {
      &:hover {
        color: $primary-color-dark;
      }
    }
  }

  @media (max-width: 680px) {
    .containerSubtitle {
      justify-content: space-evenly;
    }
  }

  @media (max-width: 480px) {
    .containerSubtitle {
      width: auto !important;
      margin: 0px;

      .subtitle {
        margin-right: 40px;
      }
    }
  }

  .containerColumn {
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .containerSample {
    display: flex;
    flex-direction: row;

    div {
      display: flex;
      align-items: center;
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .select {
    margin-right: 8px;
    margin-top: 10px;
    width: 150px;
    position: sticky;
    position: -webkit-sticky;
  }
}
