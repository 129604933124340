@use '../../../../src/common/styleConstants.scss' as *;

.customizableModal {
  .container {
    position: relative;
    width: 100%;

    .settings {
      font-size: 0.9em;
    }

    .select {
      width: 100%;
    }

    .button {
      float: right;
    }
  }
}
