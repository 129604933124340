@use '../../../common/styleConstants.scss' as *;

.container {
  overflow-x: auto;

  .table {
    width: 100%;

    .header {
      th {
        padding: 2px 0 2px 12px;
        text-align: left;
        color: $sonic-silver;
        font-weight: bold;
        font-size: 13px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
        }
      }
    }

    .bodyLight {
      td div,
      td span {
        color: $dark-charcoal-3;
      }
    }

    .bodyDark {
      td div,
      td span {
        color: $chinese-silver;
      }
    }

    .body {
      td:nth-child(1) span div {
        color: $white;
      }

      td {
        padding: 2px 0 2px 12px;
        height: 32px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
        }
      }

      .stocking {
        display: flex;
        align-items: center;

        .circle {
          margin-right: 5px;
          width: 13px;
          height: 13px;
          border-radius: 100px;
        }

        .triangle {
          margin-right: 5px;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 13px solid transparent;
        }

        .stockingNameContainer {
          width: 120px;
        }
      }
    }

    .larvae {
      background-color: $cornflower-blue-12;
    }

    .juvenile {
      background-color: $eucalyptus-11;
    }

    .growOut {
      background-color: $strawberry-15;
    }

    .select {
      min-width: 100px;
      max-width: 120px;

      div {
        height: 28px !important;

        span {
          font-size: 13px;
          margin-top: -1px;
        }
      }
    }
  }
}
