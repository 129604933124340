@use '../../../../src/common/styleConstants.scss' as *;

.wrapModal :global .ant-modal{
  width: 600px !important;
  top: 20px
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  table:first-child {
    margin-bottom: 10px;
  }
}

.table {
  width: 500px;

  .body {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 4px;
    border: 1px solid $davys-grey;

    .tableTitle, .tableSubtitle, .tableInfo {
      text-align: center;
      border-bottom: 1px solid black;
    }

    td span:empty::before {
      content: "-";
    }

    td .tagsContainer {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;

      .tags {
        border: 1px solid $chinese-silver;
        background-color: $bright-gray;
        border-radius: 2px;
        padding: 0px 10px;
        margin: 2px 4px 2px 0px;
      }
    }

    .tableSubtitle td {
      background-color: #192851;
      span {
        color: white;
      }
    }

    .tableInfo td {
      background-color: #d5e6f5;
    }

    tr:not(:last-child) td {
      border-bottom: 1px solid $light-gray;
    }

    th,
    td {
      padding: 2px 12px 2px 12px;

      a .linkText {
        color: $primary-color-light;

        &:hover {
          text-decoration: underline;
        }
      }

      .bold {
        font-weight: bold;
      }
    }

    td:first-child {
      width: 220px;
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid $davys-grey;
    }
  }
}

.table .body tr:last-child {
  vertical-align: top;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
