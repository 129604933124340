@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  position: relative;
  width: calc(100% - 200px);

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: center;

    i {
      font-size: 40px;
    }
  }
}

.stockingAnalysis {
  margin-top: -15px;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .body {
    display: flex;
    flex-direction: row;
  }

  .select {
    width: 150px;
  }

  .checkbox {
    margin-left: 6px;
    margin-bottom: 4px;
  }

  .rowBody {
    display: flex;
    justify-content: center;
    width: calc(100% - 200px);
  }

  .center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .maturation {
    padding-bottom: 5px;
    margin-right: 8px;

    .name {
      padding-bottom: 0px;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .nameDark {
      color: $title-color-dark;
    }

    .nameLight {
      color: $title-color-light;
    }
  }

  .stocking {
    padding-bottom: 5px;
    margin-right: 8px;
    display: flex;
    flex-direction: row;

    div {
      cursor: pointer;
    }

    .name {
      padding-bottom: 0px;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .nameDark {
      color: $title-color-dark;
      border-bottom: 1px dashed $title-color-dark;
    }

    .nameLight {
      color: $title-color-light;
      border-bottom: 1px dashed $title-color-light;
    }
  }

  .containerTanks {
    display: flex;
    justify-content: flex-end;
  }

  .row {
    display: flex;

    .sidePanel {
      display: none;
    }
  }

  @media (max-width: 1400px) {
    .select {
      width: 120px;
    }

    .maturation,
    .stocking {
      .name {
        max-width: 180px;
      }
    }
  }

  @media (max-width: 1220px) {
    .row {
      align-items: center;

      .filters {
        display: none;
      }

      .sidePanel {
        display: flex;

        .title {
          color: $title-color-light;
          font-size: 16px;
          font-weight: bold;
        }

        .bodyPanel {
          width: 100%;
        }
      }
    }
  }

  .originsMultiphase {
    position: relative;
    margin: 12px 0px 0px 0px;

    .hide {
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease-in-out;
    }

    .show {
      visibility: visible;
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }
  }
}