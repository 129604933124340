@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  position: relative;
  width: 100%;
  height: 95%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.stockingParameters {
  margin-top: -15px;
  padding: 0 15px;
  width: 100%;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95%;
  }

  .body {
    margin-top: 16px;

    .containerButton {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 8px;
    }

    .textButton {
      border-color: transparent;
    }

    .box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      padding: 4px 8px;

      .containerText {
        width: 100%;
        height: 100%;
        padding: 12px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .label {
          text-align: left;
          font-size: 12.5px;

          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 1.1;
        }

        .red {
          color: $mandy;
        }

        .green {
          color: limegreen;
        }

        .value {
          text-align: left;
          font-size: 22px;
          line-height: 1.25;
        }
      }

      &:hover {
        .settingIcon,
        .closeIcon {
          cursor: pointer;
          visibility: visible;
          opacity: 1;
          transform: translateX(0);
          transition: 0.3s;
        }
      }
    }

    .borderLight {
      border: 1px solid $silver-chalice;
    }

    .borderDark {
      border: 1px solid $title-color-dark;
    }

    .fillLight {
      fill: $silver-chalice;
    }

    .fillDark {
      fill: $title-color-dark;
    }

    .containerSettingIcon {
      position: absolute;
      right: 6px;
      top: 1px;
      z-index: 9;

      .settingIcon {
        visibility: hidden;
        opacity: 0;
        transform: translateX(4px);
        transition: 0.3s;
      }
    }

    .containerCloseIcon {
      position: absolute;
      right: 24px;
      top: -1px;
      z-index: 9;

      .closeIcon {
        visibility: hidden;
        opacity: 0;
        transform: translateX(4px);
        transition: 0.3s;
        font-size: 18px;
      }
    }

    .containerResizeIcon {
      position: absolute;
      right: 6px;
      bottom: 0px;
    }

    .chart {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      padding: 16px 24px 16px 20px;

      .options {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .containerParameters {
          border-radius: 8px;
          padding: 4px 12px;
          display: flex;
          align-items: center;

          .parameters {
            display: flex;

            .parameter,
            .dateOption {
              cursor: pointer;

              span {
                font-size: 13px;

                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }

            .selectedParameterLight,
            .selectedDateOptionLight {
              background-color: $row-selected-light;
            }

            .selectedParameterDark,
            .selectedDateOptionDark {
              background-color: $row-selected-dark;
            }

            div {
              padding: 2px 5px;
              border-radius: 8px;
            }
          }
        }
      }

      .text {
        text-align: center;
      }

      .containerLegends {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .rowLegends {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 20px 0px 6px 56px;

          .containerAxisYLabel {
            font-size: 11px;

            .axisYLabel {
              width: 140px;
              line-height: 1;
            }

            .axisY2Label {
              text-align: right;
            }

            .axisY3Label {
              right: -20px;
              width: 102px;
              position: relative;
            }

            div > span {
              font-size: 11px;
            }
          }

          .legends {
            display: flex;

            .legend {
              display: flex;
              font-size: 11px;
              align-items: center;
              justify-content: center;

              .square {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }

              .plusSign {
                font-size: 20px;
                font-weight: bold;
                margin-right: 4px;
              }

              .circle {
                width: 12px;
                height: 12px;
                border-radius: 100px;
                margin-right: 4px;
              }

              .totalFeedDark {
                background-color: royalblue;
              }

              .totalFeedLight {
                background-color: $maximun-blue-purple;
              }

              .growthRateDark {
                color: $white;
              }

              .growthRateLight {
                color: royalblue;
              }

              .parameterWithoutFrequencies {
                background-color: indianred;
              }
            }
          }
        }
      }

      .containerChart {
        height: 100%;

        .parameterChart {
          position: relative;
        }

        .showChart {
          display: block;
        }

        .hideChart {
          display: none;
        }

        .containerIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          .icon {
            font-size: 10em;
          }

          .showIcon {
            display: block;
          }

          .hideIcon {
            display: none;
          }
        }

        .hideContainerIcon {
          display: none;
        }
      }

      .containerParameterSettingIcon {
        position: absolute;
        right: 6px;
        top: 4px;

        visibility: hidden;
        opacity: 0;
        transform: translateX(4px);
        transition: 0.3s;
      }

      &:hover {
        .containerParameterSettingIcon {
          cursor: pointer;
          z-index: 9;

          visibility: visible;
          opacity: 1;
          transform: translateX(0px);
          transition: 0.3s;
        }
      }
    }
  }

  .grid {
    position: relative;
    height: calc(100vh - 150px);
    overflow-y: scroll;
  }

  :global {
    .react-grid-item > .react-resizable-handle {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0px;
      right: 0px;
      cursor: se-resize;
    }
  }
}
