@use '../../../styleConstants.scss' as *;

.axisX {
  line,
  path {
    stroke: rgba(107, 107, 107, 0.6);
    stroke-dasharray: 4;
    stroke-width: 0.5;
  }
  text {
    font-size: 0.9em;
  }
}

.axisY {
  line,
  path {
    stroke: transparent;
  }
  text {
    font-size: 0.9em;
  }
}

.axisLight {
  text {
    fill: $davys-grey-5;
  }
}

.axisDark {
  text {
    fill: $philippine-gray;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #959595;
}

.lines {
  stroke-dasharray: 8;
  stroke-width: 1.5;
  stroke: green;
}

.lowLine {
  stroke: red;
}

.highLine {
  stroke: green;
}

.circle {
  stroke-width: 1;
  cursor: pointer;
}

.circleLight {
  stroke: #131b55;
}

.circleDark {
  stroke: #ffffff;
}

.tooltipOption {
  position: absolute;
  z-index: 9;
  opacity: 0;
  background-color: whitesmoke;
  font-size: 13px;
  color: #000;
  border-radius: 8px;
  padding: 6px 8px;
  width: auto;

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 0 6px 12px;
    border-color: transparent whitesmoke;
    display: block;
    width: 0;
    z-index: 1;
    left: unset;
    right: -12px;
    top: calc(50% - 6px);
  }
}

.tooltip {
  position: absolute;
  border: 0;
  border-radius: 5px;
  padding: 4px 8px;
  background-color: white;
  color: black;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  flex-direction: column;

  h3 {
    margin-bottom: 0;
    color: royalblue;
    font-size: 11px;
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      margin-bottom: 0px;
      font-size: 11px;

      strong {
        font-size: 11px;
      }
    }
  }
}
