@use '../../common/styleConstants.scss' as *;

.typeSearch {
  position: absolute;
  background-color: $background-light;
  top: 38px;
  z-index: 9;
  padding: 8px 4px 8px 12px;
  width: 100%;
  border-radius: 5px;

  .radioGroup {
    display: flex;
    flex-direction: column;
  }
}

.typeSearchDark {
  background-color: $background-light;
}

.typeSearchLight {
  border: 1px solid $violet-blue;
}

.showTypeSearch {
  min-width: 100%;
  visibility: visible;
  transform: translateY(0px);
  opacity: 1;
  transition: 0.3s;
}

.hideTypeSearch {
  min-width: 100%;
  transform: scale(1, 1);
  transform: translateY(25px);
  opacity: 0;
  width: 0;
  visibility: hidden;
}
