@use '../../../common/styleConstants.scss' as *;

:global {

  .ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background-color: transparent;
  }
}

.container {
  .lightInput {
    transition: 0s;
    border: 1px solid $silver-chalice;
    border-radius: 4px;
    background-color: $body-background-light;
    color: $top-bar-icon;

    &:disabled {
      border: 1px solid $disabled-color;
    }

    :global {
      .ant-input {
        color: $top-bar-icon;
        background-color: $body-background-light;
      }

      .ant-input-suffix {
        color: $top-bar-icon;

        div i {
          color: $top-bar-icon;
        }
      }
    }
  }

  .darkInput {
    transition: 0s;
    border: 1px solid $dropdown-border-color;
    border-radius: 4px;
  }

  :global {

    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 1px $blue-50;
      border: 1px solid $primary-color-dark;
    }

    .ant-input-affix-wrapper {

      .ant-input:focus,
      .ant-input-focused {
        box-shadow: 0 0 0 1px transparent;
        border: 1px solid transparent;
      }
    }
  }
}

.darkContainer {
  .darkTitle {
    color: $dark-gray-x11;
    font-size: 14px;
  }
}

.lightContainer {
  .lightTitle {
    color: $title-color-light;
    font-size: 14px;
  }
}