@use '../../../common/styleConstants.scss' as *;

.value {
  font-size: 1.05em;
  color: $davys-grey;
  text-align: center;
  font-weight: bold;
}

.field {
  font-size: 0.95em;
  color: $quick-silver;
  text-align: center;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridContainer {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 18px;
  padding: 14px 0;
  margin-right: 18px;

  .harvest {
    display: flex;
    width: 100%;
    flex-direction: column;

    .harvestName {
      font-size: 1.05em;
      color: $davys-grey;
      font-weight: bold;
      text-align: left;
    }
  }

  .row {
    display: flex;
    justify-content: space-around;
    background-color: $ghost-white;
    border-radius: 6px;
    padding: 6px 0;

    .gridItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
