@use '../../../common/styleConstants.scss' as *;

.container {
  .sorterCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .colTitle {
      transition: 0.3s;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .sortIcons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-top: -5px;
      margin-bottom: -5px;

      i {
        transform: rotate(0deg);
        transition: 0.3s;
      }

      i::before {
        font-size: 20px;
      }

      .arrowUpIcon {
        transform: rotate(0deg);
        transition: 0.3s;
      }

      .arrowDownIcon {
        transition: 0.3s;
        transform: rotate(180deg);
      }

      .isFocusedIcon {
        transition: 0.3s;
      }
    }
  }
}

.containerDark {
  .sorterCell {
    .sortIcons {
      .arrowUpIcon {
        color: $sonic-silver;
      }

      .arrowDownIcon {
        color: $sonic-silver;
      }

      .isFocusedIcon {
        color: $bright-gray;
      }
    }
  }
  &:hover {
    .colTitle {
      transition: 0.3s;
      color: $title-color-dark;
    }
  }
}

.containerLight {
  .sorterCell {
    .sortIcons {
      .arrowUpIcon {
        color: $sonic-silver;
      }

      .arrowDownIcon {
        color: $sonic-silver;
      }

      .isFocusedIcon {
        color: $black;
      }
    }
  }
  &:hover {
    .colTitle {
      transition: 0.3s;
      color: $dark-charcoal;
    }
  }
}
