@use '../../common/styleConstants.scss' as *;

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $dark-cornflower-blue;
  padding: 12px;

  .left {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 24px;

    .company {
      font-size: 1.9em;
      font-weight: bold;
    }

    .stockingBindingCode {
      font-family: "LexendDeca-Light";
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .code {
        font-size: 1.45em;
      }

      .rightSubtitle {
        font-size: 1.15em;
      }
    }
  }

  .right {
    height: 100%;

    img {
      height: 50px;
      margin-top: 16px;
      margin-right: 24px;
    }
  }
}
