@use '../../styleConstants.scss' as *;

.smallBreadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .showBreadcrumb {
    padding-right: 10px;
  }
}

.lastBreadcrumb {
  font-weight: bold;
}

.breadcrumb {
  * {
    font-size: 15px;
  }

  .link:hover {
    text-decoration: underline;
  }

  .linkDark {
    transition: 0s;
    color: $title-color-dark !important;
  }

  .linkLight {
    transition: 0s;
    color: $title-color-light !important;
  }

  @media (max-width: 660px) {
    :global .ant-breadcrumb-separator {
      margin: 0;
    }
  }
}

.breadcrumbLight {
  .ant-breadcrumb-link {
    a {
      color: $title-color-light;
    }
  }

  .ant-breadcrumb-separator {
    color: $silver-foil;

    .ri-arrow-right-s-line {
      vertical-align: bottom;
    }
  }
}

.breadcrumbDark {
  :global {
    .ant-breadcrumb-link {
      a {
        color: $title-color-dark;
      }
    }

    .ant-breadcrumb-separator {
      color: $nickel;

      .ri-arrow-right-s-line {
        vertical-align: bottom;
      }
    }
  }
}

@media (max-width: 950px) {
  .breadcrumb {
    * {
      font-size: 15px;
    }
  }
}

@media (max-width: 740px) {
  .breadcrumb {
    * {
      font-size: 13px;
    }
  }
}

@media (max-width: 540px) {
  .breadcrumb {
    * {
      font-size: 11px;
    }
  }
}
