.growthDelta {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-cell {
          font-size: 13px;
        }
        .ant-table-body {
          height: calc(100vh - 240px) !important;
          overflow-y: scroll !important;
        }

        @media (max-width: 1199px) {
          .ant-table-body {
            height: calc(25vh) !important;
            overflow-y: scroll !important;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        cursor: default;
      }
    }

    > tr.ant-table-row {
      &:hover {
        td {
          background: transparent;
        }
      }

      td {
        &:first-child {
          div {
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
