@use '../../common/styleConstants.scss' as *;

.qrButton {
  display: flex;
  align-items: center;
  padding: 0px 8px;

  .mapPin {
    i {
      padding-bottom: 2px;
    }
  }

  :global {
    .ant-btn:disabled {
      border: none;
    }
  }
}
