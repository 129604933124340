@use '../../../../../src/common/styleConstants.scss' as *;

.tooltip {
  position: absolute;
  -webkit-transition: 250ms ease-in-out;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;

  .content {
    background-color: $white;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 160px;
    width: 200px;

    .entry {
      color: black;

      .entryContent {
        .row {
          cursor: pointer;
          border-bottom: 1px solid $table-title;

          &:last-child {
            border-bottom-width: 0px;
          }

          &:hover {
            background-color: $title-color-dark;
          }
        }

        .title {
          font-size: 11px;
          padding: 6px 4px 6px 8px;

          div {
            div {
              font-size: 11px;
            }

            strong {
              font-size: 10px;
            }
          }
        }

        .option {
          font-size: 12px;
          padding: 6px 8px 6px 16px;
        }
      }
    }
  }
}
