@use '../../../common/styleConstants.scss' as *;

:global {
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
      .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end):not(
      .ant-picker-cell-range-hover-start
    ):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background-color: transparent;
  }
}

.lightCalendar {
  :global {
    .ant-picker-panel .ant-picker-body th {
      color: $sonic-silver;
    }

    .ant-picker-panel {
      background-color: $body-background-light;

      .ant-picker-calendar-date-value {
        text-shadow: 2px 2px 6px $white;
        color: $sonic-silver;
      }

      .ant-picker-calendar-date-content {
        text-shadow: 2px 2px 16px $black;
        color: $title-color-dark;
        text-align: center;
      }
    }

    .ant-picker-panel
      .ant-picker-cell-selected
      .ant-picker-calendar-date
      .ant-picker-calendar-date-value,
    .ant-picker-panel
      .ant-picker-cell-selected:hover
      .ant-picker-calendar-date
      .ant-picker-calendar-date-value,
    .ant-picker-panel
      .ant-picker-cell-selected
      .ant-picker-calendar-date-today
      .ant-picker-calendar-date-value,
    .ant-picker-panel
      .ant-picker-cell-selected:hover
      .ant-picker-calendar-date-today
      .ant-picker-calendar-date-value {
      color: $sonic-silver;
    }

    .ant-picker-panel
      .ant-picker-calendar-date-today
      .ant-picker-calendar-date-value {
      color: $sonic-silver;
    }
  }
}

.darkCalendar {
  background-color: grey;
}
