.registerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .registerFormWrapper {
    width: 500px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;

    .registerForm {
      height: 300px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .registerFormTitle {
        text-align: center;
        font-size: 2em;
        margin-bottom: 15px;
      }

      .registerLogo {
        padding-left: 8px;
        margin: 10px auto;
      }
    }
  }
}

@media all and (max-width: 767px) and (orientation: landscape) {
  .registerLogo {
    margin-top: 150px;
  }
}
