@use '../../../common/styleConstants.scss' as *;

.dropdown {
  .ant-dropdown-trigger {
    width: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}