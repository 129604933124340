@use '../../common/styleConstants.scss' as *;

.formOptions {
  display: flex;
  justify-content: space-between;
}

.registerForm {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #030829 inset !important;
    -webkit-text-fill-color: white;
    border-radius: 0;
  }

  .input {
    border: 1px solid $bdazzled-blue;
  }

  .countryCodeContainer {
    :global {
      .ant-select .ant-select-selector {
        border-radius: 4px 0px 0px 4px;
        border: 1px solid $bdazzled-blue;

        &:hover {
          border-color: $bdazzled-blue;
        }
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(
          .ant-select-customize-input
        )
        .ant-select-selector {
        border-color: $bdazzled-blue;
        box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.2);
      }
    }
  }

  .countryCodeSelect :global .ant-select-selector {
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
  }

  .countryCodeSelect :global .ant-select-selector {
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
  }

  .notAllowed {
    cursor: not-allowed;
  }

  .inputGroup {
    display: flex;
  }

  .phoneFormItem {
    :global .ant-form-item-label label::before {
      content: "*";
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      color: $orange-red;
      margin-right: 4px;
    }
  }
}

.alertError {
  color: $orange-red;
  clear: both;
  min-height: 24px;
  padding-top: 0px;
  font-size: 14px;
  line-height: 1.5715;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.alertInfo {
  color: white;
  clear: both;
  min-height: 24px;
  padding-top: 0px;
  font-size: 14px;
  line-height: 1.5715;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
