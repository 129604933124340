@use '../../../common/styleConstants.scss' as *;

.spinnerContainer {
  position: relative;
  width: 100%;
  height: 95%;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    i {
      font-size: 40px;
    }
  }
}

.parameterState {
  background-color: white;
  margin: auto;
  min-height: 1187px;
  width: 1680px;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95%;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $davys-grey;
    padding-top: 20px;

    div {
      font-size: 1.8em;
      text-transform: uppercase;
    }

    span {
      font-size: 1.8em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .graphs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    margin: 10px 30px;
  }

  .graphs > * {
    flex: 0 1 calc(12.5% - 10px);
    box-sizing: border-box;
  }
}
