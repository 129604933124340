.NEW_MOON_DARK {
  fill: rgba(0, 0, 0, 0.2);
}

.NEW_MOON_LIGHT {
  fill: rgba(0, 0, 0, 0.2);
}

.FIRST_QUARTER_MOON_DARK {
  fill: rgb(212, 175, 55, 0.4);
}

.FIRST_QUARTER_MOON_LIGHT {
  fill: rgb(212, 175, 55, 0.2);
}

.LAST_QUARTER_MOON_DARK {
  fill: rgb(112, 128, 144, 0.2);
}

.LAST_QUARTER_MOON_LIGHT {
  fill: rgb(112, 128, 144, 0.2);
}

.FULL_MOON_DARK {
  fill: rgba(255, 255, 255, 0.4);
}

.FULL_MOON_LIGHT {
  fill: rgba(255, 255, 255, 0.4);
}
